export const ZohoLogo = () => {
  return (
    <>
      <svg
        width="176"
        height="61"
        viewBox="0 0 176 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_149_2)">
          <path d="M176 12.9595V54.6907L170.225 60.3282V19.4907L176 12.9595Z" fill="#E79225" />
          <path
            d="M138.153 12.9938L132.172 19.4907L132.481 19.9032L169.984 19.7657L170.328 19.4907L176 12.9595L138.153 12.9938Z"
            fill="#FFF16D"
          />
          <path
            d="M170.328 19.4907H132.172V60.3626H170.328V19.4907Z"
            fill="url(#paint0_linear_149_2)"
          />
          <path
            d="M164.519 34.2031C163.762 32.3125 162.628 30.5938 161.15 29.1156C159.741 27.6719 158.159 26.5719 156.372 25.85C154.619 25.1281 152.728 24.75 150.666 24.75C148.603 24.75 146.678 25.1281 144.925 25.85C143.137 26.5719 141.556 27.6719 140.147 29.1156C138.669 30.5938 137.569 32.3125 136.813 34.2031C136.056 36.0938 135.713 38.1562 135.713 40.425C135.713 42.625 136.091 44.6875 136.847 46.6125C137.603 48.5375 138.703 50.2562 140.181 51.7687C141.556 53.1781 143.138 54.2438 144.891 54.9656C146.644 55.6875 148.603 56.0656 150.7 56.0656C152.728 56.0656 154.619 55.6875 156.372 54.9656C158.125 54.2438 159.741 53.1781 161.15 51.7687C162.628 50.2562 163.762 48.5375 164.519 46.6469C165.275 44.7562 165.653 42.6594 165.653 40.4594C165.653 38.1562 165.275 36.0938 164.519 34.2031ZM156.647 47.6781C155.169 49.4312 153.209 50.325 150.666 50.325C148.122 50.325 146.128 49.4312 144.65 47.6781C143.172 45.925 142.45 43.4844 142.45 40.3906C142.45 37.2281 143.206 34.7875 144.65 33C146.128 31.2125 148.088 30.3531 150.666 30.3531C153.209 30.3531 155.169 31.2469 156.647 33C158.091 34.7875 158.847 37.2281 158.847 40.3906C158.847 43.4844 158.125 45.925 156.647 47.6781Z"
            fill="white"
          />
          <path
            d="M128.425 9.55615V9.7624L133.306 47.2655L130.453 55.1718L130.075 54.8968L125.056 18.9405L125.159 18.4593L128.287 9.7624L128.425 9.55615Z"
            fill="#009ADA"
          />
          <path
            d="M90.8531 14.7812L128.425 9.55615L125.262 18.5624L124.816 19.0437L89.6844 24.1999L89.8562 17.7718L90.8531 14.7812Z"
            fill="#91C9ED"
          />
          <path
            d="M125.262 18.5625L130.453 55.1719L93.4312 60.3625L88.4469 24.9906L90.6125 23.3062L125.262 18.5625Z"
            fill="url(#paint1_linear_149_2)"
          />
          <path
            d="M118.972 25.575C118.8 24.4406 118.422 23.5812 117.803 22.9968C117.287 22.55 116.669 22.3093 115.947 22.3093C115.775 22.3093 115.569 22.3093 115.362 22.3437C114.4 22.4812 113.678 22.9281 113.231 23.65C112.887 24.1656 112.75 24.8187 112.75 25.575C112.75 25.85 112.784 26.1593 112.819 26.4687L114.159 35.9906L103.503 37.5718L102.162 28.05C101.991 26.95 101.612 26.0906 100.994 25.5062C100.478 25.025 99.8594 24.7843 99.1719 24.7843C99 24.7843 98.8281 24.7843 98.6562 24.8187C97.6594 24.9562 96.9031 25.4031 96.4219 26.125C96.0781 26.6406 95.9406 27.2937 95.9406 28.05C95.9406 28.325 95.975 28.6343 96.0094 28.9781L99.6531 53.7624C99.825 54.8968 100.203 55.7562 100.891 56.3406C101.406 56.7874 102.025 56.9937 102.781 56.9937C102.987 56.9937 103.194 56.9937 103.4 56.9593C104.328 56.8218 105.016 56.3749 105.462 55.6531C105.772 55.1374 105.909 54.5187 105.909 53.7968C105.909 53.5218 105.875 53.2125 105.841 52.9031L104.362 43.1062L115.019 41.525L116.497 51.3218C116.669 52.4562 117.047 53.3156 117.7 53.8656C118.216 54.3124 118.834 54.5531 119.556 54.5531C119.728 54.5531 119.934 54.5531 120.141 54.5187C121.103 54.3812 121.825 53.9343 122.272 53.2125C122.581 52.6968 122.753 52.0781 122.753 51.3218C122.753 51.0468 122.719 50.7374 122.684 50.4281L118.972 25.575Z"
            fill="white"
          />
          <path
            d="M55.6875 0L42.3156 31.7625L44.1375 45.7188L44.2406 45.6844L59.2625 11.8937L59.1938 11.1719L55.9625 0.446875L55.6875 0Z"
            fill="#66BF6B"
          />
          <path
            d="M55.6875 0L59.1594 11.6531L59.2281 11.8937L92.2969 26.7094L92.4 26.6406L89.5813 15.5031L55.6875 0Z"
            fill="#98D0A0"
          />
          <path
            d="M59.1594 11.6531L92.4 26.6406L77.3781 60.7062L44.1375 45.7187L59.1594 11.6531Z"
            fill="url(#paint2_linear_149_2)"
          />
          <path
            d="M82.3281 29.3907C81.6063 27.4657 80.6094 25.8157 79.3031 24.4407C77.9969 23.0657 76.4156 21.9657 74.5594 21.1751C72.7031 20.3845 70.8469 20.0063 68.9562 20.0063H68.8531C66.9281 20.0063 65.0375 20.4532 63.1812 21.2782C61.2219 22.1376 59.5375 23.3063 58.0938 24.8532C56.65 26.3657 55.4812 28.222 54.5875 30.3532C53.6937 32.4501 53.2125 34.5813 53.1094 36.6782V37.4001C53.1094 39.2563 53.4187 41.0782 54.0719 42.8657C54.7594 44.7563 55.7563 46.372 57.0625 47.747C58.3687 49.122 59.9844 50.222 61.9094 51.0126C63.7312 51.8032 65.5875 52.1813 67.4781 52.1813H67.5125C69.4031 52.1813 71.2938 51.7688 73.15 50.9782C75.1094 50.1188 76.8281 48.9157 78.2719 47.3688C79.7156 45.8563 80.9188 44.0345 81.8125 41.9032C82.7062 39.772 83.1875 37.6751 83.2906 35.5782V34.9595C83.325 33.0688 83.0156 31.2126 82.3281 29.3907ZM75.6937 39.2907C74.4562 42.247 72.7719 44.2751 70.7438 45.3751C69.6438 45.9595 68.5094 46.2689 67.375 46.2689C66.3781 46.2689 65.3125 46.0282 64.2469 45.5814C61.9094 44.5845 60.4656 43.0032 59.8469 40.7345C59.6406 39.9782 59.5375 39.1876 59.5375 38.3626C59.5375 36.7126 59.95 34.8907 60.775 32.9313C62.0469 29.9063 63.7313 27.8438 65.7594 26.7095C66.8594 26.0907 67.9937 25.8157 69.1281 25.8157C70.1594 25.8157 71.1906 26.0563 72.2906 26.5032C74.5938 27.5001 76.0375 29.0813 76.6562 31.3501C76.8625 32.072 76.9656 32.8626 76.9656 33.6876C76.9656 35.4063 76.5531 37.2626 75.6937 39.2907Z"
            fill="white"
          />
          <path
            d="M39.6688 5.39697L45.1 41.7657L42.625 54.5532L42.2813 54.1063L36.9875 18.9751V18.2876L39.325 6.01572L39.6688 5.39697Z"
            fill="#760D16"
          />
          <path
            d="M0 24.2001L2.57812 11.3438L39.6688 5.39697L37.1594 18.4938V19.3532L0.446875 24.5438L0 24.2001Z"
            fill="#EF463E"
          />
          <path
            d="M37.1594 18.4939L42.625 54.5533L5.9125 60.2939L0 24.2001L37.1594 18.4939Z"
            fill="url(#paint3_linear_149_2)"
          />
          <path
            d="M33.2062 48.8126C32.9312 48.4689 32.5187 48.2283 32.0375 48.0564C31.5562 47.8845 30.9719 47.8158 30.25 47.8158C29.5969 47.8158 28.8406 47.8845 28.0156 48.022L18.3219 49.672C18.425 48.9158 18.8031 47.9533 19.4562 46.7501C20.1781 45.4095 21.2781 43.7939 22.6875 41.9376C23.1687 41.2845 23.5469 40.8033 23.8219 40.4595C23.9938 40.2189 24.2687 39.9095 24.6125 39.4626C26.8469 36.5408 28.1875 34.1689 28.7375 32.3126C29.0469 31.247 29.2187 30.1814 29.2875 29.1158C29.3219 28.8064 29.3219 28.5314 29.3219 28.2564C29.3219 27.5001 29.2531 26.7439 29.1156 25.9876C29.0125 25.3001 28.8406 24.7501 28.6 24.3033C28.3594 23.8564 28.0844 23.5126 27.7406 23.3064C27.3625 23.0658 26.7781 22.9626 26.0563 22.9626C25.4719 22.9626 24.75 23.0314 23.8906 23.1689L12.6844 25.0939C11.3438 25.3345 10.3125 25.7126 9.69375 26.3314C9.17813 26.8126 8.9375 27.4314 8.9375 28.1189C8.9375 28.2908 8.9375 28.497 8.97188 28.7033C9.14375 29.6658 9.625 30.3533 10.4156 30.697C10.8969 30.9033 11.4469 31.0064 12.1344 31.0064C12.5812 31.0064 13.0969 30.972 13.6469 30.8689L22.9969 29.2189C22.9969 29.3908 23.0312 29.5626 23.0312 29.7001C23.0312 30.2845 22.9281 30.8689 22.7219 31.4189C22.4469 32.2095 21.7594 33.3095 20.625 34.7189C20.3156 35.097 19.8344 35.7158 19.1812 36.5064C16.6375 39.5658 14.85 42.1783 13.75 44.3439C12.9594 45.8564 12.4437 47.3001 12.1344 48.7783C11.9625 49.6376 11.8594 50.4283 11.8594 51.2189C11.8594 51.7689 11.8938 52.3189 11.9969 52.8345C12.1344 53.5908 12.3063 54.2095 12.5469 54.6908C12.7875 55.172 13.1312 55.5501 13.5094 55.7564C13.8875 55.9626 14.4031 56.0314 15.1594 56.0314C16.0875 56.0314 17.3594 55.8939 18.975 55.6189L29.15 53.8658C30.9375 53.5564 32.2094 53.1095 32.9313 52.5251C33.5156 52.0439 33.825 51.3908 33.825 50.6345C33.825 50.4283 33.7906 50.222 33.7562 50.0158C33.6875 49.5689 33.5156 49.1564 33.2062 48.8126Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_149_2"
            x1="2039.83"
            y1="77.9856"
            x2="2039.83"
            y2="3983.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.005618" stop-color="#FFE513" />
            <stop offset="1" stop-color="#FDB924" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_149_2"
            x1="277.998"
            y1="582.621"
            x2="4092.4"
            y2="3676.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.005618" stop-color="#008CD2" />
            <stop offset="1" stop-color="#00649D" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_149_2"
            x1="2457.34"
            y1="1374.29"
            x2="2457.34"
            y2="4795.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#26A146" />
            <stop offset="1" stop-color="#008A52" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_149_2"
            x1="1864.16"
            y1="361.58"
            x2="2430.8"
            y2="3917.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D92231" />
            <stop offset="1" stop-color="#BA2234" />
          </linearGradient>
          <clipPath id="clip0_149_2">
            <rect width="176" height="60.8438" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
