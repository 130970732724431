export const SBSupport = ({ color = "#28287B" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.60156 13.6001H6.00156C6.6643 13.6001 7.20156 14.1374 7.20156 14.8001V17.2001C7.20156 17.8628 6.6643 18.4001 6.00156 18.4001H3.60156V10.0001C3.60156 5.36091 7.36237 1.6001 12.0016 1.6001C16.6408 1.6001 20.4016 5.36091 20.4016 10.0001V18.4001H18.0016C17.3388 18.4001 16.8016 17.8628 16.8016 17.2001V14.8001C16.8016 14.1374 17.3388 13.6001 18.0016 13.6001H20.4016"
          stroke={color}
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M13.1111 23C13.6018 23 14 22.552 14 22C14 21.448 13.6018 21 13.1111 21C12.8996 21 11.1004 21 10.8889 21C10.3982 21 10 21.448 10 22C10 22.552 10.3982 23 10.8889 23C11.1004 23 12.8996 23 13.1111 23Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M20 18C20 20.5 18.75 22 16.6667 22H13"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </>
  );
};
