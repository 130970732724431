import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import { useGetActivityLogsMutation } from 'src/services/account-service';
import moment from 'moment';
import InboxPagination from 'src/components/InboxPagination';

const ActivityLogsTab = ({ account }) => {
  const [logs, setLogs] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [activityLogs, { isLoading: isLoadingActivityLog}] = useGetActivityLogsMutation();

  useEffect(() => {
    const object = {};
    object.id = account?._id;
    object.offset = logs.length * (page - 1);
    object.limit = limit;
    activityLogs(object).then((res) => {
      setTotalLogs(res?.data?.totalDocs);
      setLogs(res?.data?.docs);
    });
  }, [limit, page]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const formatTimestamp = (timestamp) => {
    return moment(timestamp).fromNow();
  };

  return (
    <>
      {isLoadingActivityLog ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress size={24} />
        </Box>
      ) : logs.length > 0 ? (
        <>
          <TableContainer component={Paper} sx={{ maxHeight: 520 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textTransform: 'none', fontSize: 'medium' }}>Time</TableCell>
                  <TableCell style={{ textTransform: 'none', fontSize: 'medium' }}>Activity Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ textTransform: 'none' }}>
                      <br />
                      {formatTimestamp(log.timestamp)}
                      <br />
                      <span style={{ fontSize: 'small' }}>{log.timestamp}</span>
                    </TableCell>
                    <TableCell style={{ textTransform: 'none' }}>
                      {log.logMessage.split('•').map((message, idx) => (
                        <div key={idx}>{message && `• ${message.trim()}`}</div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ position: 'sticky', bottom: 0, width: '100%' }}>
              <InboxPagination
                page={page}
                setPage={setPage}
                total={totalLogs}
                length={logs?.length}
                limit={limit}
                handleLimitChange={handleLimitChange}
              />
          </Box>
        </>
      ) : (
        <Typography variant="body1" align="center">
          No data found
        </Typography>
      )}
    </>
  );
};

export default ActivityLogsTab;
