export const SalesForceLogo = () => {
  return (
    <>
      <svg
        width="176"
        height="123"
        viewBox="0 0 176 123"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_147_34)">
          <path
            d="M73.2406 13.4356C78.9167 7.52133 86.8191 3.85341 95.559 3.85341C107.177 3.85341 117.313 10.3318 122.711 19.9491C127.402 17.8532 132.594 16.6874 138.057 16.6874C159.012 16.6874 176 33.8236 176 54.9611C176 76.1012 159.012 93.2373 138.057 93.2373C135.5 93.2373 133 92.9816 130.583 92.4927C125.83 100.972 116.772 106.701 106.375 106.701C102.022 106.701 97.9056 105.695 94.2402 103.908C89.4215 115.242 78.1946 123.19 65.11 123.19C51.4838 123.19 39.8708 114.568 35.4131 102.476C33.4651 102.89 31.4468 103.105 29.376 103.105C13.1524 103.105 0 89.8176 0 73.4236C0 62.4373 5.90929 52.8451 14.6892 47.713C12.8816 43.5537 11.8762 38.9632 11.8762 34.1369C11.8762 15.2834 27.1822 -4.0584e-05 46.0609 -4.0584e-05C57.1448 -4.0584e-05 66.9953 5.26994 73.2406 13.4356Z"
            fill="#00A1E0"
          />
          <path
            d="M25.4919 63.8833C25.3816 64.1716 25.532 64.2318 25.5671 64.2819C25.8981 64.5226 26.234 64.6956 26.5725 64.8886C28.3676 65.8413 30.0624 66.1196 31.8349 66.1196C35.4452 66.1196 37.6866 64.1992 37.6866 61.1079V61.0477C37.6866 58.1896 35.1569 57.1517 32.7826 56.402L32.4743 56.3017C30.6842 55.7201 29.1398 55.2187 29.1398 54.0403V53.9776C29.1398 52.9698 30.0424 52.2277 31.4413 52.2277C32.9957 52.2277 34.841 52.7441 36.0294 53.401C36.0294 53.401 36.3778 53.6266 36.5057 53.2882C36.5759 53.1077 37.1776 51.4881 37.2403 51.3126C37.308 51.122 37.1877 50.9816 37.0648 50.9064C35.7084 50.0816 33.8331 49.5175 31.8926 49.5175L31.5316 49.52C28.2272 49.52 25.9206 51.5156 25.9206 54.3763V54.4364C25.9206 57.4525 28.4654 58.4303 30.8496 59.1122L31.2332 59.2301C32.9707 59.7641 34.4674 60.2229 34.4674 61.4464V61.5065C34.4674 62.6247 33.4947 63.4571 31.9252 63.4571C31.316 63.4571 29.373 63.4445 27.2745 62.1183C27.0213 61.9703 26.8733 61.8625 26.6778 61.7447C26.575 61.6795 26.3168 61.5667 26.2039 61.9077L25.4919 63.8833Z"
            fill="white"
          />
          <path
            d="M78.3446 63.8833C78.2343 64.1716 78.3847 64.2318 78.4198 64.2819C78.7507 64.5226 79.0867 64.6956 79.4252 64.8886C81.2203 65.8413 82.9151 66.1196 84.6876 66.1196C88.2979 66.1196 90.5392 64.1992 90.5392 61.1079V61.0477C90.5392 58.1896 88.0095 57.1517 85.6353 56.402L85.3269 56.3017C83.5368 55.7201 81.9924 55.2187 81.9924 54.0403V53.9776C81.9924 52.9698 82.895 52.2277 84.294 52.2277C85.8484 52.2277 87.6936 52.7441 88.882 53.401C88.882 53.401 89.2305 53.6266 89.3584 53.2882C89.4286 53.1077 90.0303 51.4881 90.093 51.3126C90.1607 51.122 90.0403 50.9816 89.9175 50.9064C88.5611 50.0816 86.6858 49.5175 84.7453 49.5175L84.3842 49.52C81.0799 49.52 78.7733 51.5156 78.7733 54.3763V54.4364C78.7733 57.4525 81.318 58.4303 83.7023 59.1122L84.0859 59.2301C85.8233 59.7641 87.3226 60.2229 87.3226 61.4464V61.5065C87.3226 62.6247 86.3473 63.4571 84.7779 63.4571C84.1686 63.4571 82.2256 63.4445 80.1271 62.1183C79.8739 61.9703 79.7235 61.8675 79.533 61.7447C79.4678 61.7021 79.1619 61.5842 79.0566 61.9077L78.3446 63.8833Z"
            fill="white"
          />
          <path
            d="M114.426 57.8279C114.426 59.5754 114.1 60.9518 113.458 61.9245C112.824 62.8873 111.863 63.3561 110.524 63.3561C109.183 63.3561 108.228 62.8898 107.604 61.9245C106.972 60.9543 106.651 59.5754 106.651 57.8279C106.651 56.0829 106.972 54.709 107.604 53.7463C108.228 52.7936 109.183 52.3298 110.524 52.3298C111.863 52.3298 112.824 52.7936 113.46 53.7463C114.1 54.709 114.426 56.0829 114.426 57.8279ZM117.439 54.5887C117.143 53.5883 116.682 52.7058 116.068 51.9713C115.453 51.2342 114.676 50.6425 113.754 50.2112C112.834 49.7825 111.745 49.5644 110.524 49.5644C109.301 49.5644 108.213 49.7825 107.293 50.2112C106.37 50.6425 105.593 51.2342 104.976 51.9713C104.364 52.7083 103.903 53.5909 103.605 54.5887C103.311 55.584 103.164 56.6721 103.164 57.8279C103.164 58.9837 103.311 60.0743 103.605 61.0671C103.903 62.0649 104.362 62.9474 104.979 63.6845C105.593 64.4216 106.373 65.0108 107.293 65.4295C108.215 65.8482 109.301 66.0613 110.524 66.0613C111.745 66.0613 112.831 65.8482 113.754 65.4295C114.674 65.0108 115.453 64.4216 116.068 63.6845C116.682 62.9499 117.143 62.0674 117.439 61.0671C117.735 60.0718 117.883 58.9812 117.883 57.8279C117.883 56.6746 117.735 55.584 117.439 54.5887Z"
            fill="white"
          />
          <path
            d="M142.186 62.8897C142.086 62.5964 141.803 62.7067 141.803 62.7067C141.364 62.8747 140.898 63.0301 140.401 63.1078C139.897 63.1856 139.343 63.2257 138.749 63.2257C137.29 63.2257 136.132 62.7919 135.302 61.9345C134.469 61.0771 134.003 59.6906 134.008 57.8153C134.013 56.108 134.424 54.8243 135.164 53.8465C135.898 52.8738 137.017 52.3749 138.508 52.3749C139.752 52.3749 140.699 52.5178 141.692 52.8311C141.692 52.8311 141.931 52.9339 142.043 52.6231C142.307 51.891 142.502 51.367 142.783 50.5622C142.863 50.3341 142.668 50.2363 142.597 50.2087C142.206 50.0558 141.284 49.8076 140.587 49.7023C139.935 49.602 139.173 49.5493 138.325 49.5493C137.059 49.5493 135.931 49.7649 134.966 50.1962C134.003 50.6249 133.186 51.2166 132.539 51.9537C131.892 52.6907 131.401 53.5733 131.072 54.5711C130.746 55.5664 130.581 56.6595 130.581 57.8153C130.581 60.3149 131.255 62.3356 132.586 63.8149C133.92 65.2991 135.923 66.0537 138.536 66.0537C140.08 66.0537 141.665 65.7403 142.803 65.2915C142.803 65.2915 143.021 65.1862 142.926 64.933L142.186 62.8897Z"
            fill="white"
          />
          <path
            d="M147.459 56.154C147.602 55.1838 147.87 54.3765 148.284 53.7472C148.908 52.792 149.861 52.268 151.2 52.268C152.538 52.268 153.423 52.7945 154.058 53.7472C154.479 54.3765 154.662 55.2189 154.735 56.154H147.459ZM157.605 54.0205C157.35 53.0552 156.715 52.0799 156.299 51.6337C155.642 50.9267 155 50.4328 154.364 50.157C153.531 49.801 152.533 49.5653 151.44 49.5653C150.167 49.5653 149.011 49.7784 148.073 50.2197C147.133 50.6609 146.343 51.2626 145.724 52.0123C145.105 52.7594 144.638 53.6494 144.343 54.6598C144.044 55.6651 143.894 56.7607 143.894 57.9165C143.894 59.0924 144.049 60.188 144.358 61.1733C144.668 62.1661 145.165 63.0411 145.837 63.7656C146.506 64.4952 147.369 65.0668 148.402 65.4655C149.427 65.8616 150.673 66.0672 152.105 66.0647C155.05 66.0546 156.602 65.3978 157.242 65.0443C157.355 64.9816 157.462 64.8713 157.327 64.5554L156.66 62.6876C156.56 62.4093 156.276 62.5121 156.276 62.5121C155.547 62.7829 154.509 63.2692 152.09 63.2642C150.508 63.2617 149.334 62.7954 148.6 62.0658C147.845 61.3187 147.476 60.2206 147.411 58.6712L157.613 58.6812C157.613 58.6812 157.881 58.6762 157.909 58.4154C157.919 58.3051 158.26 56.3195 157.605 54.0205Z"
            fill="white"
          />
          <path
            d="M65.7598 56.154C65.9052 55.1838 66.171 54.3765 66.5847 53.7472C67.2089 52.792 68.1617 52.268 69.5005 52.268C70.8393 52.268 71.7243 52.7945 72.3611 53.7472C72.7798 54.3765 72.9628 55.2189 73.0355 56.154H65.7598ZM75.9037 54.0205C75.6479 53.0552 75.0161 52.0799 74.5999 51.6337C73.9431 50.9267 73.3013 50.4328 72.6644 50.157C71.8321 49.801 70.8342 49.5653 69.7411 49.5653C68.47 49.5653 67.3117 49.7784 66.3741 50.2197C65.4339 50.6609 64.6442 51.2626 64.0249 52.0123C63.4056 52.7594 62.9393 53.6494 62.6435 54.6598C62.3476 55.6651 62.1947 56.7607 62.1947 57.9165C62.1947 59.0924 62.3501 60.188 62.6585 61.1733C62.9694 62.1661 63.4658 63.0411 64.1377 63.7656C64.8071 64.4952 65.6696 65.0668 66.7025 65.4655C67.7279 65.8616 68.974 66.0672 70.4055 66.0647C73.3514 66.0546 74.9033 65.3978 75.5426 65.0443C75.6554 64.9816 75.7633 64.8713 75.6279 64.5554L74.9635 62.6876C74.8607 62.4093 74.5774 62.5121 74.5774 62.5121C73.8478 62.7829 72.8124 63.2692 70.388 63.2642C68.8085 63.2617 67.6352 62.7954 66.9006 62.0658C66.1459 61.3187 65.7774 60.2206 65.7122 58.6712L75.9137 58.6812C75.9137 58.6812 76.1819 58.6762 76.2095 58.4154C76.2195 58.3051 76.5605 56.3195 75.9037 54.0205Z"
            fill="white"
          />
          <path
            d="M43.7094 62.8338C43.3108 62.5154 43.2556 62.4352 43.1203 62.2296C42.9197 61.9162 42.8169 61.4699 42.8169 60.9033C42.8169 60.0058 43.1127 59.3614 43.727 58.9277C43.7195 58.9302 44.6045 58.163 46.6854 58.1906C48.147 58.2107 49.4533 58.4263 49.4533 58.4263V63.0645H49.4558C49.4558 63.0645 48.1596 63.3428 46.7004 63.4305C44.6245 63.5559 43.7019 62.8313 43.7094 62.8338ZM47.7685 55.6659C47.3548 55.6359 46.8183 55.6183 46.1764 55.6183C45.3015 55.6183 44.4566 55.7286 43.6643 55.9417C42.867 56.1548 42.15 56.4883 41.5333 56.9295C40.914 57.3733 40.4151 57.9399 40.0541 58.6118C39.693 59.2837 39.51 60.076 39.51 60.9635C39.51 61.8661 39.6655 62.6508 39.9763 63.2926C40.2872 63.9369 40.736 64.4735 41.3076 64.8871C41.8742 65.3008 42.5737 65.6042 43.386 65.7872C44.1858 65.9702 45.0934 66.063 46.0862 66.063C47.1317 66.063 48.1746 65.9777 49.185 65.8048C50.1853 65.6343 51.4138 65.3861 51.7548 65.3083C52.0933 65.2281 52.4693 65.1253 52.4693 65.1253C52.7225 65.0626 52.7025 64.7919 52.7025 64.7919L52.6975 55.4629C52.6975 53.4171 52.1509 51.9002 51.0754 50.9601C50.0048 50.0224 48.4278 49.5486 46.3896 49.5486C45.6249 49.5486 44.3939 49.6539 43.6568 49.8018C43.6568 49.8018 41.428 50.233 40.5104 50.95C40.5104 50.95 40.3098 51.0754 40.4201 51.3562L41.1421 53.2967C41.2324 53.5474 41.4756 53.4622 41.4756 53.4622C41.4756 53.4622 41.5533 53.4321 41.6436 53.3795C43.6066 52.3114 46.0887 52.344 46.0887 52.344C47.1918 52.344 48.0392 52.5646 48.6109 53.0034C49.1674 53.4296 49.4508 54.0739 49.4508 55.4328V55.864C48.5733 55.7387 47.7685 55.6659 47.7685 55.6659Z"
            fill="white"
          />
          <path
            d="M130.048 50.4094C130.125 50.1788 129.962 50.0685 129.895 50.0434C129.722 49.9757 128.854 49.7927 128.185 49.7501C126.904 49.6723 126.192 49.888 125.555 50.1738C124.923 50.4596 124.221 50.9209 123.83 51.4449V50.2039C123.83 50.0309 123.707 49.893 123.537 49.893H120.922C120.751 49.893 120.628 50.0309 120.628 50.2039V65.4196C120.628 65.5901 120.769 65.7305 120.939 65.7305H123.619C123.79 65.7305 123.928 65.5901 123.928 65.4196V57.818C123.928 56.7976 124.041 55.7797 124.266 55.1404C124.487 54.5086 124.788 54.0022 125.159 53.6386C125.532 53.2776 125.956 53.0244 126.42 52.8815C126.894 52.7361 127.418 52.6884 127.789 52.6884C128.323 52.6884 128.909 52.8263 128.909 52.8263C129.105 52.8489 129.215 52.7285 129.28 52.5505C129.456 52.0842 129.952 50.6877 130.048 50.4094Z"
            fill="white"
          />
          <path
            d="M104.891 43.3574C104.565 43.2571 104.269 43.1894 103.883 43.1167C103.492 43.0465 103.025 43.0114 102.496 43.0114C100.651 43.0114 99.1971 43.5329 98.1767 44.5608C97.1613 45.5837 96.4718 47.1407 96.1258 49.189L96.0005 49.8784H93.6839C93.6839 49.8784 93.4031 49.8684 93.3429 50.1743L92.9643 52.2978C92.9368 52.4984 93.0245 52.6262 93.2953 52.6262H95.5492L93.2627 65.3925C93.0847 66.4204 92.8791 67.2653 92.651 67.9072C92.4278 68.539 92.2097 69.0128 91.9389 69.3588C91.6782 69.6897 91.4325 69.9354 91.0063 70.0783C90.6553 70.1962 90.2491 70.2513 89.8054 70.2513C89.5597 70.2513 89.2312 70.2112 88.988 70.1611C88.7474 70.1134 88.6195 70.0608 88.4365 69.9831C88.4365 69.9831 88.1732 69.8828 88.0679 70.146C87.9852 70.3641 87.3835 72.0163 87.3108 72.2194C87.2406 72.4225 87.3409 72.5804 87.4687 72.6281C87.7696 72.7334 87.9927 72.8036 88.4014 72.9014C88.968 73.0342 89.4468 73.0417 89.8956 73.0417C90.8333 73.0417 91.6907 72.9089 92.4002 72.6531C93.1123 72.3949 93.734 71.9461 94.2856 71.3394C94.8798 70.6825 95.2533 69.9956 95.6094 69.0554C95.9629 68.1278 96.2662 66.9745 96.5069 65.6307L98.8059 52.6262H102.165C102.165 52.6262 102.449 52.6363 102.506 52.3279L102.888 50.2069C102.913 50.0038 102.827 49.8784 102.554 49.8784H99.2923C99.3099 49.8057 99.4578 48.6575 99.8313 47.5769C99.9918 47.1181 100.293 46.7445 100.546 46.4888C100.797 46.2381 101.085 46.0601 101.401 45.9573C101.724 45.852 102.093 45.8019 102.496 45.8019C102.802 45.8019 103.106 45.837 103.334 45.8846C103.65 45.9523 103.773 45.9874 103.855 46.0125C104.189 46.1127 104.234 46.015 104.299 45.8545L105.079 43.7134C105.159 43.4828 104.961 43.385 104.891 43.3574Z"
            fill="white"
          />
          <path
            d="M59.3175 65.4204C59.3175 65.5909 59.1947 65.7288 59.0242 65.7288H56.319C56.1485 65.7288 56.0282 65.5909 56.0282 65.4204V43.6485C56.0282 43.478 56.1485 43.3402 56.319 43.3402H59.0242C59.1947 43.3402 59.3175 43.478 59.3175 43.6485V65.4204Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_147_34">
            <rect width="176" height="123" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
