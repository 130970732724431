import React, { createContext, useContext } from 'react';
import { roles } from './roles';

const AuthContext = createContext();

export const AuthProvider = ({ children, role }) => {
  const permissions = roles[role] || roles?.Viewer

  return (
    <AuthContext.Provider value={permissions}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
