export const LFSavedSearches = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#DAEFDF" />
        <path
          d="M12.625 21.25V17.875C12.625 17.4608 12.9608 17.125 13.375 17.125H18.625C19.0392 17.125 19.375 17.4608 19.375 17.875V21.625M17.875 13L13.375 13C12.9608 13 12.625 12.6642 12.625 12.25L12.625 10M21.6234 12.6234L19.3766 10.3766C19.1355 10.1355 18.8084 10 18.4674 10H11.2857C10.5756 10 10 10.5756 10 11.2857V20.7143C10 21.4244 10.5756 22 11.2857 22H20.7143C21.4244 22 22 21.4244 22 20.7143V13.5326C22 13.1916 21.8645 12.8645 21.6234 12.6234Z"
          stroke="#00AA38"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};
