import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { PersonOff } from '@mui/icons-material';
import toast from 'react-hot-toast';

export const RemoveLeadDialog = ({ open, onClose, handleRemoveLead, removeLeadOptions, setRemoveLeadOptions, theme }) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const handleRemoveClick = async () => {
    try {
      setIsRemoving(true);
      await handleRemoveLead();
      setIsRemoving(false);
    }
    catch {
      toast.error('Error while removing the email.')
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{ backgroundColor: "rgba(4, 4, 30, 0.5)" }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 1, px: 4, pt: 4 }}>
        <PersonOff />
        <Typography sx={{ fontSize: "20px", fontWeight: 700, lineHeight: "28px", letterSpacing: "0em", color: "#28287B" }}>
          Remove Lead
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 4 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "&.MuiCheckbox-root .MuiSvgIcon-root": { color: theme.palette.primary.main } }}
                checked={removeLeadOptions.allDomain}
                onChange={(e) => setRemoveLeadOptions({ ...removeLeadOptions, allDomain: e.target.checked })}
              />
            }
            sx={{ fontSize: "14px", fontWeight: 700, lineHeight: "18px", letterSpacing: "0em", color: "#28287B", my: { xs: 1, sm: 0 } }}
            label="Remove all leads from the same company (email domain)"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "&.MuiCheckbox-root .MuiSvgIcon-root": { color: theme.palette.primary.main } }}
                checked={removeLeadOptions.allCampaign}
                onChange={(e) => setRemoveLeadOptions({ ...removeLeadOptions, allCampaign: e.target.checked })}
              />
            }
            sx={{ fontSize: "14px", fontWeight: 700, lineHeight: "18px", letterSpacing: "0em", color: "#28287B", my: { xs: 1, sm: 0 } }}
            label="Remove from all campaigns"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "&.MuiCheckbox-root .MuiSvgIcon-root": { color: theme.palette.primary.main } }}
                checked={removeLeadOptions.blocklist}
                onChange={(e) => setRemoveLeadOptions({ ...removeLeadOptions, blocklist: e.target.checked })}
              />
            }
            sx={{ fontSize: "14px", fontWeight: 700, lineHeight: "18px", letterSpacing: "0em", color: "#28287B", my: { xs: 1, sm: 0 } }}
            label="Add email to blocklist"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleRemoveClick} color="error" disabled={isRemoving}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};
