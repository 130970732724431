import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Link, DialogTitle, DialogContent, DialogActions, Dialog } from "@mui/material";
import { ApiLogo } from "src/assets/integrations/apiLogo";
import { useUpdateApiKeyMutation, useGetApiKeyMutation } from "src/services/integration-service";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { config } from "src/config.js";

const integrationData = [
  { logo: <ApiLogo />, title: "Success.ai API" }
];

const Api = ({ updateApiKey, getApiKey, isLoadingUpdateApiKey, value }) => {
  const [open, setOpen] = useState(false);
  const [clipboard, setClipboard] = useState('');
  const workspace = useSelector((state) => state.workspace);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleGenerateKey = async () => {
    try {
      const toastId = toast.loading("Generating new API key", { duration: Infinity });
      const { message } = await updateApiKey({workspace: workspace}).unwrap();
      toast.success(message, { id: toastId, duration: 2000 });
      setOpen(false);
    } catch (error) {
      toast.error("Failed to generate API key. Please try again later.");
    }
  };

  const getApiKeyForCopy = async () => {
    const { apiKey } = await getApiKey({workspace: workspace?._id}).unwrap();
    setClipboard(apiKey);
  };

  const copyToClipBoard = () => {
    navigator.clipboard
      .writeText(clipboard)
      .then(() => toast.success("Copied to Clipboard"))
      .catch(() => toast.error("Unable to copy to clipboard"));
  };

  useEffect(() => {
    getApiKeyForCopy();
  }, [isLoadingUpdateApiKey, value]);

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", padding: 3 }}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item>
            <Button variant="outlined" onClick={handleClickOpen}>Generate new API Key</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={!clipboard} onClick={copyToClipBoard}>Copy API Key</Button>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ display: 'block', marginTop: 2 }}>
          <Link href={`${config.API_BASE_URL}/docs`} color="primary" underline="none" target="_blank">
            See documentation
          </Link>
        </Typography>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "22px", fontWeight: "700" }}>Are you sure?</DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ letterSpacing: '1px', fontWeight: 400, fontSize: "15px" }}>
            This will generate a new API key and invalidate the old one.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ margin: 2 }}>
          <Button onClick={handleClose} color="primary" variant="outlined">Cancel</Button>
          <Button
            disabled={isLoadingUpdateApiKey}
            onClick={handleGenerateKey}
            color="primary"
            variant="contained"
          >
            Generate new API Key
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const IntegrationCard = ({ logo, title, children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: "0px 12px 15px 0px #4B71970D",
      width: "100%",
      p: 3,
      backgroundColor: "#fff",
      borderRadius: "12px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #E4E4E5",
        py: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "120px",
          height: "83px",
        }}
      >
        {logo}
      </Box>
    </Box>
    <Typography
      sx={{
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "28px",
        color: "#28287B",
        mt: 2,
        mb: 1,
        textAlign: 'center'
      }}
    >
      {title}
    </Typography>
    <Box sx={{ mt: 3, width: '100%' }}>
      {children}
    </Box>
  </Box>
);

const ApiKey = ({ value }) => {
  const [updateApiKey, { isLoading: isLoadingUpdateApiKey }] = useUpdateApiKeyMutation();
  const [getApiKey] = useGetApiKeyMutation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", mt: 3, padding: 3 }}>
      <Grid container spacing={3} justifyContent="center">
        {integrationData.map((integration, index) => (
          <Grid item xs={12} sm={6} md={8} key={index}>
            <IntegrationCard logo={integration.logo} title={integration.title}>
              <Api updateApiKey={updateApiKey} getApiKey={getApiKey} isLoadingUpdateApiKey={isLoadingUpdateApiKey} value={value} />
            </IntegrationCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ApiKey;