import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthProvider } from "../auth/auth";
import { useGetMeAndWorkspaceQuery } from "../services/user-service";
import isLoggedIn from "../utils/is-logged-in";
import { Box, CircularProgress, Typography } from "@mui/material";

const PrivateRoute = () => {
  const [userRole, setUserRole] = useState(null);

  const { data, isLoading } = useGetMeAndWorkspaceQuery(undefined, {
    skip: !localStorage.getItem("auth_token"),
  });

  useEffect(() => {
    if (data) {
      let role = data.workspace?.members?.find(member => member?.email === data.user?.email)?.role;
      if (!role && data?.user?._id && data?.workspace?._id) {
        role = data.user._id !== data.workspace.createdBy ? "Viewer" : "Admin";
      }
      setUserRole(role);
    }
  }, [data]);

  if (!isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CircularProgress color="primary" size={25} thickness={5} />
      <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#4e88e6", ml: 2 }}>
        Loading...
      </Typography>
    </Box>
  }

  return (
    <AuthProvider role={userRole}>
      <Outlet />
    </AuthProvider>
  );
};

export default PrivateRoute;
