import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setWorkspace, useGetWorkSpacesMutation } from 'src/services/workSpace-service';

function useWorkspaces() {
  const [getWorkSpaces] = useGetWorkSpacesMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAppSumoUser = (workspace) => {
    return workspace && workspace?.assignedPlan && workspace?.assignedPlan.length > 0;
  };

  const isEarlyBirdAccessUser = (workspace) => {
    return workspace && workspace?.isEarlyBirdAccessUser;
  };

  const isPaidUser = (workspace, currentPlan) => {
    return workspace && currentPlan && (currentPlan?.subscription?.sendingWarmup?.expiresAt || currentPlan?.subscription?.leads?.expiresAt);
  };

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const docs = await getWorkSpaces().unwrap();
        dispatch(setWorkspace(docs?.currentWorkspace));
        const currentWorkspace = docs?.currentWorkspace;
        const currentPlan = docs?.currentPlan;
        const uniqueWorkspaces = docs?.uniqueWorkspaces?.length;
  
        const isEarlyBird = isEarlyBirdAccessUser(currentWorkspace);
        const isPaid = isPaidUser(currentWorkspace, currentPlan);
        const isAppSumo = isAppSumoUser(currentWorkspace);
        if (
          (!currentPlan && !isAppSumo) ||
          (uniqueWorkspaces > 1 && !isPaid && !isEarlyBird && !isAppSumo)
        ) {
          navigate("/workSpace/billing", { state: { fromCreateWorkspace: true }, replace: true });
        }
      } catch (error) {
        console.error("Failed to fetch workspaces:", error);
      }
    };

    const timer = setTimeout(fetchWorkspaces, 500);
    return () => clearTimeout(timer);
  }, [getWorkSpaces, dispatch]);
}

export default useWorkspaces;
