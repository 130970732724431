export const Bounced = () => {
  return (
    <>
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="32" height="32" rx="16" fill="#F2D7FF" />
        <path
          d="M14.08 14.58V13.83C13.6658 13.83 13.33 14.1658 13.33 14.58H14.08ZM14.1437 14.58H14.8937C14.8937 14.1658 14.5579 13.83 14.1437 13.83V14.58ZM17.92 14.58V13.83C17.5058 13.83 17.17 14.1658 17.17 14.58H17.92ZM17.9766 14.58H18.7266C18.7266 14.1658 18.3909 13.83 17.9766 13.83V14.58ZM14.1437 14.6312V15.3812C14.5579 15.3812 14.8937 15.0454 14.8937 14.6312H14.1437ZM14.08 14.6312H13.33C13.33 15.0454 13.6658 15.3812 14.08 15.3812V14.6312ZM17.9766 14.6312V15.3812C18.3909 15.3812 18.7266 15.0454 18.7266 14.6312H17.9766ZM17.92 14.6312H17.17C17.17 15.0454 17.5058 15.3812 17.92 15.3812V14.6312ZM15.9999 18.1025V17.3525V18.1025ZM17.5809 19.7511C17.7989 20.1033 18.2612 20.2121 18.6134 19.9941C18.9656 19.7761 19.0743 19.3138 18.8563 18.9616L17.5809 19.7511ZM13.1435 18.9616C12.9255 19.3138 13.0343 19.7761 13.3865 19.9941C13.7387 20.2121 14.2009 20.1033 14.4189 19.7511L13.1435 18.9616ZM21.65 16.5C21.65 19.6204 19.1204 22.15 16 22.15V23.65C19.9488 23.65 23.15 20.4488 23.15 16.5H21.65ZM16 22.15C12.8796 22.15 10.35 19.6204 10.35 16.5H8.84998C8.84998 20.4488 12.0511 23.65 16 23.65V22.15ZM10.35 16.5C10.35 13.3796 12.8796 10.85 16 10.85V9.34998C12.0511 9.34998 8.84998 12.5511 8.84998 16.5H10.35ZM16 10.85C19.1204 10.85 21.65 13.3796 21.65 16.5H23.15C23.15 12.5511 19.9488 9.34998 16 9.34998V10.85ZM14.08 15.33H14.1437V13.83H14.08V15.33ZM17.92 15.33H17.9766V13.83H17.92V15.33ZM13.3937 14.58V14.6312H14.8937V14.58H13.3937ZM14.1437 13.8812H14.08V15.3812H14.1437V13.8812ZM14.83 14.6312V14.58H13.33V14.6312H14.83ZM17.2266 14.58V14.6312H18.7266V14.58H17.2266ZM17.9766 13.8812H17.92V15.3812H17.9766V13.8812ZM18.67 14.6312V14.58H17.17V14.6312H18.67ZM15.9999 18.8525C16.6557 18.8525 17.2416 19.203 17.5809 19.7511L18.8563 18.9616C18.2623 18.0021 17.2097 17.3525 15.9999 17.3525L15.9999 18.8525ZM14.4189 19.7511C14.7582 19.203 15.3442 18.8525 15.9999 18.8525L15.9999 17.3525C14.7901 17.3525 13.7375 18.0021 13.1435 18.9616L14.4189 19.7511Z"
          fill="#C867F4"
        />
      </svg>
    </>
  );
};
