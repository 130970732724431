import React, { useState, useMemo, useEffect } from 'react';
import { Box, FormControl, Select, ListSubheader, MenuItem, InputAdornment, IconButton, TextField, Button, Typography } from '@mui/material';
import { AddOutlined, Search as SBSearch, Email, Today, Done, Close, Circle, Home, Cached, Favorite, EmojiEvents, RadioButtonUnchecked } from '@mui/icons-material';
import { useUpdateLabelMutation } from "src/services/campaign-service";
import { toast } from "react-hot-toast";

export const renderLabelWithIcon = (label) => {
  switch (label.name) {
    case 'Lead':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span>{label.name}</span>
          <Email sx={{ fontSize: 20, color: '#ffcf3c', ml: 1 }} />
        </Box>
      );
    case 'Interested':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span>{label.name}</span>
          <Favorite sx={ {fontSize: 20, color: '#689f38', ml: 1}} />
        </Box>
      );
    case 'Not interested':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span>{label.name}</span>
          <Close sx={ {fontSize: 20, color: '#ee3131', ml: 1}} />
        </Box>
      );
    case 'Meeting booked':
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <span>{label.name}</span>
        <Today sx={ {fontSize: 20, color: '#97c7f3', ml: 1}} />
      </Box>
    );
    case 'Meeting completed':
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <span>{label.name}</span>
        <Done sx={ {fontSize: 20, color: '#689f38', ml: 1}} />
      </Box>
    );
    case 'Out of office':
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <span>{label.name}</span>
        <Home sx={ {fontSize: 20, color: '#852f02', ml: 1}} />
      </Box>
    );
    case 'Wrong person':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span>{label.name}</span>
          <Circle sx={ {fontSize: 20, color: '#272727', ml: 1}} />
        </Box>
      );
    case 'Won':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span>{label.name}</span>
          <EmojiEvents sx={ {fontSize: 20, color: '#c98d09', ml: 1, marginTop: '5px'}} />
        </Box>
      );
    case 'Lost':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span>{label.name}</span>
          <RadioButtonUnchecked sx={ {fontSize: 20, color: '#ee3131', ml: 1}} />
        </Box>
      );
    default:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span>{label.name}</span>
          <Cached sx={ {fontSize: 20, color: '#ffcf3c', ml: 1}} />
        </Box>
      );
  }
};

const StatusLabelSelect = ({
  handleClickOpenAddStatusDialog,
  isLabelsLoading,
  statusLabels,
  inboxSelectedID,
  label,
  fetchUpdatedData,
  canEdit
}) => {
  const [selectedOption, setSelectedOption] = useState(label?._id || statusLabels?.labels[0]._id);
  const [searchText, setSearchText] = useState("");
  const [updateLabel, { isLoading: isUpdatingLabel }] = useUpdateLabelMutation();
  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const displayedOptions = useMemo(
    () => statusLabels?.labels.filter((option) => containsText(option.name, searchText)),
    [searchText, statusLabels?.labels]
  );
  const handleStatusChange = async (e) => {
    const labelId = e.target.value;
    if (label === labelId) return;
    setSelectedOption(labelId);
    const { message } = await updateLabel({ campaignEmailId: inboxSelectedID, labelId }).unwrap();
    fetchUpdatedData();
    toast.success(message);
  };

  useEffect(() => {
    if (label) {
      setSelectedOption(label?._id);
    } else {
      setSelectedOption(statusLabels?.labels[0]._id);
    }
  }, [label]);

  return (
    <Box sx={{ mt: 0.5 }}>
      <FormControl>
        <Select
          size="small"
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "18px",
            color: "#28287B",
            "& .MuiOutlinedInput-input": {
              py: 0.5,
            },
          }}
          MenuProps={{
            autoFocus: false,
            PaperProps: {
              sx: {
                display: "flex",
                flexDirection: "column",
                height: "400px",
                overflowY: "auto",

                "&::-webkit-scrollbar": {
                  width: "14px",
                },

                "&::-webkit-scrollbar-track": {
                  borderRadius: "60px",
                },

                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#E4E4E5",
                  borderRadius: "10px",
                  border: "4px solid rgba(0, 0, 0, 0)",
                  backgroundClip: "padding-box",
                },

                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#d5d5d5",
                },
              },
            },
          }}
          labelId="search-select-label"
          id="search-select"
          value={selectedOption}
          onChange={canEdit && handleStatusChange}
          onClose={() => setSearchText("")}
        >
          <ListSubheader sx={{ px: 1 }}>
            {" "}
            <TextField
              placeholder="Search by status"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton sx={{ width: 32, height: 32 }}>
                      <SBSearch color="rgba(40, 40, 123, 0.5)" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                width: "100%",
                height: 40,
                backgroundColor: "white",
                "& div": { pl: 0.3 },
                "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                "& div input": {
                  py: 1.3,
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0em",
                  "&::placeholder": {
                    color: "rgba(40, 40, 123, 0.5)",
                  },
                },
                mb: 1,
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
            {canEdit && (
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "left",
                  px: 1.5,
                  py: 1,
                  mb: 1,
                  // mx: 2,
                  width: "100%",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "18px",
                  color: "#28287B",
                  "&:hover": {
                    backgroundColor: "#F2F4F6",
                    color: "#3F4FF8",
                  },
                  border: "1px solid #fff",
                }}
                onClick={handleClickOpenAddStatusDialog}
              >
                <Typography
                  sx={{
                    width: "calc(100% - 20px)",
                    textAlign: "left",

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "#28287B",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  Create New Label
                </Typography>
                <AddOutlined />
              </Button>
            )}
          </ListSubheader>
          {!isLabelsLoading &&
            displayedOptions.map((label, index) => {
              return (
                <MenuItem key={label._id} value={label._id} sx={{ mx: 1, borderRadius: "8px" }}>
                  {renderLabelWithIcon(label)}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StatusLabelSelect;