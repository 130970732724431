export const LFSearchHero = () => {
  return (
    <>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#E7F0FF" />
        <path
          d="M32.9265 33.0401L36.3996 36.4001M27.3996 23.2001C29.3878 23.2001 30.9996 24.8119 30.9996 26.8001M35.2796 27.4401C35.2796 31.77 31.7695 35.2801 27.4396 35.2801C23.1097 35.2801 19.5996 31.77 19.5996 27.4401C19.5996 23.1102 23.1097 19.6001 27.4396 19.6001C31.7695 19.6001 35.2796 23.1102 35.2796 27.4401Z"
          stroke="#0071F6"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};
