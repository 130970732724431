import { Box,Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteAccountModal from '../../components/emailAccounts/DeleteAccountModal';
import toast from "react-hot-toast";

const AccountMessage = () => {
const { messageData } = useParams();

const [open, setOpen] = useState(false);
const [checked, setChecked] = useState(false);
const [connectedEmail, setConnectedEmail] = useState("");

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleCheckboxChange = (event) => {
  setChecked(event.target.checked);
};

const handleContinue = () => {
  // formik.resetForm();
  handleClose();
};

const messageMap = {
  created: "Account is created successfully!",
  reconnected: "Account is reconnected successfully!",
};
const displayMessage = messageMap[messageData] || messageData;

useEffect(() => {
  if (displayMessage.includes("Already exist in another User")) {
    const emailMatch = displayMessage.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/);
    if (emailMatch) {
      setConnectedEmail(emailMatch[0]);
    }
    handleClickOpen();
  } else {
    setTimeout(() => {
      window.close();     
    }, 3000);
  }
}, [displayMessage, messageMap]);

useEffect(() => {
  if (messageData === "reconnected") {
    localStorage.removeItem("reconnect");
  }
  localStorage.removeItem("iframe");
}, [messageData]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign:"center"
          }}
        >
          <Typography 
            sx={{
              mt: 3,
              fontWeight: 600,
              fontSize: "18px",
              color: "rgba(0,0,0,0.5)",
              textAlign: "center",
            }}
          >
             {displayMessage}
          </Typography>
        </Box>
      </Box>
      <DeleteAccountModal
        open={open}
        handleClose={handleClose}
        handleContinue={handleContinue}
        checked={checked}
        handleCheckboxChange={handleCheckboxChange}
        connectedEmail={connectedEmail}
        // formikvalue={formikvalue}
      />
      
    </>
  );
};

export default AccountMessage;
