import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, TextField, CircularProgress } from "@mui/material";
import { useState } from "react";

const RemoveWorkSpaceDialog = ({ open, onClose, onClick, isLoading }) => {
  const [inputValue, setInputValue] = useState("");
  const [isInputValid, setIsInputValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setIsInputValid(value === "delete workspace");
    setShowError(value !== "delete workspace" && value !== "");
  };

  const handleProceedClick = () => {
    if (isInputValid) {
      onClick();
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <DialogTitle sx={{ textAlign: "center", fontSize: "28px", fontWeight: "bold" }}>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            This will result in all your workspace data being deleted immediately, including your campaigns and accounts.
          </Typography>
          <Typography sx={{ mb: 2, color: 'red', fontWeight: 'bold' }}>
            Warning: This action is not reversible. Please be certain.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Enter <span style={{ color: 'red', fontWeight: 'bold' }}>delete workspace</span> to proceed.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="delete workspace"
            value={inputValue}
            onChange={handleInputChange}
            sx={{ mt: 1 }}
            error={showError}
            helperText={showError ? "Incorrect input. Please type 'delete workspace' exactly." : ""}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={handleProceedClick}
            variant="contained"
            color="error"
            sx={{ mr: 1 }}
            disabled={!isInputValid}
          >
           { isLoading ? <CircularProgress size={20} sx={{ color: "white" }} /> : "Proceed" }
          </Button>
          <Button onClick={onClose} variant="contained" sx={{ backgroundColor: 'gray', color: 'white' }}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RemoveWorkSpaceDialog;