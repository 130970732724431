export const roles = {
    Admin: {
      canEdit: true,
      canView: true,
      canDelete: true,
    },
    Editor: {
      canEdit: true,
      canView: true,
      canDelete: false,
    },
    Viewer: {
      canEdit: false,
      canView: true,
      canDelete: false,
    },
  };
  