import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/auth/auth';

const ViewerRoute = ({ element: Component, ...rest }) => {
  const { canEdit } = useAuth();
  if (!canEdit) {
    return <Navigate to="/accounts" />;
  }
  if (canEdit) {
    return <Component {...rest} />;
  }
};

export default ViewerRoute;
