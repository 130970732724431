export const DocumentationIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#F7E5FF" />
        <path
          d="M19.6969 29.6001H13.6969C12.3714 29.6001 11.2969 28.5256 11.2969 27.2001L11.297 12.8001C11.297 11.4747 12.3715 10.4001 13.697 10.4001H24.4972C25.8227 10.4001 26.8972 11.4747 26.8972 12.8001V17.6001M15.4972 15.2001H22.6973M15.4972 18.8001H22.6973M22.6973 23.5541V26.4985C22.6973 27.9534 24.2487 29.2879 25.7036 29.2879C27.1586 29.2879 28.6973 27.9535 28.6973 26.4985V22.7793C28.6973 22.009 28.2544 21.2273 27.2694 21.2273C26.2156 21.2273 25.7036 22.009 25.7036 22.7793V26.3435M15.4972 22.4001H19.0973"
          stroke="#C867F4"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
