export const EAWarmupHealthPercent = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#DAFFFD" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.53848 8.13757C7.13857 7.53766 7.95235 7.20065 8.80088 7.20065C9.6494 7.20065 10.4632 7.53766 11.0633 8.13757L12.0009 9.07437L12.9385 8.13757C13.2337 7.83193 13.5868 7.58815 13.9772 7.42044C14.3676 7.25273 14.7875 7.16446 15.2124 7.16077C15.6373 7.15707 16.0587 7.23804 16.4519 7.39894C16.8452 7.55984 17.2025 7.79745 17.5029 8.0979C17.8034 8.39836 18.041 8.75565 18.2019 9.14891C18.3628 9.54218 18.4438 9.96356 18.4401 10.3885C18.4364 10.8133 18.3481 11.2332 18.1804 11.6237C18.0127 12.0141 17.7689 12.3672 17.4633 12.6624L12.0009 18.1256L6.53848 12.6624C5.93857 12.0623 5.60156 11.2485 5.60156 10.4C5.60156 9.55144 5.93857 8.73766 6.53848 8.13757V8.13757Z"
          stroke="#29CEC7"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
