export const OpenAiIcon = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.7 7.26991L28.33 9.09991C26.725 7.03291 24.262 5.89091 21.633 6.00791C17.313 6.19991 14 9.95291 14 14.2769V23.4199L24.5 29.5399L23.5 31.2599L11.794 24.4329C11.302 24.1459 11 23.6199 11 23.0509V14.3639C11 8.09991 16.129 2.78991 22.39 3.00691C25.669 3.11991 28.68 4.66291 30.7 7.26991Z"
          fill="#28287B"
        />
        <path
          d="M12.861 9.83301L13.261 12.8C10.669 13.157 8.44799 14.719 7.23499 17.054C5.24099 20.891 6.83498 25.636 10.58 27.799L18.498 32.37L29.048 26.337L30.038 28.063L18.273 34.787C17.779 35.069 17.172 35.068 16.679 34.784L9.15598 30.441C3.72998 27.308 1.69598 20.211 5.01398 14.898C6.75198 12.114 9.59398 10.279 12.861 9.83301Z"
          fill="#28287B"
        />
        <path
          d="M6.16092 26.5631L8.93092 27.7001C7.94392 30.1231 8.18592 32.8281 9.60192 35.0461C11.9279 38.6911 16.8349 39.6841 20.5789 37.5221L28.4969 32.9501L28.5469 20.7971L30.5369 20.8031L30.4779 34.3541C30.4759 34.9231 30.1709 35.4481 29.6779 35.7331L22.1549 40.0761C16.7299 43.2081 9.56692 41.4211 6.62392 35.8911C5.08292 32.9941 4.91392 29.6161 6.16092 26.5631Z"
          fill="#28287B"
        />
        <path
          d="M17.3 40.73L19.67 38.9C21.275 40.967 23.7381 42.109 26.3671 41.992C30.6871 41.8 34 38.047 34 33.723V24.58L23.5 18.46L24.5 16.74L36.2061 23.567C36.6981 23.854 37 24.38 37 24.949V33.636C37 39.9 31.8701 45.21 25.6101 44.994C22.3311 44.88 19.32 43.337 17.3 40.73Z"
          fill="#28287B"
        />
        <path
          d="M35.1389 38.167L34.7389 35.2C37.3309 34.843 39.5519 33.2809 40.7649 30.9459C42.7589 27.1089 41.1649 22.364 37.4199 20.201L29.5019 15.63L18.9519 21.663L17.9619 19.937L29.7269 13.213C30.2209 12.931 30.8279 12.9319 31.3209 13.2159L38.8439 17.5589C44.2689 20.6909 46.3029 27.788 42.9849 33.102C41.2479 35.886 38.4059 37.721 35.1389 38.167Z"
          fill="#28287B"
        />
        <path
          d="M41.8389 21.4371L39.0689 20.3001C40.0559 17.8771 39.8139 15.1721 38.3979 12.9541C36.0719 9.30912 31.1649 8.31612 27.4209 10.4781L19.5029 15.0501L19.4529 27.2031L17.4629 27.1971L17.5219 13.6461C17.5239 13.0771 17.8289 12.5521 18.3219 12.2671L25.8449 7.92412C31.2699 4.79212 38.4329 6.57912 41.3759 12.1091C42.9169 15.0061 43.0859 18.3841 41.8389 21.4371Z"
          fill="#28287B"
        />
      </svg>
    </>
  );
};
