import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/base-query.js';
import _ from "lodash";

export const integrationApi = createApi({
  reducerPath: 'integrationsApi',
  baseQuery: baseQuery,
  tagTypes: ['INTEGRATIONS'],
  endpoints: (builder) => ({
    addWebhook: builder.mutation({
      query: (newWebhook) => {
        return {
          url: 'integrations/add-webhook',
          method: 'POST',
          body: newWebhook,
        };
      },
    }),

    getAllWebhooks: builder.mutation({
      query: (queryParams) => {
        const params = new URLSearchParams(_.pickBy(queryParams));
        return {
          method: 'GET',
          url: 'integrations/all-webhook',
          params
        };
      },
      providesTags: ['Integrations'],
    }),

    deleteWebhook: builder.mutation({
      query: (id) => {
        return {
          method: "DELETE",
          url: `integrations/${id}`,
        };
      },
      invalidatesTags: ["Integrations"],
    }),

    getAllIntegrationLogs: builder.mutation({
      query: (queryParams) => {
        const params = new URLSearchParams(_.pickBy(queryParams));
        return {
          method: 'GET',
          url: 'integrations/all-integration-logs',
          params
        };
      },
      providesTags: ['Integrations'],
    }),

    updateApiKey: builder.mutation({
      query: (data) => {
        return {
          url: 'integrations/update-api-key',
          method: 'POST',
          body: data
        };
      },
    }),
    
    getApiKey: builder.mutation({
      query: (queryParams) => {
        const params = new URLSearchParams(_.pickBy(queryParams));
        return {
          method: 'GET',
          url: 'integrations/get-api-key',
          params
        };
      },
    }),

    sendDummyData: builder.mutation({
      query: (sendData) => {
        return {
          url: 'integrations/send-dummy-data',
          method: 'POST',
          body: sendData,
        };
      },
    }),
  }),
});

export const {
  useAddWebhookMutation,
  useGetAllWebhooksMutation,
  useDeleteWebhookMutation,
  useGetAllIntegrationLogsMutation,
  useUpdateApiKeyMutation,
  useGetApiKeyMutation,
  useSendDummyDataMutation,
} = integrationApi;
