import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, CircularProgress } from "@mui/material";

const RemoveMemberDialog = ({ open, onClose, onClick, isDeletingMember }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2, py: 1 }}>
        <DialogTitle sx={{ fontSize: "24px", textAlign: "center" }}>Are you sure?</DialogTitle>
        <DialogContent>
          You will need to invite this user again if you want to add them back to the workspace.
        </DialogContent>
        <DialogActions sx={{ mb: 3, justifyContent: 'center' }}>
          <Button onClick={onClick} variant="contained" color="error">
            {isDeletingMember ? <CircularProgress sx={{color : "#fff"}} size={20} thickness={5} /> : "Delete"}
          </Button>
          <Button onClick={onClose} variant="contained" sx={{ backgroundColor: 'black', color: 'white' }}>Cancel</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RemoveMemberDialog;
