import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  alpha,
  Popover,
  Tooltip
} from '@mui/material';
import { useSelector } from "react-redux";
import {
  useGetAccountsMutation,
} from "src/services/account-service.js";
import Pagination from "src/components/Pagination";
import { AiOutlineClose } from "react-icons/ai";
import { FilterIcon } from "src/assets/general/FilterIcon";
import { SBSearch } from "src/assets/sidebar/SBSearch";
import { Clear } from "@mui/icons-material";
import { EmailFilterPauseIcon } from "src/assets/emailAccounts/filterMenu/EmailFilterPausedIcon";
import { EmailFilterHasErrorIcon } from "src/assets/emailAccounts/filterMenu/EmailFilterHasError";
import { EmailFilterWarmupActiveIcon } from "src/assets/emailAccounts/filterMenu/EmailFilterWarmupActive";
import { EmailFilterWarmupPaused } from "src/assets/emailAccounts/filterMenu/EmailFilterWarmupPaused";
import { EmailFilterWarmupErrorsIcon } from "src/assets/emailAccounts/filterMenu/EmailFilterWarmupErrors";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const filterButtons = [
  {
    name: "On Pause",
    value: "paused",
    icon: (active) => <EmailFilterPauseIcon color={active ? "#0071F6" : "#28287B"} />,
  },
  {
    name: "Issues Detected",
    value: "has_errors",
    icon: (active) => <EmailFilterHasErrorIcon color={active ? "#0071F6" : "#28287B"} />,
  },
  {
    name: "Warmup In Progress",
    value: "warmup_active",
    icon: (active) => <EmailFilterWarmupActiveIcon color={active ? "#0071F6" : "#28287B"} />,
  },
  {
    name: "Warmup Paused",
    value: "warmup_paused",
    icon: (active) => <EmailFilterWarmupPaused color={active ? "#0071F6" : "#28287B"} />,
  },
  {
    name: "Warmup Issues",
    value: "warmup_has_errors",
    icon: (active) => <EmailFilterWarmupErrorsIcon color={active ? "#0071F6" : "#28287B"} />,
  },
];


const headCellsWarmups = [
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },
  { id: 'warmup', label: 'Warmup' },
  { id: 'warmupemails', label: 'Wamrup Emails' },
  { id: 'latesterror', label: 'Latest Error' },
];

const headCellsCampaign = [
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },
  { id: 'emailsent', label: 'Email Sent' },
  { id: 'clicks', label: 'Clicks' },
  { id: 'replies', label: 'Replies' },
  { id: 'latesterror', label: 'Latest Error' },
];

const headCellsBoth = [
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },
  { id: 'warmup', label: 'Warmup' },
  { id: 'emailsent', label: 'Email Sent' },
  { id: 'clicks', label: 'Clicks' },
  { id: 'replies', label: 'Replies' },
  { id: 'latesterror', label: 'Latest Error' },
];

function EnhancedTableHead({ headCells }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ClientsAccounts = () => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [accounts, setAccounts] = useState([]);
  const [filter, setFilter] = useState(null);
  const [workspaceId, setWorkspaceId] = useState();
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const workspace = useSelector((state) => state.workspace);
  const [getAccounts, { isLoading: isAccountsLoading }] = useGetAccountsMutation();
  const theme = useTheme();

  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const offset = accounts.length;

  useEffect(() => {
    setTimeout(async () => {
      if (workspaceId) {
        const { docs, total } = await getAccounts({ search, filter: filter?.value, offset: offset * (page - 1), limit, workspaceId, client: true }).unwrap();
        setAccounts(docs);
        setTotal(total);
      }
    }, 500);
  }, [limit, page, workspaceId, search, filter]);

  const handleSelectFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const headCells = workspace?.warmup?.warmupPlan && workspace?.campaign?.campaignPlan
    ? headCellsBoth
    : workspace?.warmup?.warmupPlan
      ? headCellsWarmups
      : headCellsCampaign;


  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: { xs: "80vh", md: "70vh" },
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
          p: 3,
          mt: 3,
          backgroundColor: "#ffffff",
          border: "1px solid #F2F4F7",
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start" },
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
            rowGap: { xs: 1, md: 0 },
            width: "100%",
            mb: 3
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start", width: { xs: "100%", md: "30%" } }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, color: "#28287b" }}>
              {`Only ${total ?? 0} out of ${workspace?.totalAccounts ?? 0} accounts  have been utilized.`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: { xs: "80%", md: "70%" }, gap: { xs: 2, md: 0 } }}>
            <Box sx={{ display: "flex" }}>
              {filter && (
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "13px",
                    fontWeight: 700,
                    lineHeight: "16.38px",
                    color: theme.palette.primary.main,
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),

                    mr: 2,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.2),
                    },
                    height: "40px",
                    px: 2,
                  }}
                  onClick={() => handleSelectFilter(null)}
                >
                  {filter.name}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      ml: 1,
                    }}
                  >
                    <AiOutlineClose />
                  </Box>
                </Button>
              )}
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "13px",
                  fontWeight: 700,
                  lineHeight: "16.38px",
                  color: "#28287B",
                  backgroundColor: "white",
                  mr: { xs: 0, md: 2 },
                  "&:hover": {
                    backgroundColor: "white",
                  },
                  border: filter ? "1px solid #0071F6" : "1px solid #E4E4E5",
                  height: "40px",
                  px: 2,
                }}
                onClick={handleClick}
              >
                <Box
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}
                >
                  <FilterIcon />
                </Box>
                Filter
              </Button>
            </Box>
            <TextField
              placeholder="Search by email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton sx={{ width: 32, height: 32 }}>
                      {search ? (
                        <Clear onClick={() => setSearch("")} />
                      ) : (
                        <SBSearch color="rgba(40, 40, 123, 0.5)" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                width: { xs: "100%", md: 300 },
                height: 40,
                backgroundColor: "white",
                "& div": { pl: 0.3 },
                "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                "& div input": {
                  py: 1.3,
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0em",
                  "&::placeholder": {
                    color: "rgba(40, 40, 123, 0.5)",
                  },
                },
              }}
              value={search}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
        {(isAccountsLoading && accounts.length === 0) ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress color="primary" size={25} thickness={5} />
            <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#4e88e6", ml: 2 }}>
              Loading...
            </Typography>
          </Box>
        ) : accounts.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No Data Available
            </Typography>
          </Box>
        ) : (
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
                stickyHeader
                aria-label="sticky table"
              >
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  {accounts.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align='left'
                        >
                          {row?.email}
                        </TableCell>
                        <TableCell align="left">{row?.status}</TableCell>
                        {workspace?.warmup?.warmupPlan && (
                          <TableCell align="left">{row?.warmup.status}</TableCell>
                        )}
                        {(workspace?.warmup?.warmupPlan && !workspace?.campaign?.campaignPlan) && (
                          <TableCell align="left">{row?.warmupStats?.sent_count ? row?.warmupStats?.sent_count : 0}</TableCell>
                        )}
                        {workspace?.campaign?.campaignPlan && (
                          <>
                            <TableCell align="left">{row?.campaignSend ? row?.campaignSend : 0}</TableCell>
                            <TableCell align="left">{row?.campaignStats?.click ? row?.campaignStats?.click : 0}</TableCell>
                            <TableCell align="left">{row?.campaignStats?.reply ? row?.campaignStats?.reply : 0}</TableCell>
                          </>
                        )}
                        <TableCell align="left">
                          <Tooltip sx={{color: "#595999"}} title={row?.error?.errorMessage ?? "No Error"} placement="top">
                            <IconButton>
                              <ErrorOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {Math.ceil(total / limit) > 0 && (
              <Grid
                item
                xs={12}
                sx={{ ml: { xs: 0, md: 2 }, mb: 2, width: { xs: "100%", md: "98%" } }}
              >
                <Pagination
                  page={page}
                  setPage={setPage}
                  total={total}
                  length={accounts?.length}
                  limit={limit}
                  handleLimitChange={handleLimitChange}
                />
              </Grid>
            )}
          </Paper>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // width: "fit-content",
            p: 1,
            width: "260px",
          }}
        >
          {filterButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1,
                  px: 1,

                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "#101828",
                  fontSize: "13px",
                  backgroundColor: filter?.name === item.name && "#F2F4F6",
                }}
                onClick={() => {
                  setFilter(item);
                  setPage(1);
                  handleClose();
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                  {item.icon(filter?.name === item.name)}
                  <Typography
                    sx={{
                      color: filter?.name === item.name ? "#0071F6" : "#28287B",
                      fontSize: "13px",
                      fontWeight: 700,
                      linHeight: "16px",
                      letterSpacing: "0px",
                      ml: 2,
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </Button>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};

export default ClientsAccounts;