import React from 'react';
import { Button } from '@mui/material';
import Logout from '@mui/icons-material/Logout';

const LogoutButton = ({ onClick }) => {
  return (
    <Button 
      sx={{ 
        mt: 2,
        backgroundColor: "#0071F6", 
        color: "white",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "18px",
        borderRadius: "8px",
        px: 4,
        py: 1.8,
        "&:hover": {
          backgroundColor: "#164694",
          boxShadow: 10, 
        },
      }} 
      onClick={onClick}
    >
      Logout
      <Logout fontSize="small" sx={{ ml: 1, color: "white" }} />
    </Button>
  );
};

export default LogoutButton;
