import jwtDecode from "jwt-decode";
import { getAuthToken } from "src/services/auth-service.js";

function getTokenFromUrl() {
  const params = new URLSearchParams(window.location.search);
  return params.get('token');
}

export default function isLoggedIn() {
  const tokenUrl = getTokenFromUrl();
  if(tokenUrl){
    localStorage.setItem("auth_token", tokenUrl)
  }
  const authToken = getAuthToken();
  return authToken && !isJwtExpired(authToken);
}

function isJwtExpired(token) {
  if (typeof token !== "string" || !token) throw new Error("Invalid token provided");

  let isJwtexpired = false;
  const { exp } = jwtDecode(token);
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) isJwtexpired = true;

  return isJwtexpired;
}
