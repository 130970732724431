import React from "react";
import { Box, LinearProgress, linearProgressClasses, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import CircleIcon from '@mui/icons-material/Circle';

const CustomCounterProgress = (props) => {
  const { countOf, maxCountOf, minRange, maxRange, barColor, minColor = "white", maxColor = "white" } = props;
  const BorderLinearProgress = styled(LinearProgress)(({ barcolor, value }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      background: `linear-gradient(to right, ${barColor} ${value}%, ${barColor}30 ${value}%, ${barColor}30 100%)`,
      borderRadius: 5,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: 'transparent',
    },
  }));
  const percentage = (value) => (value * 100) / maxCountOf;
  const progressPercentage = Math.min(percentage(countOf), 100);

  let tooltipTitle;
  if (countOf > maxRange && countOf > minRange) {
    tooltipTitle = "Long";
  } else if (countOf > minRange && countOf <= maxRange) {
    tooltipTitle = "Perfect";
  } else if (countOf <= minRange && countOf < maxRange) {
    tooltipTitle = "Short";
  } 

  return (
    <Box sx={{ position: "relative", mt: 1, width: "100%", height: "100", overflow: 'hidden'}}>
        <span>
      <BorderLinearProgress
        variant="determinate"
        value={progressPercentage}
        barcolor={barColor}
        sx={{
          height:"12px",
        }}
      />
     {countOf > 0 && (
      <Tooltip title={tooltipTitle} arrow placement="top">
        <CircleIcon
          sx={{
            fontSize: "19px",
            color: "#fafafa",
            position: "absolute",
            left: `${progressPercentage}%`,
            top: -3,
            transform: progressPercentage === 100 ? "translateX(-100%)" : "translateX(-50%)",
          }}
        />
      </Tooltip>
      )}
      </span>
      <Typography
        variant="body2"
        color={barColor}
        fontWeight="bold"
        sx={{
          position: "absolute",
          left: `${progressPercentage}%`,
          top: -20,
          fontSize: "0.8rem",
          transform: progressPercentage === 100 ? "translateX(-70%)" : "translateX(-50%)",
        }}
      >
        {/* {`${Math.round(progressPercentage)}%`} */}
      </Typography>
      <Box
        sx={{
          position: "absolute",
          left: `${percentage(minRange)}%`,
          top: 0,
          bottom: 0,
          borderLeft: `2px solid ${minColor}`,
          opacity: 0.9,
        }}
      >
      </Box>
        <Box
          sx={{
            position: "absolute",
            width: "1%",
            left: `${percentage(maxRange)}%`,
            top: 0,
            bottom: 0,
            borderLeft: `2px solid ${maxColor}`,
            opacity: 0.9,
          }}
        >
      </Box>
    </Box>
  );
};

export default CustomCounterProgress;
