export const EDWarmupEmailsSent = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#F7E5FF" />
        <path
          d="M10 10.2376C10 9.61358 9.15778 9.44817 8.93433 10.0321C8.39383 11.4445 8 12.708 8 13.3912C8 15.5683 9.79086 17.3332 12 17.3332C14.2091 17.3332 16 15.5683 16 13.3912C16 12.6572 15.5454 11.2534 14.9428 9.71468C14.1621 7.72145 13.7717 6.72484 13.2899 6.67116C13.1357 6.65398 12.9675 6.68486 12.8299 6.7556C12.4 6.97668 12.4 8.06366 12.4 10.2376C12.4 10.8908 11.8627 11.4202 11.2 11.4202C10.5373 11.4202 10 10.8908 10 10.2376Z"
          stroke="#C867F4"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};
