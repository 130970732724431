import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Box,
  Button,
  alpha,
  IconButton,
  Divider,
  Grid,
  Checkbox,
  styled,
  Skeleton,
  InputLabel,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  CheckOutlined,
  ChevronLeft,
  ChevronRight,
  CloseOutlined,
  EditOutlined,
  InfoOutlined,
  LanguageOutlined,
  PersonOutlineOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { GmailIcon } from "src/components/logos/gmailIcon";
import { MicrosoftIcon } from "src/components/logos/microsoftIcon";
import { useDebounce } from "src/hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { paths } from "src/paths";
import OrderStatus from "./dfyDomains"
import axios from "axios";

import { config } from "src/config";
import { useFormik } from "formik";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import * as Yup from "yup";
import { useCreateCustomerPortalSessionMutation,useCreateDomainSubscriptionMutation } from "src/services/billing-service";
// import { getSessionStorage } from "src/utils/storage";
import { useGetPaymentMethodQuery } from "src/services/billing-service";
import { toast } from "react-hot-toast";
import { getAuthEmail } from "src/services/auth-service";

const API_SERVICE_BACKEND = config.API_BASE_URL;

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 6,
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "rgba(18, 185, 129, 1)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "rgba(18, 185, 129, 1)",
  },
});

const CustomCheckBox = (props) => {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
};
function calculateDomains(emailsPerDay) {
  const emailsPerDomain = 3 * 50;  // 3 mailboxes per domain, each sending 50 emails
  return Math.ceil(emailsPerDay / emailsPerDomain);
}

const benefitsText = [
  "We set up your accounts",
  "Automatic reconnects",
  "US IPs only",
  "Email deliverability optimized",
  "Save time and money",
  "Mailboxes starting at $3.50",
];

const AddNew = () => {
  const [createCustomerPortalSession] = useCreateCustomerPortalSessionMutation();
  const [createDomainSubscription] = useCreateDomainSubscriptionMutation();
  const {data:paymentMethods} = useGetPaymentMethodQuery()
  const [step, setStep] = useState(0);
  const [setupType, setSetupType] = useState("");
  const [searchDomain, setSearchDomain] = useState("");
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [domainTotal, setDomainTotal] = useState(0);
  const [selectedDomainInfo, setSelectedDomainInfo] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [allowLoadMoreResult, setAllowLoadMoreResult] = useState(true)
  const [viewMoreIndex, setViewMoreIndex] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [total, setTotal] = useState(false);
  const [editDomain, setEditDomain] = useState("");
  const [forwardDomain, setForwardDomain] = useState("");
  const [forwardDomainError, setForwardDomainError] = useState(false)
  const [domainSetupCharge, setDomainSetupCharge] = useState(0)
  const [mailBoxSetupCharge, setMailBoxSetupCharge] = useState(0)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [isPlaceOrderCofirmatiomModalOpen, setIsPlaceOrderCofirmatiomModalOpen] = useState(false)
  const [isFetchingPaymentMethods, setIsFetchingPaymentMethods] = useState(false)
  const [paymentCards, setPaymentCards] = useState([])
  const [selectedCard, setSelectedCard] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState({
    domain: false,
    mailbox: false,
  })
  
  const email = getAuthEmail();
  const [userDetail, setuserDetail] = useState({});
  const debounceValue = useDebounce(searchDomain, 1000);
  let finalTotal = total + domainTotal + domainSetupCharge + mailBoxSetupCharge;
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      status: "pending",
      domain: "all",
      forwardDomain: ""
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required").matches(/^[a-zA-Z]+$/, "Only letters are allowed"),
      lastName: Yup.string().required("Last Name is required").matches(/^[a-zA-Z]+$/, "Only letters are allowed"),
      email: Yup.string().email("Invalid email"),
      domain: Yup.string().required("Domain is required"),
    }),
    onSubmit: async (values) => {
      handleAddNewEmail(values);
    },
  });
  const navigate = useNavigate();

  const getUserInfo = async () => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/auth/user/${email}`
      );
      const userData = res.data.data;
      setuserDetail(userData);
    } catch (error) {
      throw error; // You can choose to re-throw the error or handle it here
    }
  };


  useEffect(() => {
    if (email) {
      getUserInfo();
    }
  }, [email]);

  const handleSelectDomain = (domain) => {
    let updatedDomains = [...selectedDomains];
    if (updatedDomains.includes(domain)) {
      const remove = selectedDomainInfo.find((dom) => dom.domain === domain);
      let amount = remove.contactDetails.length * 3.5;
      setMailBoxSetupCharge(mailBoxSetupCharge - (remove.contactDetails.length * 1.00))
      setTotal(total - amount);
      setSelectedDomainInfo(selectedDomainInfo.filter((dom) => dom.domain !== domain));
      setDomainTotal(domainTotal - 13);
      setDomainSetupCharge(domainSetupCharge - 1.5)
      updatedDomains = updatedDomains.filter((dom) => dom !== domain);
    } else {
      const domainInfo = suggestions.find((sug) => sug.domain === domain);
      setSelectedDomainInfo([...selectedDomainInfo, { ...domainInfo, price: 3.5, contactDetails: [] }]);
      setDomainTotal(domainTotal + 13);
      setDomainSetupCharge(domainSetupCharge + 1.5)

      updatedDomains = updatedDomains.concat(domain);
    }
    setSelectedDomains(updatedDomains);
  };
  const fetchSuggestedDomain = async (domain) => {
    const prefix = ["dev", "bussiness", "team", "office", "pro"]

    const [
      response1,
      response2,
      response3,
      response4,
      response5,
      response6,
      response7,
      response8,
      response9,
      response10,
      response11



    ] = await Promise.all([
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${domain}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${prefix[0]}${domain}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${domain}${prefix[0]}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${domain}${prefix[1]}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${prefix[1]}${domain}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${prefix[2]}${domain}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${domain}${prefix[2]}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${prefix[3]}${domain}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${domain}${prefix[3]}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${prefix[4]}${domain}`),
      await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${domain}${prefix[4]}`)


    ])
    const validZones = ["com", "co", "org"];
    const endsWithValidZone = (domain) => validZones.some(zone => domain.endsWith(`.${zone}`));

    const filterDomains = (data) =>
      data.filter(domain => validZones.includes(domain?.zone) || endsWithValidZone(domain.domain)) ?? [];

    const allResponses = [
      response1.data.data,
      response2.data.data,
      response3.data.data,
      response4.data.data,
      response5.data.data,
      response6.data.data,
      response7.data.data,
      response8.data.data,
      response9.data.data,
      response10.data.data,
      response11.data.data
    ];

    const suggestions = allResponses.flatMap(filterDomains);

    setSuggestions(suggestions);

  }
  const fetchSuggestedWithPreAndPostFix = async (domain1, domain2) => {
    const [response1, response2] = await Promise.all([await axios.get(`${API_SERVICE_BACKEND}/dfy-setup/searchDomain/${domain1}`), await axios.get(`${API_SERVICE_BACKEND}/DFYInfraSetup/searchDomain/${domain2}`)])
    setSuggestions([...suggestions,
    ...(response1.data.data.filter((domain) => (["com", "co", "org"].includes(domain?.zone))) ?? []),
    ...(response2.data.data.filter((domain) => (["com", "co", "org"].includes(domain?.zone))) ?? [])
    ])
  }
  const handleLoadMore = async () => {
    const prefix = ["work", "app", "site", "online", "web", "net", "store", "shop", "tech", "digital", "cloud", "host", "server", "email", "mail", "inbox"]
    setLoadingMore(true);
    const updatedIndex = viewMoreIndex + 1;
    setViewMoreIndex(updatedIndex);
    await fetchSuggestedWithPreAndPostFix(`${debounceValue}${prefix[updatedIndex]}`, `${prefix[updatedIndex]}${debounceValue}`)
    if (prefix.length === updatedIndex + 1) {
      setAllowLoadMoreResult(false)
    }
    setLoadingMore(false);
  };
  useEffect(() => {
    if (searchDomain === "") {
      setSuggestions([]);
      return;
    }
    setLoading(true);
    const loader = setTimeout(() => {
      setSuggestions([]);
      setAllowLoadMoreResult(true)
      setViewMoreIndex(0)
      fetchSuggestedDomain(debounceValue).then(() => {
        setLoading(false);
      })


    }, 1000);
    return () => {
      clearTimeout(loader);
    };
  }, [debounceValue]);

  const urlPattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  const urlValidation = (value) => {
    if (value == "" || !urlPattern.test(value)) {
      setForwardDomainError(true)
    } else {
      setForwardDomainError(false)
    }

  }

  // get card details
  const getCardDetails = async() => {
    try{
      // let baseURL =`${API_SERVICE_BACKEND}/getUserPaymentMethods?userEmail=${sessionStorage.getItem("userEmail")}`  
      // const response = await axios.get(baseURL)
      const response = paymentMethods;
      console.log("paymentMethods", paymentMethods)
      if(paymentMethods.length > 0){
      setSelectedCard(paymentMethods[0].id)
      setPaymentCards(paymentMethods ??[])
      setIsFetchingPaymentMethods(false)
    } else {
      setSelectedCard(null);
      setPaymentCards([]);
    }
    setIsFetchingPaymentMethods(false)
  }catch(error){
    setSelectedCard(null);
    setPaymentCards([]);
    setIsFetchingPaymentMethods(false)
  }
  }
  // new payment redirection
  const handleNewPaymentMethod = async() => {
    try {
      const baseURL = `${API_SERVICE_BACKEND}/createCustomerPortalSession?userEmail=${sessionStorage.getItem("userEmail")}`;
      const response = await createCustomerPortalSession();
      handlePlaceOrderModalClose();
      if(response.data.url){
      window.open(response.data.url, "_blank");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.message);
      console.log(error);
    }
  }
  const handleSelectPaymentMethod = (card) => {
    setSelectedCard(card)
  }
  // dialogs


  // payment status
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(true);
    setIsPlaceOrderCofirmatiomModalOpen(true)
    setIsFetchingPaymentMethods(true)
    getCardDetails()
    // handlePlaceOrder()
  };
const handlePlaceOrder = () => {
    setIsPlaceOrderCofirmatiomModalOpen(false)
    setIsConfirmationDialogOpen(false)
    handleDomainSubscription()
}

    
  const handleClose = () => {
    setOpen(false);
    setIsPlaceOrderCofirmatiomModalOpen(false)
  };
  // confirm payment
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handlePlaceOrderModalClose = () => {
    setOpen1(false);
    setIsPlaceOrderCofirmatiomModalOpen(false)
  };
  //payment completed
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
    setSelectedDomainInfo([])
    setSearchDomain("")
    setSuggestions([])
    setSelectedDomains([])
    setTotal(0)
    formik.resetForm()
    // setStep(3)

  };

  const handleClose2 = () => {
    setOpen2(false);
    navigate(paths.emailAccounts.orderStatus)
  };
  const handleDomainSubscription = async () => {
    if (forwardDomainError || forwardDomain === "") {
      urlValidation(forwardDomain)
      if (forwardDomain === "") {
        toast.error("Forward domain is required")
        return;
      }
      return;
    };
    const payload = {
      userEmail: getAuthEmail(),
      paymentMethodId: selectedCard,
      type: setupType,
      forwardDomain,
      domains: selectedDomainInfo,
    };

    try {
      const response = await createDomainSubscription(payload);
      console.log("response", response)
      if (response.data.success) {
        handleClickOpen2()
      }else{
        toast.error(response.response.data.error ?? "Something went wrong")
      }
    } catch (error) {
      toast.error(error.response.data.error ?? "Something went wrong");
    }
  };


  const handleEditContactDetail = (domain, index) => {
    setIsEdit(true)
    formik.resetForm()
    const updatedDomain = selectedDomainInfo.map((dom) => {
      if (dom.domain == domain.domain) {
        setEditIndex(index)
        setEditDomain(domain.domain)
        formik.setFieldValue("firstName", dom.contactDetails[index].firstName)
        formik.setFieldValue("lastName", dom.contactDetails[index].lastName)
        formik.setFieldValue("email", dom.contactDetails[index].email)
        formik.setFieldValue("domain", domain.domain)

        return dom
      }
      return dom
    })
    setSelectedDomainInfo(updatedDomain)
  }

  const handleAddNewEmail = (values) => {
    if (isEdit) {
      if (!values.email.includes(`@${editDomain}`)) {
        formik.setFieldError("email", "Email is invalid")
        return
      }
      const updatedDomain = selectedDomainInfo.map((dom) => {
        if (dom.domain == values.domain) {
          const isDuplicate = dom.contactDetails.find((contact) => contact.email === values.email)
          const isValid = dom.contactDetails.findIndex((contact) => contact.email === values.email)
          if (isDuplicate && isValid !== editIndex) {
            formik.setFieldError("email", "Email already exists")
            return dom
          }
          dom.contactDetails[editIndex] = { firstName: values.firstName, lastName: values.lastName, email: values.email }
          setIsEdit(false)
          setEditIndex(null)
          formik.resetForm()
          return dom
        }
        return dom
      })
      setSelectedDomainInfo(updatedDomain)
    } else {
      let increment = 0;
      if (values.domain != "" && values.domain != "all") {
        const updatedDomain = selectedDomainInfo.map((dom) => {
          if (dom.domain == values.domain && dom.contactDetails.length < 3) {

            dom.contactDetails.push({ firstName: values.firstName, lastName: values.lastName, email: `${values.firstName.replaceAll(' ', '').toLowerCase()}@${dom.domain}` })
            dom.contactDetails.push({ firstName: values.firstName, lastName: values.lastName, email: `${values.lastName.replaceAll(' ', '').toLowerCase()}@${dom.domain}` })
            dom.contactDetails.push({ firstName: values.firstName, lastName: values.lastName, email: `${values.firstName.replaceAll(' ', '').toLowerCase()}${values.lastName.replaceAll(' ', '').toLowerCase()}@${dom.domain}` })

            dom.contactDetails = [...new Set(dom.contactDetails)]
            increment += 3 * 3.5
            setMailBoxSetupCharge(mailBoxSetupCharge + (1.00 *3))
            setTotal(total + increment)

            formik.resetForm()
            return dom
          }
          return dom;
        })
        setSelectedDomainInfo(updatedDomain)
      } else if (values.domain == "all") {

        const updatedDomain = selectedDomainInfo.map((dom) => {
          if (!dom?.contactDetails || dom?.contactDetails?.length === 0) {
            dom.contactDetails = [
              { firstName: values.firstName, lastName: values.lastName, email: `${values.firstName.replaceAll(' ', '').toLowerCase()}@${dom.domain}` },
              { firstName: values.firstName, lastName: values.lastName, email: `${values.lastName.replaceAll(' ', '').toLowerCase()}@${dom.domain}` },
              { firstName: values.firstName, lastName: values.lastName, email: `${values.firstName.replaceAll(' ', '').toLowerCase()}${values.lastName.replaceAll(' ', '').toLowerCase()}@${dom.domain}` }
            ]
            increment += 3.5 * 3
            return dom
          }
          return dom
        })
        setTotal(total + increment)
        setMailBoxSetupCharge(mailBoxSetupCharge + (increment/3.5* 1.00))

        formik.resetForm();
        setSelectedDomainInfo(updatedDomain)
      }
    }
  }
  const handleCancelEdit = () => {
    setIsEdit(false)
    setEditIndex(null)
    setEditDomain("")
    formik.resetForm()
  }
 

  return (
    <>
      {step == 0 && <Box sx={{ width: "200px",padding: "10px 20px" }}>
        <Button
          variant="outlined"
          onClick={() => {
            formik.resetForm()
            navigate(paths.emailAccounts.orderStatus);
            setIsEdit(false)
            setEditIndex(null)
            setEditDomain("")

          }}
          startIcon={<KeyboardArrowLeftIcon />}
        >Back</Button>
      </Box>}

      <Stack sx={{ mt: 3, height: "100%" }}>
        {step === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100% - 24px)",
              width: "100%",
            }}
          >
            <Stack spacing={3}>
              <Typography
                sx={{ fontSize: "32px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Done-For-Your Email Setup
              </Typography>
              <Stack spacing={1}>
                <Box
                  onClick={() => {
                    setStep(1);
                    setSetupType("google");
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    p: 1,
                    borderRadius: 1,
                    border: "1px solid #E0E0E5",
                    boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 1,
                        borderRadius: 1,
                        border: "1px solid #E0E0E5",
                      }}
                    >
                      <GmailIcon size={"40px"} />
                    </Box>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#5B5B5B",
                        }}
                      >
                        Google
                      </Typography>

                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                          fontSize: "24px",
                          fontWeight: 600,
                          color: "#5B5B5B",
                        }}
                      >
                        Gmail{" "}
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{ borderColor: alpha("#101828", 0.5) }}
                        />{" "}
                        G-Suite{" "}
                      </Typography>
                    </Stack>
                  </Box>

                  <IconButton
                    variant="outlined"
                    onClick={() => {
                      setStep(1)
                      setSetupType("microsoft")
                    }}

                    sx={{
                      borderRadius: "12px",
                      p: "12px",
                      border: "1px solid #E0E0E5",
                    }}
                  >
                    <ChevronRight sx={{ color: "#5862FF" }} />
                  </IconButton>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    p: 1,
                    borderRadius: 1,
                    border: "1px solid #E0E0E5",
                    boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 1,
                        borderRadius: 1,
                        border: "1px solid #E0E0E5",
                      }}
                    >
                      {" "}
                      <MicrosoftIcon size={"40px"} />
                    </Box>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#5B5B5B",
                        }}
                      >
                        Microsoft{" "}
                        <span style={{ color: "#5862FF" }}>(Coming Soon)</span>
                      </Typography>

                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                          fontSize: "24px",
                          fontWeight: 600,
                          color: "#5B5B5B",
                        }}
                      >
                        Office 365{" "}
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{ borderColor: alpha("#101828", 0.5) }}
                        />{" "}
                        Outlook
                      </Typography>
                    </Stack>
                  </Box>
                </Box> */}
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                  Includes:
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      {benefitsText.slice(0, 3).map((item, index) => (
                        <Typography
                          key={index}
                          sx={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 1,
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#61616F",
                          }}
                        >
                          <CheckOutlined sx={{ color: "#149C1F" }} />
                          {item}
                        </Typography>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      {benefitsText.slice(3, 6).map((item, index) => (
                        <Typography
                          key={index}
                          sx={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 1,
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#61616F",
                          }}
                        >
                          <CheckOutlined sx={{ color: "#149C1F" }} />
                          {item}
                        </Typography>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Box>
        ) : step === 1 ? (
          <Stack spacing={2} sx={{padding: "10px 20px" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setSearchDomain("");
                  formik.resetForm();
                  setSelectedDomains([]);
                  setStep(0);
                  setIsEdit(false)
                  setEditIndex(null)
                  setEditDomain("")
                }}
                sx={{ width: "fit-content" }}
                startIcon={<ChevronLeft />}
              >
                Back
              </Button>
            </Box>
            <Stack
              sx={{ border: "1px solid rgba(224, 224, 229, 1)", borderRadius: 2 }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                  fontSize: "16px",
                  fontWeight: 600,
                  p: 2,
                  borderBottom: "1px solid rgba(224, 224, 229, 1)",
                }}
              >
                {" "}
                <LanguageOutlined sx={{ color: "rgba(88, 98, 255, 1)" }} />
                Enter your domain
              </Typography>
              <Box sx={{ p: 3 }}>
                {" "}
                <TextField
                  fullWidth
                  variant="outlined"
                  value={searchDomain}
                  onChange={(e) => setSearchDomain(e.target.value)}
                  placeholder="Type your domain name to start"
                  sx={{
                    "& .MuiOutlinedInput-root": { height: "46px" },
                    "& fieldset": {
                      border: "1px solid #E0E0E5",
                      boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                    },
                  }}
                />
              </Box>
            </Stack>

            {!loading &&
              suggestions?.length > 0 ? (
              <>
                <Stack spacing={2}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                    Suggested
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 1,
                      backgroundColor: "rgba(233, 233, 236, 1)",
                      border: "1px solid rgb(224, 224, 229)",
                      borderRadius: "12px",
                      boxShadow: "rgba(16, 24, 40, 0.05) 0px 1px 2px 0px",
                      p: 2,
                    }}
                  >
                    {suggestions.length > 0 ? suggestions.map((suggestion, index) => {
                      const isSelected = selectedDomains?.includes(
                        suggestion.domain
                      );
                      return (
                        <Box
                          key={index}
                          variant="outlined"
                          onClick={() => {
                            if (!suggestion.isAvailable) return;
                            handleSelectDomain(suggestion.domain);
                          }}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 1,
                            border: isSelected
                              ? "1px solid rgba(16, 168, 117, 1)"
                              : "1px solid rgba(224, 224, 229, 1)",
                            borderRadius: 1,
                            backgroundColor: isSelected
                              ? "rgba(231, 248, 242, 1)"
                              : "#ffffff",
                            color: "#111927",
                            p: 1,
                          }}
                        >
                          {suggestion.isAvailable && (
                            <CustomCheckBox
                              checked={isSelected}
                              sx={{ p: 0 }}
                              disabled={!suggestion.isAvailable}
                            />
                          )}
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 500,
                              textDecoration: !suggestion.isAvailable
                                ? "line-through"
                                : "none",
                              color: !suggestion.isAvailable
                                ? "#5B5B5B"
                                : "#111927",
                            }}
                          >
                            {" "}
                            {suggestion.domain}
                          </Typography>

                          {/* {suggestion.isAvailable &&
                          (isSelected ? <CloseOutlined /> : <CheckOutlined />)} */}
                        </Box>
                      );
                    }) : (
                      <Typography>No result</Typography>
                    )}
                  </Box>
                  {allowLoadMoreResult && !loadingMore ? (
                    <Button
                      variant="contained"
                      fullWidth

                      onClick={handleLoadMore}
                    >
                      Search for more
                    </Button>
                  ) : loadingMore ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <CircularProgress />
                      <Typography
                        sx={{
                          color: "#5B5B5B",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Loading...
                      </Typography>
                    </Box>
                  ) : null}
                </Stack>

                {selectedDomains?.length > 0 && (
                  <>
                  <Stack
                    spacing={2}
                    sx={{
                      backgroundColor: "#ffffff",
                      border: "1px solid rgb(224, 224, 229)",
                      borderRadius: "12px",
                      boxShadow: "rgba(16, 24, 40, 0.05) 0px 1px 2px 0px",
                      p: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      Selected Domains : {selectedDomains.length}
                    </Typography>
                    <TableContainer
                      component={Paper}
                      sx={{ boxShadow: "none" }}
                    >
                      <Table
                        sx={{
                          minWidth: 650,
                          borderCollapse: "separate",
                          borderSpacing: "0px 10px !important ",
                        }}
                        aria-label="simple table"
                      >
                        <TableHead
                          sx={{
                            "& .MuiTableCell-root": {
                              backgroundColor: "#E9E9EC",
                            },
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                                border: "1px solid #E0E0E5",
                                borderRight: "none",
                              }}
                            >
                              Domain name
                            </TableCell>

                            <TableCell
                              align="right"
                              sx={{
                                border: "1px solid #E0E0E5",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                            >
                              Price
                            </TableCell>
                            <TableCell
                              sx={{
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                border: "1px solid #E0E0E5",
                                borderLeft: "none",
                              }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedDomains.map((domain, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                borderRadius: "10px",
                                "& td": {
                                  borderTop: "1px solid #12B981",
                                  borderBottom: "1px solid #12B981",
                                  borderRight: "none !important",
                                },
                                "& td:first-child": {
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                  borderLeft: "1px solid #12B981",
                                  borderRight: "none",
                                },
                                "& td:last-child": {
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                  borderRight: "1px solid #12B981 !important",
                                  borderLeft: "none",
                                },
                              }}
                            >
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <LanguageOutlined
                                    sx={{ color: "rgba(88, 98, 255, 1)" }}
                                  />{" "}
                                  {domain}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  $13.00
                                </Typography>
                              </TableCell>
                              <TableCell align="right" width={70}>
                                <IconButton
                                  onClick={() => handleSelectDomain(domain)}
                                >
                                  <CloseOutlined />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  
                  </Stack>
             
                  <Stack spacing={1}
                    sx={{
                      p: 2,
                      border: "1px solid #E0E0E5",
                      borderRadius: 1,
                      boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                    }}
                    >
                    <Box
                      sx={{
                        display: "flex",
                        flex:1,
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                       <Tooltip 
                      title="Doamin charges billed anually."
                      arrow
                      >
                        <InfoOutlined sx={{ fontSize: "16px", color: "#5B5B5B" }} />
                      </Tooltip>
                      <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                        <Typography sx={{ fontSize: "14px" }}>
                       Domains: 
                      
                      </Typography>
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        ${domainTotal.toFixed(2)}
                      </Typography>
                      </Box>
                      </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flex:1,
                        justifyContent: "flex-end",
                        alignItems: "end",
                        gap: 1,
                      }}
                    >
                      <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Tooltip title="The setup charge is one time only." arrow>
                      <InfoOutlined fontSize="16px" color="#5B5B5B" />
                    </Tooltip>  
                        <Typography sx={{ fontSize: "14px" }}>
                        Setup charge: 
                      </Typography>
                      
                      </Box>
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        ${domainSetupCharge.toFixed(2)}
                      </Typography>
                      </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "14px"  }}>
                     Subtotal (Annually):
                       
                      </Typography>
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        ${(domainTotal+ domainSetupCharge).toFixed(2)}
                        </Typography>
                    
                    </Box>
                  
                      </Stack>
                      <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        gap: 1,
                        pb:5
                      }}
                    >
                    <Button
                        variant="contained"
                        sx={{ width: "fit-content" }}
                        onClick={() => setStep(2)}
                      >
                        Next
                      </Button>
                      </Box>
                      </>
                         )}
              </>

            ) : loading ? (
              <>
                <Stack
                  spacing={1}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Loading domain suggestions
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 1,
                      width: "75%",
                      p: 2,
                    }}
                  >
                    {new Array(8).fill(0).map((item, index) => (
                      <Box
                        key={index}
                        variant="outlined"
                        sx={{
                          border: "1px solid rgba(224, 224, 229, 1)",
                          borderRadius: 1,
                          backgroundColor: "#ffffff",
                          color: "#111927",
                          p: 1,
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <Skeleton
                          width={(Math.random() * index * 8) / 2 ? 120 : 80}
                        />
                      </Box>
                    ))}
                  </Box>
                </Stack>
              </>
            ) : (
              !loading && suggestions.length == 0 && debounceValue ? <Typography align="center">No Result</Typography> : <Typography align="center">Search domain</Typography>
            )}
          </Stack>
        ) : step === 2 ? (
          <div style={{padding: "10px 20px" }}>
            <Stack spacing={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  setStep(1);
                  formik.resetForm();
                  setIsEdit(false)
                  setEditIndex(null)
                  setEditDomain("")
                }}
                sx={{ width: "fit-content" }}
                startIcon={<ChevronLeft />}
              >
                Back
              </Button>
              <Stack spacing={1}>
                <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                  Order
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#5B5B5B" }}
                >
                  Order new emails
                </Typography>
              </Stack>
              <Stack
                sx={{
                  border: "1px solid rgba(224, 224, 229, 1)",
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    fontSize: "16px",
                    fontWeight: 600,
                    p: 2,
                    borderBottom: "1px solid rgba(224, 224, 229, 1)",
                  }}
                >
                  {" "}
                  <LanguageOutlined sx={{ color: "rgba(88, 98, 255, 1)" }} />
                  Forwarding domain
                </Typography>
                <Box sx={{ p: 3 }}>
                  {" "}
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={forwardDomain}
                    onBlur={() => urlValidation(forwardDomain)}
                    error={forwardDomainError}
                    placeholder={"https://www.example.com"}
                    onChange={(e) => {
                      urlValidation(e.target.value)
                      setForwardDomain(e.target.value.trim())
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": { height: "46px" },
                      "& fieldset": {
                        border: "1px solid #E0E0E5",
                        boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                      },
                    }}
                  />
                 { forwardDomainError &&<Typography
                    sx={{
                      fontSize: "12px",
                      color: "#ff0000",
                      fontWeight: 500,
                      py:1
                    }}
                  >
                   {forwardDomainError && "Invalid URL or should be start with https:// or www."}
                  </Typography>}
                </Box>
              </Stack>
              <Stack
                sx={{
                  border: "1px solid rgba(224, 224, 229, 1)",
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    fontSize: "16px",
                    fontWeight: 600,
                    p: 2,
                    borderBottom: "1px solid rgba(224, 224, 229, 1)",
                  }}
                >
                  {" "}
                  <PersonOutlineOutlined sx={{ color: "rgba(88, 98, 255, 1)" }} />
                  Contact details
                </Typography>
                <Stack sx={{ p: 3 }} spacing={2}>
                  <Grid container gap={2}>
                    <Grid item xs={12} sm={5.9}>
                      <Stack spacing={0.5}>
                        <InputLabel
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#0A0A0A",
                          }}
                        >
                          First Name
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          value={isEdit ? "" : formik.values.firstName}
                          disabled={isEdit}
                          error={!isEdit && formik.touched.firstName && Boolean(formik.errors.firstName)}
                          name="firstName"
                          onChange={e => formik.setFieldValue("firstName", e.target.value.trim())}
                          sx={{
                            "& .MuiOutlinedInput-root": { height: "46px" },
                            "& fieldset": {
                              border: "1px solid #E0E0E5",
                              boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                            },
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={5.9}>
                      <Stack spacing={0.5}>
                        <InputLabel
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#0A0A0A",
                          }}
                        >
                          Last Name
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          disabled={isEdit}
                          value={isEdit ? "" : formik.values.lastName}
                          error={!isEdit && formik.touched.lastName && Boolean(formik.errors.lastName)}
                          onChange={e => formik.setFieldValue("lastName", e.target.value.trim())}
                          sx={{
                            "& .MuiOutlinedInput-root": { height: "46px" },
                            "& fieldset": {
                              border: "1px solid #E0E0E5",
                              boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                            },
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={0.5} fullWidth>
                        <InputLabel
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#0A0A0A",
                          }}
                        >
                          domain
                        </InputLabel>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "35%",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                height: "46px",
                                alignContent: "center",
                                px: 2,
                                border: `1px solid ${formik.touched.domain && formik.errors.domain ? "red" : "#E0E0E5"}`,
                                borderRight: "none",
                                borderRadius: 1,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                            >
                              @
                            </Typography>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              fullWidth
                              value={isEdit ? "all" : formik.values.domain}
                              disabled={isEdit}
                              error={formik.touched.domain && Boolean(formik.errors.domain)}
                              onChange={e => formik.setFieldValue("domain", e.target.value)}
                              sx={{
                                height: "46px",
                                "& fieldset": {
                                  borderColor: "#E0E0E5",
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  boxShadow: "none",
                                },
                              }}
                            //label="Age"
                            //onChange={handleChange}
                            >
                              <MenuItem key={"all"} value={"all"}>
                                All Domains
                              </MenuItem>
                              {selectedDomains?.map((domain, index) => (
                                <MenuItem key={index} value={domain}>
                                  {domain}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Button variant="contained" type="submit" disabled={isEdit} fullWidth onClick={formik.handleSubmit}>
                    Add
                  </Button>
                </Stack>
              </Stack>
          
              {selectedDomainInfo.filter((domain) => domain?.contactDetails?.length > 0).length > 0 ? (<> 
             
               <TableContainer component={Paper} sx={{p:2}}>
               <Typography sx={{ fontSize: "14px", fontWeight: 700 ,py:2 }}>
              Total Mailbox: {selectedDomainInfo.filter((domain) => domain?.contactDetails?.length > 0).reduce((acc, curr) => acc + curr.contactDetails.length, 0)} 
              </Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead
                    sx={{
                      "& .MuiTableCell-root": { backgroundColor: "#E9E9EC" },
                    }}
                  >
                    <TableRow>
                      <TableCell>Provider</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email Name</TableCell>
                      <TableCell>@</TableCell>
                      <TableCell>Domain</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedDomainInfo.filter(domain => domain?.contactDetails?.length > 0).map((domain) => {
                      return domain.contactDetails.map((contact, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "& .MuiTableCell-root .MuiTypography-root": {
                              fontSize: "14px",
                              fontWeight: 500,
                            },
                          }}
                        >
                          <TableCell scope="row">
                            <Typography
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                                borderRadius: 1,
                                p: 1,
                                border: "1px solid #E0E0E5",
                                width: "fit-content",
                              }}
                            >
                              {setupType == "google" ? <GmailIcon size={"20px"} /> : <MicrosoftIcon size={"20px"} />}
                              <Typography>Google</Typography>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {
                              editIndex === index && domain.domain == editDomain ? <Stack direction={"row"} spacing={0.5}>

                                <TextField
                                  variant="outlined"
                                  value={formik.values.firstName.trim()}
                                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                  name="firstName"
                                  placeholder="first name"
                                  onChange={e => formik.setFieldValue("firstName", e.target.value.trim().replaceAll(' ', ''))}
                                  sx={{
                                    "& .MuiOutlinedInput-root": { height: "46px" },
                                    "& fieldset": {
                                      border: "1px solid #E0E0E5",
                                      boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                                    },
                                  }}
                                />
                                <TextField
                                  variant="outlined"
                                  value={formik.values.lastName.trim()}
                                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                  placeholder="last name"
                                  onChange={e => formik.setFieldValue("lastName", e.target.value.trim().replaceAll(' ', ''))}
                                  sx={{
                                    "& .MuiOutlinedInput-root": { height: "46px" },
                                    "& fieldset": {
                                      border: "1px solid #E0E0E5",
                                      boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                                    },
                                  }}
                                />
                              </Stack> : (<Typography
                                sx={{
                                  borderRadius: 1,
                                  p: 1,
                                  border: "1px solid #E0E0E5",
                                  width: "fit-content",
                                }}
                              >
                                {`${contact?.firstName} ${contact?.lastName}`}
                              </Typography>)
                            }

                          </TableCell>
                          <TableCell>
                            {
                              editIndex === index && domain.domain == editDomain ? <TextField
                                variant="outlined"
                                value={formik.values.email.includes("@") ? formik.values.email.split("@")[0] : formik.values.email}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                endAdornment={<Typography>@{formik.values.domain}</Typography>}
                                onChange={e => e.target.value ? formik.setFieldValue("email", `${e.target.value.trim()}@${formik.values.domain}`) : formik.setFieldValue("email", `${e.target.value.trim()}@${formik.values.domain}`)}
                                sx={{
                                  "& .MuiOutlinedInput-root": { height: "46px" },
                                  "& fieldset": {
                                    border: "1px solid #E0E0E5",
                                    boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                                  },
                                }}
                              /> : (<Typography
                                sx={{
                                  borderRadius: 1,
                                  p: 1,
                                  border: "1px solid #E0E0E5",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 1,
                                  width: "fit-content",
                                }}
                              >
                                {contact.email.includes("@") ? contact.email.split("@")[0] : contact.email}
                              </Typography>)

                            }
                          </TableCell>
                          <TableCell>@</TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                borderRadius: 1,
                                p: 1,
                                border: "1px solid #E0E0E5",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                                width: "fit-content",
                              }}
                            >
                              {domain.domain}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography>$3.50</Typography>
                          </TableCell>
                          <TableCell align="right">

                            {isEdit && index == editIndex && domain.domain == editDomain ? (
                              <Stack spacing={1} direction="row" sx={{ justifyContent: "end" }}>
                                <Button variant="contained" onClick={formik.handleSubmit}>Save</Button>
                                <Button variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
                              </Stack>
                            ) : (<IconButton onClick={() => handleEditContactDetail(domain, index)}>
                              <EditOutlined sx={{ color: "#5862FF" }} />
                            </IconButton>)}
                          </TableCell>
                        </TableRow>
                      ))
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
                <Stack
                  spacing={2}
                  sx={{
                    p: 2,
                    border: "1px solid #E0E0E5",
                    borderRadius: 1,
                    boxShadow: `0 1px 2px 0 ${alpha("#101828", 0.05)}`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1,
                      }}
                      >
                     <Tooltip
                      title="Mailboxes will be billed on a monthly basis."
                      arrow
                      >
                        <InfoOutlined sx={{ fontSize: "14px", color: "#5B5B5B" }} />
                      </Tooltip>
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: 500, color: "#5B5B5B" }}
                    >
                      Mailbox (Monthly Price):
                    </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      ${total.toFixed(2)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                     <Tooltip 
                      title="Doamin charges billed anually."
                      arrow
                      >
                        <InfoOutlined sx={{ fontSize: "14px", color: "#5B5B5B" }} />
                      </Tooltip>
                      <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: 500, color: "#5B5B5B" }}
                    >
                    Domain Annual Price:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      ${domainTotal.toFixed(2)}
                    </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                      gap: 2,
                    }}
                  >
                       <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Tooltip 
                      title="This is a one-time setup charge for each domain."
                      arrow
                      >
                        <InfoOutlined sx={{ fontSize: "14px", color: "#5B5B5B" }} />
                      </Tooltip>
                   <Typography
                      sx={{ fontSize: "14px", fontWeight: 500, color: "#5B5B5B", alignItems: "center" }}
                    >
                      Domain Setup Charge ($1.50 per Domain): 
                      </Typography>
                   
                    </Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      ${domainSetupCharge.toFixed(2)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                        <Tooltip
                      title="This is a one-time setup charge for each mailbox."
                      arrow
                      >
                        <InfoOutlined sx={{ fontSize: "14px", color: "#5B5B5B" }} />
                      </Tooltip>
                   <Typography
                      sx={{ fontSize: "14px", fontWeight: 500, color: "#5B5B5B" }}
                    >
                      Mailbox Setup Charge ($1.00 per Mailbox):
                      </Typography>
                    
                   </Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      ${mailBoxSetupCharge.toFixed(2)}
                    </Typography>
                  </Box>
                  <Divider sx={{ borderColor: alpha("#101828", 0.07) }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: 500, color: "#5B5B5B" }}
                    >
                      Total Price:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      ${finalTotal.toFixed(2)}
                    </Typography>
                  </Box>
                </Stack> </>) : null}
              {selectedDomainInfo.filter((domain) => domain.contactDetails.length > 0).length > 0 ? <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
                sx={{pb:5}}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  <InfoOutlined sx={{ color: "#5862FF", fontSize: "16px" }} />{" "}
                  Please allow 24 to 72 hours for your emails to be set up as DNS
                  propagation can take some time.
                </Typography>
                <Button variant="contained" onClick={handleClickOpen}>
                  Place Order
                </Button>
              </Stack> : null}
            </Stack>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  borderBottom: "1px solid #E0E0E5",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                  {" "}
                  Checking payment Status
                </Typography>
                <IconButton
                  onClick={handleClickOpen1}
                  sx={{
                    borderRadius: "12px",
                    p: "10px",
                    color: "#111927",
                    border: "1px solid #E0E0E5",
                  }}
                >
                  <CloseOutlined sx={{ fontSize: "18px" }} />{" "}
                </IconButton>
              </DialogTitle>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 3,
                  m: 4,
                }}
              >
                <Stack
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CircularProgress />
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#5B5B5B" }}
                  >
                    {" "}
                    Waiting to get payment confirmation...
                  </Typography>
                </Stack>
                <Typography
                  textAlign={"center"}
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Please wait while we process your payment. If the payment page
                  did not open automatically, <Link>click here</Link> to open it
                  in a new tab.
                </Typography>

                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    p: 1,
                    borderRadius: "12px",
                    border: "1px solid red",
                    color: "#111927",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  <InfoOutlined sx={{ fontSize: "16px", color: "red" }} />
                  Do not close this window.
                </Typography>
              </DialogContent>
            </Dialog>
          
            <Dialog open={isPlaceOrderCofirmatiomModalOpen} onClose={handlePlaceOrderModalClose}>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  borderBottom: "1px solid #E0E0E5",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                  {" "}
                Select Payment Method
                </Typography>
                <IconButton
                  onClick={handlePlaceOrderModalClose}
                  sx={{
                    borderRadius: "12px",
                    p: "10px",
                    color: "#111927",
                    border: "1px solid #E0E0E5",
                  }}
                >
                  <CloseOutlined sx={{ fontSize: "18px" }} />{" "}
                </IconButton>
              </DialogTitle>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  m: 3,
                }}
              >
                <Stack
                  spacing={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >

                 {
                  !isFetchingPaymentMethods && paymentCards.length > 0 ? paymentCards.map((card, index) => {
                    return(
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 20,
                        p: 2,
                        borderRadius: 2,
                        border: `1px solid ${alpha("#0000ff", 0.2)}`,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                   <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#fff",
                            backgroundColor: "#00579f",
                            p: "4px",
                            borderRadius: "4px"
                          }}
                        >
                          {card.brand}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#5B5B5B",
                          }}
                        >
                         ....{card.last4}
                        </Typography>
                       
                      </Box>
                      <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                        
                      }}>
                      <Typography 
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#5B5B5B",
                          backgroundColor: "#E0E0E5",
                          p: "4px",
                          borderRadius: "4px"
                        }}
                        >
                          Expires {card.exp_month}/{card.exp_year}
                        </Typography>
                        <IconButton
                          onClick={() => handleSelectPaymentMethod(card.id)}
                        >
                          {selectedCard === card?.id ? (
                            <CheckOutlined />
                          ) : (
                            <RadioButtonUncheckedOutlined />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                  )}):isFetchingPaymentMethods?
                  <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    p: 2,
                    borderRadius: 2,
                    width: 400
                    
                  }}>
                    <CircularProgress height={15} width={15} />
                  </Box>
                  :
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#5B5B5B",
                      width: "100%",
                      px:16,
                      py:10
                    }}
                  >
                    No payment methods found
                  </Typography>
                 }
               
               </Stack>
              </DialogContent>
              <DialogActions
                sx={{
                  justifyContent: "space-between",
                  gap: 1,
                  borderTop: "1px solid #E0E0E5",
                  p: 2,
                }}
              >
                <Button
                  variant="outlined"
                  disabled={paymentCards.length === 0}
                  onClick={() => {
                    handlePlaceOrder();
                  }}
                  autoFocus
                  sx={{ width: "50%" }}
                >
                  Confirm Order
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    handleNewPaymentMethod()
                  }}
                  autoFocus
                  sx={{ width: "50%" }}
                >
                  Add New Card
                </Button>

              </DialogActions>
            </Dialog>
            <Dialog open={open2} onClose={handleClose2}>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  borderBottom: "1px solid #E0E0E5",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                  {" "}
                  Payment Completed
                </Typography>
                <IconButton
                  onClick={handleClose2}
                  sx={{
                    borderRadius: "12px",
                    p: "10px",
                    color: "#111927",
                    border: "1px solid #E0E0E5",
                  }}
                >
                  <CloseOutlined sx={{ fontSize: "18px" }} />{" "}
                </IconButton>
              </DialogTitle>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  m: 3,
                }}
              >
                <Stack
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    sx={{
                      borderRadius: 2,
                      border: `1px solid ${alpha("#12B981", 0.2)}`,
                      p: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "12px",
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#12B981",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckOutlined sx={{ color: "#ffffff" }} />
                    </Box>
                  </Box>
                  <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                    {" "}
                    Completed
                  </Typography>
                </Stack>
                <Typography
                  textAlign={"center"}
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Success, your payment has been successfully completed.
                </Typography>
              </DialogContent>
              <DialogActions
                sx={{
                  justifyContent: "space-between",
                  gap: 1,
                  borderTop: "1px solid #E0E0E5",
                  p: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleClose2}
                  sx={{ width: "50%" }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleClose2();
                    navigate(paths.emailAccounts.manage);
                  }}
                  autoFocus
                  sx={{ width: "50%" }}
                >
                  Back To Email Sending
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog 
              open={isConfirmationDialogOpen}
              onClose={() => setIsConfirmationDialogOpen(false)}
            >
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  m: 3,
                }}
              >
                <Typography
                  textAlign="center"
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Are you sure you want to place this order?
                </Typography>
              </DialogContent>

              <DialogActions
                sx={{
                  justifyContent: "space-between",
                  gap: 1,
                  borderTop: "1px solid #E0E0E5",
                  p: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => setIsConfirmationDialogOpen(false)}
                  sx={{ width: "50%" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handlePlaceOrder}
                  sx={{ width: "50%" }}
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

          </div>
        ) : step === 3 ? (
          <>
            <OrderStatus setStep={setStep} />

          </>
        ) : (
          <></>
        )}
      </Stack>
    </>);
};

export default AddNew;
