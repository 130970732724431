import React from 'react';
import { Box, Popover, TextField, InputAdornment, IconButton, Button, Typography } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import { SBSearch } from 'src/assets/sidebar/SBSearch';

const CustomPopover = ({ open, anchorEl, onClose, searchPlaceholder, handleSearchChange, showCreateButton, onCreateButtonClick, createButtonText, items, handleInterestChange, renderLabelWithIcon }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, height: '400px', overflowY: 'auto' }}>
        <Box sx={{ width: '100%', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, pt: 2 }}>
          <TextField
            placeholder={searchPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton sx={{ width: 32, height: 32 }}>
                    <SBSearch color="rgba(40, 40, 123, 0.5)" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            sx={{
              width: '100%',
              height: 40,
              backgroundColor: 'white',
              '& div': { pl: 0.3 },
              '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
              '& div input': {
                py: 1.3,
                fontSize: '11px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0em',
                '&::placeholder': {
                  color: 'rgba(40, 40, 123, 0.5)',
                },
              },
            }}
            onChange={handleSearchChange}
          />
          {showCreateButton && (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'left',
                px: 1.5,
                width: '100%',
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '14px',
                color: '#28287B',
                '&:hover': {
                  backgroundColor: '#F2F4F6',
                  color: '#3F4FF8',
                },
                border: '1px solid #fff',
              }}
              onClick={onCreateButtonClick}
            >
              <Typography
                sx={{
                  width: 'calc(100% - 20px)',
                  textAlign: 'left',
                  ml: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#28287B',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                {createButtonText}
              </Typography>
              <AddOutlined />
            </Button>
          )}
        </Box>
        <Box
          sx={{
            flex: '1 1 auto',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '14px' },
            '&::-webkit-scrollbar-track': { borderRadius: '60px' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#E4E4E5',
              borderRadius: '10px',
              border: '4px solid rgba(0, 0, 0, 0)',
              backgroundClip: 'padding-box',
            },
            '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#d5d5d5' },
          }}
        >
          {items?.labels?.map((i) => (
            <Button
              key={i._id}
              onClick={() => handleInterestChange(i._id)}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 1.5,
                py: 1,
                mb: 1,
                width: '100%',
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '14px',
                color: '#28287B',
                '&:hover': {
                  color: '#3F4FF8',
                },
              }}
            >
              <Typography
                sx={{
                  width: 'calc(100% - 20px)',
                  textAlign: 'left',
                  ml: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px'
                }}
              >
                {renderLabelWithIcon(i)}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
    </Popover>
  );
};

export default CustomPopover;
