// VerificationLink.js
import { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useResendVerifyLinkMutation } from 'src/services/auth-service';
import toast from 'react-hot-toast';
import { useGetMeQuery } from 'src/services/user-service.js';

const useSharedVerificationLinkState = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const checkStatus = () => {
      const lastClicked = localStorage.getItem('resendVerificationLinkLastClicked');
      if (lastClicked) {
        const timeElapsed = Date.now() - parseInt(lastClicked, 10);
        if (timeElapsed < 3600000) {
          setIsDisabled(true);
          const timeoutId = setTimeout(() => {
            setIsDisabled(false);
            localStorage.removeItem('resendVerificationLinkLastClicked');
            window.dispatchEvent(new Event('resendVerificationLinkUpdated'));
          }, 3600000 - timeElapsed);
          return () => clearTimeout(timeoutId);
        }
      }
    };

    checkStatus();
    window.addEventListener('resendVerificationLinkUpdated', checkStatus);

    return () => {
      window.removeEventListener('resendVerificationLinkUpdated', checkStatus);
    };
  }, []);

  const disableLink = () => {
    const now = Date.now();
    localStorage.setItem('resendVerificationLinkLastClicked', now);
    setIsDisabled(true);
    window.dispatchEvent(new Event('resendVerificationLinkUpdated'));
    setTimeout(() => {
      setIsDisabled(false);
      localStorage.removeItem('resendVerificationLinkLastClicked');
      window.dispatchEvent(new Event('resendVerificationLinkUpdated'));
    }, 3600000);
  };

  return { isDisabled, disableLink };
};

const VerificationLink = ({ handleDrawerToggle, isMobile, props }) => {
  const { isDisabled, disableLink } = useSharedVerificationLinkState();
  const [searchParams] = useSearchParams();
  const [resendVerifyLink, { isLoading: isResendLinkVerifyLinkLoading }] = useResendVerifyLinkMutation();
  const secondary = true;
  const { data: user, isLoading } = useGetMeQuery();

  const sendVerificationLink = async () => {
    let email;
    if (!secondary) {
      const token = searchParams.get('token');
      email = window.atob(token);
    } else {
      email = user.email;
    }
    const { data } = await resendVerifyLink({ email });
    toast.success(data.message);

    disableLink();
  };

  return (
    <>
      <Tooltip
        title={isDisabled ? "Resend Verification link has already been sent. Please wait 60 minutes before requesting another." : ""}
        arrow
      >
        <span
          onClick={!isDisabled ? sendVerificationLink : null}
          style={{
            fontWeight: 700,
            fontSize: "13px",
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            color: '#216fed',
            paddingLeft: '4px',
            whiteSpace: 'nowrap',
          }}
        >
          Resend verification link
        </span>
      </Tooltip>
    </>
  );
};

export default VerificationLink;
