import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, TableCell, TableRow } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const FileUploadCsv = ({ column, sample, selections, onChange, isUploading, filter, isSelected,alreadySelectionValues, setSelectionValue,samples, onInitialSelection }) => {
  const initialSelected = selections.find((s) => s.label === column) || selections[selections.length - 1];

  const [selected, setSelected] = useState(initialSelected);
  const [key, setKey] = useState('');

  useEffect(() => {
    onInitialSelection(initialSelected);
  }, []);

  const handleChange = (event) => {
    const selection = selections.find((s) => s.value === event.target.value);
    setSelected(selection);
    onChange(selection);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 240,
      },
    },
  };

  useEffect(() => {
    if(!isSelected && filter){
      setSelected(selections[selections.length - 1]);
    }
  }, [isSelected])

  const sampleHasLink = (sampleData) => {
    const urlRegex = /\b(?:https?:\/\/)?(?:www\.)?(?!.*@)[\w\d-]+(?:\.[\w\d.-]+)+\S*/;
    return urlRegex.test(sampleData);
  };

  const keys = Object.keys(samples);

  const selectKey = (event) => {
    const title = event.target.value;
    setKey(title);
    const newSelected = selected;
    newSelected.textTitle = title
    onChange(newSelected); 
  }

  return (
    <TableRow>
      <TableCell>{column}</TableCell>
      <TableCell width={240}>
        <FormControl fullWidth size="small" sx={{ maxHeight: "300px" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected.value}
            onChange={handleChange}
            variant="outlined"
            MenuProps={MenuProps}
            disabled={isUploading}
          >
            {selections.map((selection, index) => (
              <MenuItem
                value={selection.value}
                key={index}
                disabled={
                  alreadySelectionValues.some(
                    (selected) => selected && selected.value === selection.value && selected.value !== "DO_NOT_IMPORT"
                  ) && (selection.value !== "Custom_Variable") && (selection.value !== "link")
                }
              >
                {selection.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
          { selected.value === "link" && sampleHasLink(sample) &&(
          <FormControl fullWidth size="small" sx={{ maxHeight: "300px", marginTop: 1 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={key}
              onChange={selectKey}
              variant="outlined"
              MenuProps={MenuProps}
              displayEmpty
            >
              <MenuItem disabled value="">
              Select link text
            </MenuItem>
              {keys.map((key) => (
                <MenuItem value={key} >
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        </TableCell>
      <TableCell>{sample}</TableCell>
    </TableRow>
  );
};

export default FileUploadCsv;
