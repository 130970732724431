// MemberList.js
import React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';

const extractNameFromEmail = (email) => {
  const name = email.split('@')[0];
  return name.charAt(0).toUpperCase() + name.slice(1);
};

const MemberList = ({ members, handleViewAccessChange, setOpenRemoveMemberDialog, setAnchorEl, canEdit, canDelete }) => {
  return (
    <>
      {members.map((member) => (
        <Paper key={member._id} variant="outlined" sx={{ p: 2, mb: 2 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body1">{extractNameFromEmail(member.email)}</Typography>
              <Typography variant="body2">{member.email}</Typography>
            </Box>
            <Stack direction="row" spacing={1} sx={{ mt: { xs: 2, sm: 0 } }}>
              <FormControl fullWidth variant="outlined" sx={{ backgroundColor: "white", borderRadius: "8px" }}>
                <InputLabel>Access</InputLabel>
                <Select
                  value={member.role}
                  onChange={(e) => canEdit && canDelete && handleViewAccessChange(e, member._id)}
                  label="Access"
                  renderValue={(selected) => selected}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        borderColor: "#E4E4E5",
                      },
                      "&:hover fieldset": {
                        borderColor: "#E4E4E5",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#E4E4E5",
                      },
                    },
                    "& .MuiInputBase-input": {
                      py: 0.5, // Adjust padding for height
                      fontSize: "14px",
                      "&::placeholder": {
                        color: "#A1A1A1",
                      },
                    },
                    "& .MuiSelect-select": {
                      px: 1, // Adjust padding for width
                    },
                  }}
                >
                  <MenuItem value="Admin">
                    <Box>
                      <Typography sx={{ fontWeight: 700 }}>Admin</Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Manage team, billing, and settings
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem value="Editor">
                    <Box>
                      <Typography sx={{ fontWeight: 700 }}>Editor</Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Edit everything except team and billing
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem value="Viewer">
                    <Box>
                      <Typography sx={{ fontWeight: 700 }}>View / VA Access</Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Can view everything but not make updates
                      </Typography>
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  setOpenRemoveMemberDialog(member._id); // Pass the member ID
                  setAnchorEl(null);
                }}
                sx={{
                  minWidth: 32,
                  minHeight: 32,
                  width: 50,
                  height: 32,
                  padding: 0,
                  borderRadius: "50%",
                }}
                disabled={!canEdit || !canDelete}
              >
                <Delete fontSize="small" />
              </Button>
            </Stack>
          </Stack>
        </Paper>
      ))}
    </>
  );
};

export default MemberList;
