import { useEffect } from 'react';
import { useGetCurrentPlanQuery } from "src/services/billing-service.js";
import { useGetMeQuery } from "src/services/user-service";
import { useSelector } from "react-redux";

const UserVerification = () => {
  const { data: user, refetch: refetchUser } = useGetMeQuery();
  const { data: currentPlan } = useGetCurrentPlanQuery();

  const freeAccountsLimit = useSelector((state) => state.accounts);

  // Helper functions to determine user type
  const isAppSumoUser = () => {
    return user && user.assignedPlan && user.assignedPlan.length > 0 && !user.isAppSumoRefund;
  };

  const isEarlyBirdAccessUser = () => {
    return user && user.isEarlyBirdAccessUser && !user.isEarlyBirdDealRefund;
  };

  const isPaidUser = () => {
    return user && currentPlan && (currentPlan.subscription?.sendingWarmup?.expiresAt || currentPlan.subscription?.leads?.expiresAt);
  };

  const checkIfDisableButton = () => {
    if (isAppSumoUser() || isEarlyBirdAccessUser() || isPaidUser()) {
      return false; // Button should not be disabled
    }
    // User is a free trial user
    if (freeAccountsLimit.length >= 2) {
      return true; // Disable button
    }
    return false; // Less than 2 accounts, do not disable button
  };

  useEffect(() => {
    refetchUser();
  }, [currentPlan, freeAccountsLimit]);

  const userDetails = {
    isAppSumoUser: isAppSumoUser(),
    isEarlyBirdAccessUser: isEarlyBirdAccessUser(),
    isPaidUser: isPaidUser(),
    isFreeUser: !isAppSumoUser() && !isEarlyBirdAccessUser() && !isPaidUser(),
    shouldDisableButton: checkIfDisableButton(),
    accountsLimit: freeAccountsLimit.length
  };

  return userDetails;
};

export default UserVerification;
