import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Tab, Tabs, Link, DialogTitle, DialogContent, DialogActions, Dialog, Divider } from "@mui/material";
import { SalesForceLogo } from "src/assets/integrations/SalesForceLogo";
import { ZapierLogo } from "src/assets/integrations/ZapierLogo";
import { ZohoLogo } from "src/assets/integrations/ZohoLogo";
import WebhookTable from './IntegrationWebhookTable';
import { ApiLogo } from "src/assets/integrations/apiLogo";
import { useUpdateApiKeyMutation, useGetApiKeyMutation } from "src/services/integration-service"
import { toast } from "react-hot-toast";
import { config } from "src/config.js";
import HeaderWithPopover from "src/components/HeaderWithPopover";
import { useSelector } from "react-redux";
import PremiumFeatureDialog from "src/utils/premiumFeatureDialog";
import UserVerification from "src/components/emailAccounts/UserVerification";
import { useAuth } from "src/auth/auth";
import { useGetMeAndWorkspaceQuery } from "src/services/user-service";

const integrationData = [
  { logo: <SalesForceLogo />, title: "Salesforce" },
  { logo: <ZapierLogo />, title: "Zapier" },
  { logo: <ZohoLogo />, title: "Zoho" },
  { logo: <ApiLogo />, title: "Success.ai API" }
];

const ButtonCommingSoon = () => {
  return (
    <Button
      fullWidth
      variant="contained"
      sx={{
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        py: 1.5,
      }}
    >
      Coming Soon
    </Button>
  );
}

const Api = ({ updateApiKey, getApiKey, isLoadingUpdateApiKey,isFreeUser, setOpenModal, canEdit, canDelete }) => {
  const [open, setOpen] = useState(false);
  const [clipboard, setClipboard] = useState('');
  const workspace = useSelector((state) => state.workspace);

  const handleClickOpen = () => {
    if(isFreeUser){
      setOpenModal(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGenerateKey = async () => {
    try {
      const toastId = toast.loading("Generating new API key", { duration: Infinity });
      const { message } = await updateApiKey({workspace: workspace}).unwrap();
      toast.success(message, { id: toastId, duration: 2000 });
      setOpen(false);
    } catch (error) {
      toast.error("Failed to generate API key. Please try again later.");
    }
  };
  
  const getApiKeyForCopy = async () => {
     const {apiKey} = await getApiKey({workspace: workspace?._id}).unwrap();
     setClipboard(apiKey)
    };

  const copyToClipBoard = async () => {
    navigator.clipboard
    .writeText(clipboard)
    .then(() => {
      toast.success("Copied to Clipboard");
    })
    .catch((err) => {
      toast.error("Unable to copy to clipboard");
    });
  }

    useEffect(() => {
      getApiKeyForCopy();
    }, [isLoadingUpdateApiKey]);
  
  return (
    <>
      <Box sx={{width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item>
            <Button variant="outlined" disabled={!canEdit || !canDelete} onClick={handleClickOpen}>Generate new API Key</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={!clipboard || isFreeUser || !canEdit} onClick={copyToClipBoard}>Copy API Key</Button>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ display: 'block', marginTop: 2 }}>
          <Link href={`${config.API_BASE_URL}/docs`} color="primary" underline="none" target="_blank">
            See documentation
          </Link>
        </Typography>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "22px", fontWeight: "700" }}>Are you sure?</DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ letterSpacing: '1px', fontWeight: 400, fontSize: "15px" }}>
            This will generate a new API key and invalidate the old one.
          </Typography>
        </DialogContent>

        <DialogActions sx={{ margin: 2 }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button 
            disabled= {isLoadingUpdateApiKey} 
            onClick={handleGenerateKey} 
            color="primary" 
            variant="contained"
            >
            Generate new API Key
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const IntegrationCard = ({ logo, title, children }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      boxShadow: "0px 12px 15px 0px #4B71970D",
      width: "100%",
      p: 3,
      flexDirection: "column",
      height: "100%",
      backgroundColor: "#fff",
      borderRadius: "12px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #E4E4E5",
        py: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "120px",
          height: "83px",
        }}
      >
        {logo}
      </Box>
    </Box>
    <Typography
      sx={{
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "28px",
        color: "#28287B",
        mt: 2,
        mb: 1,
      }}
    >
      {title}
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        mt: 3,
      }}
    >
      {children}
    </Box>
  </Box>
);

const Page = () => {
  const [tabValue, setTabValue] = useState(0);
  const [updateApiKey, { isLoading: isLoadingUpdateApiKey }] = useUpdateApiKeyMutation();
  const [getApiKey] = useGetApiKeyMutation();
  const [openModal, setOpenModal] = useState(false);
  const { isFreeUser } = UserVerification();
  const { canEdit, canDelete } = useAuth();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [workspace, setWorkspace] = useState(null);

  const handleWorkspaceChange = (newWorkspace) => {
    setWorkspace(newWorkspace);
  };

  const {data: data} = useGetMeAndWorkspaceQuery();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <HeaderWithPopover title="Integrations" onWorkspaceChange={handleWorkspaceChange} selectedTimeline={data?.workspace}/>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "#8181B0",
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "0px",
                width: "100%",
                mt: 1.5,
              }}
            >
              Experience seamless integration and elevate your efficiency with Success.ai. Choose
              the method that suits you best and supercharge your productivity today!
            </Typography>
          </Box>
          <Box sx={{ mt: 3, width: '100%' }}>
            <Tabs value={tabValue} onChange={handleTabChange} sx={{ borderBottom: '1px solid #E4E4E5' }}>
              <Tab label="API" />
              <Tab label="Webhook" />
            </Tabs>
            <Box sx={{ mt: 3 }}>
              {tabValue === 0 && (
                <Grid container columnSpacing={3} rowGap={2} >
                  {integrationData
                  .map((integration, index) => (
                    <>
                      {integration.title == 'Success.ai API'
                        ? (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <IntegrationCard logo={integration.logo} title={integration.title}>
                              <Api  
                              updateApiKey={updateApiKey} 
                              getApiKey={getApiKey}
                              isLoadingUpdateApiKey={isLoadingUpdateApiKey}
                              isFreeUser={isFreeUser}
                              setOpenModal={setOpenModal} 
                              canEdit={canEdit}
                              canDelete={canDelete}
                              />
                            </IntegrationCard>
                          </Grid>
                        )
                        : (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <IntegrationCard logo={integration.logo} title={integration.title} >
                              <ButtonCommingSoon />
                            </IntegrationCard >
                          </Grid>
                        )}
                    </>
                  ))}
                </Grid>
              )}
              {tabValue === 1 && (
                <WebhookTable canEdit={canEdit} canDelete={canDelete} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <PremiumFeatureDialog
        open={openModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default Page;
