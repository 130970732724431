import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress
} from '@mui/material';
import { ExpandMoreOutlined, Clear, AddOutlined } from '@mui/icons-material';
import { SBSearch } from 'src/assets/sidebar/SBSearch';
import Countbadge from 'src/components/Countbadge';

const CustomAccordion = ({
  title,
  searchPlaceholder,
  searchItem,
  setSearchItem,
  handleSearchChange,
  items = [],
  handleClickItem,
  currentItem,
  setInboxTabsOpen,
  setShowAll,
  showCreateButton = false,
  createButtonText,
  onCreateButtonClick,
  totalItems,
  isLoading,
  loadMoreItems,
  threshold = 10,
}) => {
  const allItemsLoaded = items.length >= totalItems;
  return (
    <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          height: '400px',
          overflowY: 'auto',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '100%',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            pt: 2,
          }}
        >
          <TextField
            placeholder={searchPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton sx={{ width: 32, height: 32 }}>
                    {searchItem ? (
                      <Clear onClick={() => setSearchItem('')} />
                    ) : (
                      <SBSearch color="rgba(40, 40, 123, 0.5)" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={searchItem}
            variant="outlined"
            sx={{
              width: '100%',
              height: 40,
              backgroundColor: 'white',
              '& div': { pl: 0.3 },
              '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
              '& div input': {
                py: 1.3,
                fontSize: '11px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0em',
                '&::placeholder': {
                  color: 'rgba(40, 40, 123, 0.5)',
                },
              },
              mb: 1,
            }}
            onChange={handleSearchChange}
          />
          {showCreateButton && (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'left',
                px: 1.5,
                py: 1,
                mb: 1,
                width: '100%',
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                color: '#28287B',
                '&:hover': {
                  backgroundColor: '#F2F4F6',
                  color: '#3F4FF8',
                },
                border: '1px solid #fff',
              }}
              onClick={onCreateButtonClick}
            >
              <Typography
                sx={{
                  width: 'calc(100% - 20px)',
                  textAlign: 'left',
                  ml: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#28287B',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                {createButtonText}
              </Typography>
              <AddOutlined />
            </Button>
          )}
        </Box>
        <Box
          sx={{
            flex: '1 1 auto',
            mt: 2,
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '14px' },
            '&::-webkit-scrollbar-track': { borderRadius: '60px' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#E4E4E5',
              borderRadius: '10px',
              border: '4px solid rgba(0, 0, 0, 0)',
              backgroundClip: 'padding-box',
            },
            '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#d5d5d5' },
          }}
        >
          {items?.map((i) => (
            <Button
              key={i._id}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 1.5,
                py: 1,
                mb: 1,
                width: '100%',
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                color: currentItem === i._id ? '#3F4FF8' : '#28287B',
                '&:hover': {
                  backgroundColor: currentItem === i._id ? '#fff' : '#F2F4F6',
                  color: '#3F4FF8',
                },
                border: currentItem === i._id ? '1px solid #3F4FF8' : '1px solid #fff',
              }}
              onClick={() => {
                handleClickItem(i._id);
                setInboxTabsOpen(false);
                setShowAll(false);
              }}
            >
              <Typography
                sx={{
                  width: 'calc(100% - 20px)',
                  textAlign: 'left',
                  ml: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '12px'
                }}
              >
                {title === 'Status' ? i.name : title === 'Campaigns' ? i.name : i.email}
              </Typography>
              {i?.actualUnreadCount > 0 && <Countbadge count={i.actualUnreadCount} />}
            </Button>
          ))}
        </Box>
        {totalItems >= threshold && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              pt: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                cursor: "pointer",
              }}
              onClick={loadMoreItems}
              disabled={allItemsLoaded}
            >
              {isLoading ? <CircularProgress sx={{color: 'white'}} size={25} thickness={5} /> : <> Load more </>}
            </Button>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
