import { Box } from "@mui/material";
import { LFJobTitles } from "./leadFinder/LFFilter/LFJobTitles";
import { LFLocation } from "./leadFinder/LFFilter/LFLocation";
import { LFIndustry } from "./leadFinder/LFFilter/LFIndustry";
import { LFEmployees } from "./leadFinder/LFFilter/LFEmployees";
import { LFTechnologies } from "./leadFinder/LFFilter/LFTechnologies";
import { LFDepartment } from "./leadFinder/LFFilter/LFDepartment";
import { LFManagementLevels } from "./leadFinder/LFFilter/LFManagementLevels";
import { LFName } from "./leadFinder/LFFilter/LFName";
import { LFCompanyName } from "./leadFinder/LFFilter/LFCompanyName";
import { Abc } from "@mui/icons-material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
export const campaignData = [
  {
    id: 1,
    title: "Your Campaign Title",
    created: "8 days",
    status: "draft",
    sent: 0,
    opened: 0,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [10, 0, 0, 0, 20, 0, 0],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [3, 0, 0, 0, 15, 0, 0],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [1, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  {
    id: 2,
    title: "Jon_Withlinks_11_05",
    created: "2 months",
    status: "error",
    sent: 0,
    opened: 0,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  {
    id: 3,
    title: "26/04 Gmail Campaign10",
    created: "2 months",
    status: "active",
    sent: 220,
    opened: 25,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [0, 0, 20, 0, 0, 30, 0],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [0, 0, 5, 0, 0, 15, 0],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [0, 0, 2, 0, 0, 0, 0],
      },
    ],
  },
  {
    id: 4,
    title: "26/04 Gmail Campaign09",
    created: "2 months",
    status: "paused",
    sent: 220,
    opened: 12,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 10],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 2],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  {
    id: 5,
    title: "26/04 Gmail Campaign06",
    created: "2 months",
    status: "completed",
    sent: 172,
    opened: 57,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [0, 0, 150, 0, 0, 122, 0],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [0, 0, 75, 0, 0, 100, 0],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [0, 0, 22, 0, 0, 15, 0],
      },
    ],
  },
];

const TimeData = [
  {
    label: 'Last 3 months',
    value: '0-90',
  },
  {
    label: 'Last 6 months',
    value: '0-180',
  },
  {
    label: 'Last year',
    value: '0-360',
  },
  {
    label: 'Last 2 years',
    value: '0-720',
  },
  {
    label: 'Last 3 years',
    value: '0-1080',
  },
];

export const emailData = [
  {
    id: 1,
    email: "annie@b2brocket.ai",
    emailsSentToday: 0,
    emailsSentPastWeek: 140,
    emailsLanded: 140,
    emailsSaved: 0,
    health: 100,
    warmup: "pause",
    series: [
      {
        name: "Warmup emails landed in spam",
        data: [1, 0, 0, 0, 0, 0],
      },
      {
        name: "Warmup emails sent",
        data: [8, 9, 4, 6, 5, 6],
      },
    ],
  },
  {
    id: 2,
    email: "danni@nftbrandsllc.com",
    emailsSentToday: 0,
    emailsSentPastWeek: 0,
    emailsLanded: 0,
    emailsSaved: 0,
    health: 0,
    warmup: "disabled",
    series: [
      {
        name: "Warmup emails landed in spam",
        data: [1, 0, 0, 0, 0, 0],
      },
      {
        name: "Warmup emails sent",
        data: [8, 9, 4, 6, 5, 6],
      },
    ],
  },
  {
    id: 3,
    email: "freddie@b2brocket.ai",
    emailsSentToday: 0,
    emailsSentPastWeek: 147,
    emailsLanded: 146,
    emailsSaved: 1,
    health: 99,
    warmup: "enable",
    series: [
      {
        name: "Warmup emails landed in spam",
        data: [1, 0, 4, 0, 0, 0],
      },
      {
        name: "Warmup emails sent",
        data: [1, 5, 6, 9, 4, 2],
      },
    ],
  },
];

export const inboxData = [
  {
    id: 1,
    user: "test1@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 2,
    user: "test1@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },

  {
    id: 3,
    user: "test2@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 4,
    user: "test3@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 5,
    user: "test4@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
];

export const selectTypes = [
  "IMAP Username",
  "IMAP Password",
  "IMAP Port",
  "IMAP Host",
  "SMTP Username",
  "SMTP Password",
  "SMTP Port",
  "SMTP Host",
  "Email",
  "First Name",
  "Last Name",
  "Reply To",
  "Daily Limit",
  "Sending gap in minutes",
  "Warmup Enabled",
  "Warmup Increment",
  "Warmup Limit",
  "Warmup | Reply Rate Percent",
  "Warmup | Read Emulation",
  "Warmup | Warm tracking domain",
  "Warmup | Random email range (min)",
  "Warmup | Random email range (max)",
  "Warmup | Open rate",
  "Warmup | Spam protection rate",
  "Warmup | Mark important rate",
  "Do not import",
];

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const locationData = [
  {
    id: 0,
    label: "All Locations",
    children: [
      "Alabama, US",
      "Alaska, US",
      "Arizona, US",
      "Arkansas, US",
      "California, Maryland",
      "California, Missouri",
      "California, Pennsylvania",
      "California, US",
      "Colorado, US",
      "Connecticut, US",
      "Delaware, US",
      "Florida",
      "Florida, New York",
      "Florida, Argentina",
      "Florida, Bolivia",
      "Florida, US",
      "Georgia, Europe",
      "Georgia, US",
      "Georgia, Alabama",
      "Hawaii",
      "Hawaii, US",
      "Idaho, US",
      "Illinois, US",
      "Indiana",
      "Indiana, Pennsylvania",
      "Indiana, US",
      "Iowa, US",
      "Kansas, US",
      "Kentucky, US",
      "Louisiana, US",
      "Maine, US",
      "Maryland, US",
      "Massachusetts, US",
      "Michigan, US",
      "Minnesota, US",
      "Mississippi",
      "Mississippi, US",
      "Missouri, US",
      "Montana, US",
      "Nebraska, US",
      "Nevada, US",
      "New Hampshire, US",
      "New Jersey",
      "New Jersey, US",
      "New Mexico, US",
      "New York, US",
      "North Carolina, US",
      "North Dakota, US",
      "Ohio, US",
      "Oklahoma, US",
      "Oregon, US",
      "Pennsylvania, US",
      "Rhode Island, US",
      "South Carolina, US",
      "South Dakota, US",
      "Tennessee, US",
      "Texas",
      "Texas, US",
      "Utah, US",
      "Vermont, US",
      "Virginia",
      "Virginia, Minnesota",
      "Virginia, Illinois",
      "Virginia, Australia",
      "Virginia, US",
      "Washington, US",
      //   "Washington D.C.",
      "Washington, District of Columbia",
      "Washington, District of Columbia",

      "West Virginia, US",
      "Wisconsin, US",
      "Wyoming, US",
      "District of Columbia, US",
      "American Samoa",
      "Clermont, Florida",
      "Guam, US",
      "Puerto Rico, US",
      "Virgin Islands, US",

      // grp -> US Metro Areas
      "Monsey, New York",
      "Anchorage, Alaska",
      "Fairbanks, Alaska",
      "Fairbanks, Indiana",
      "Birmingham",
      "Birmingham, United Kingdom",
      "Birmingham, Iowa",
      "Birmingham, Alabama",
      "Anniston, Alabama",
      "Dothan, Alabama",
      "Huntsville, Alabama",
      "Mobile, Alabama",
      "Montgomery, Alabama",
      "Tuscaloosa, Alabama",
      "Fort Smith, Arkansas",
      "Jonesboro, Arkansas",
      "Phoenix, Arizona",
      "Eureka",
      "Eureka, Missouri",
      "Eureka, Illinois",
      "Eureka, Montana",
      "Eureka, South Dakota",
      "Eureka, Kansas",
      "Eureka, California",
      "Fresno, California",
      "Irvine, California",
      "Little Rock, Arkansas",
      "Tucson, Arizona",
      "Yuma, Arizona",
      "Bakersfield, California",
      "Chico, California",
      "Eureka, California",
      "Fresno, California",
      "Los Angeles, California",
      "Monterey, California",
      // "Irvine, California",
      "Palm Springs, California",
      "Sacramento, California",
      "San Diego, California",
      "San Francisco, California",
      "San Jose, California",
      "Santa Barbara, California",
      "Denver, Colorado",
      "Fort Myers, Florida",
      "Colorado Springs, Colorado",
      "Grand Junction, Colorado",
      "Hartford, Connecticut",
      "Washington",
      "Washington, Pennsylvania",
      "Washington, Michigan",
      "Washington, Missouri",
      "Washington, New Jersey",
      "Washington, Indiana",
      "Washington, Virginia",
      "Washington, United Kingdom",
      "Washington, North Carolina",
      "Washington, Illinois",
      "Washington, Maine",
      "Washington, Iowa",
      "Washington, Utah",
      "Washington, Kansas",
      "Washington, California",
      "Washington D.C. Metro Area",
      "Gainesville, Florida",
      "Jacksonville, Florida",
      "Miami, Florida",
      "Orlando, Florida",
      "Panama City",
      "Panama City, Panama",
      "Panama City, Florida",
      "Tallahassee, Florida",
      "Tampa, Florida",
      "West Palm Beach, Florida",
      "Albany, Georgia",
      "Atlanta",
      "Atlanta, Illinois",
      "Atlanta, Texas",
      "Atlanta, Nebraska",
      "Atlanta, Idaho",
      "Atlanta, Missouri",
      "Atlanta, Indiana",
      "Atlanta, Georgia",
      "Augusta, Georgia",
      "Columbus, Georgia",
      "Macon, Georgia",
      "Savannah, Georgia",
      "Honolulu, Hawaii",
      "Cedar Rapids, Iowa",
      "Davenport, Iowa",
      "Des Moines, Iowa",
      "Ottumwa, Iowa",
      "Sioux City, Iowa",
      "Boise, Idaho",
      "Idaho Falls, Idaho",
      "Twin Falls, Idaho",
      "Chicago",
      "Chicago, Illinois",
      "Peoria, Illinois",
      "Quincy, Illinois",
      "Lafayette, Indiana",
      "Topeka, Kansas",
      "Rockford, Illinois",
      "Springfield, Illinois",
      "Evansville, Indiana",
      "Fort Wayne, Indiana",
      "Indianapolis, Indiana",
      "South Bend, Indiana",
      "Terre Haute, Indiana",
      "Wichita, Kansas",
      "Bowling Green, Kentucky",
      "Lexington, Kentucky",
      "Louisville, Kentucky",
      "Paducah, Kentucky",
      "Alexandria, Louisiana",
      "Baton Rouge, Louisiana",
      "Lafayette, Louisiana",
      "Lake Charles, Louisiana",
      "Monroe, Louisiana",
      "New Orleans, Louisiana",
      "Shreveport, Louisiana",
      "Boston",
      "Boston, United Kingdom",
      "Boston, New York",
      "Boston, Indiana",
      "Boston, Massachusetts",
      "Baltimore, Maryland",
      "Presque lsle, Maine",
      "Springfield, Massachusetts",
      "Salisbury, Maryland",
      "Havre de Grace, Maryland",
      "Baltimore, Maryland",
      "Eldersburg, Maryland",
      "Bangor, Maine",
      "Portland",
      "Portland, Oregon",
      "Portland, Tennessee",
      "Portland, Indiana",
      "Portland, Texas",
      "Portland, Connecticut",
      "Portland, United Kingdom",
      "Portland, Michigan",
      "Portland, Missouri",
      "Portland, Pennsylvania",
      "Portland, Ohio",
      "Portland, Arkansas",
      "Portland, Maine",
      "Alpena, Michigan",
      "Detroit, Michigan",
      "Flint Township, Michigan",
      "Grand Rapids, Michigan",
      "Lansing, Michigan",
      "Marquette, Michigan",
      "Traverse City, Michigan",
      "Duluth, Minnesota",
      "Mankato, Minnesota",
      "Columbus, Mississippi",
      "Minneapolis, Minnesota",
      "Rochester, Minnesota",
      "Jefferson City, Missouri",
      "Joplin, Missouri",
      "Kansas City, Missouri",
      "Saint Joseph, Missouri",
      "Saint Louis, Missouri",
      "Springfield, Missouri",
      "Biloxi, Mississippi",
      "Greenville, Mississippi",
      "Hattiesburg, Mississippi",
      "Jackson, Mississippi",
      "Meridian, Mississippi",
      "Billings, Montana",
      "Butte, Montana",
      "Glendive, Montana",
      "Great Falls, Montana",
      "Helena, Montana",
      "Missoula, Montana",
      "Charlotte, North Carolina",
      "Greenville, North Carolina",
      "Raleigh, North Carolina",
      "Wilmington, North Carolina",
      "Winston-Salem, North Carolina",
      "Winston-Salem, North Carolina",

      "Bismarck, North Dakota",
      "Fargo, North Dakota",
      "Lincoln, Nebraska",
      "North Platte, Nebraska",
      "Omaha, Nebraska",
      "Albuquerque, New Mexico",
      "Las Vegas, Nevada",
      "Reno, Nevada",
      "Albany, New York",
      "Binghamton, New York",
      "Buffalo, New York",
      "Elmira, New York",
      "New York, New York",
      "Rochester, New York",
      "Syracuse, New York",
      "Utica, New York",
      "Watertown, New York",
      "Cincinnati, Ohio",
      "Cleveland, Ohio",
      "Columbus, Ohio",
      "Dayton, Ohio",
      "Lima, Peru",
      "Toledo, Ohio",
      "Wheeling, West Virginia",
      "Youngstown, Ohio",
      "Zanesville, Ohio",
      "Ardmore, Oklahoma",
      "Oklahoma City, Oklahoma",
      "Tulsa, Oklahoma",
      "Eugene, Oregon",
      "Erie, Pennsylvania",
      "Johnstown, Pennsylvania",
      "Pittsburgh, Pennsylvania",
      "Wilkes-Barre, Pennsylvania",
      "Mayagüez, Puerto Rico",
      "Ponce, Puerto Rico",
      "Providence, Rhode Island",
      "Columbia, South Carolina",
      "Florence, South Carolina",
      "Bend, Oregon",
      "Medford, Oregon",
      "Portland, Oregon",
      "Harrisburg, Pennsylvania",
      "Philadelphia, Pennsylvania",
      "Aguadilla, Puerto Rico",
      "San Juan, Puerto Rico",
      "Charleston, South Carolina",
      "Greenville, South Carolina",
      "Rapid City, South Dakota",
      "Sioux Falls, South Dakota",
      "Chattanooga, Tennessee",
      "Jackson, Tennessee",
      "Knoxville, Tennessee",
      "Memphis, Tennessee",
      "Nashville, Tennessee",
      //   "Tri Cities, Washington",
      "Abilene, Texas",
      "Amarillo, Texas",
      "Austin, Texas",
      "Beaumont, Texas",
      "Bryan, Texas",
      "Corpus Christi, Texas",
      "Dallas, Texas",
      "El Paso, Texas",
      "Harlingen, Texas",
      "Houston, Texas",
      "Laredo, Texas",
      "Lubbock, Texas",
      "Odessa, Texas",
      "San Antonio, Texas",
      "Tyler, Texas",
      "Wichita Falls, Texas",
      "San Angelo, Texas",
      "Victoria, Texas",
      "Hampton, Virginia",
      "Roanoke, Virginia",
      "Salt Lake City, Utah",
      "Charlottesville, Virginia",
      "Harrisonburg, Virginia",
      "Richmond, Virginia",
      "Burlington, Vermont",
      "Seattle, Washington",
      "Spokane, Washington",
      //   "Tri Cities Area",
      "Green Bay, Wisconsin",
      "La Crosse, Wisconsin",
      "Madison, Wisconsin",
      "Milwaukee, Wisconsin",
      "Wausau, Wisconsin",
      "Bluefield, West Virginia",
      "Charleston, West Virginia",
      "Clarksburg, West Virginia",
      //   "Parkersburg, West Virginia",
      "Casper, Wyoming",
      "Cheyenne, Wyoming",
      "Canada",
      "Alberta",
      "Alberta, Minnesota",
      "Alberta, Canada",
      "British Columbia, Canada",
      "Manitoba, Canada",
      "New Brunswick, Canada",
      "Newfoundland and Labrador, Canada",
      "Newfoundland and Labrador, Canada",

      "Northwest Territories, Canada",
      "Northwest Territories, Canada",

      "Nova Scotia, Canada",
      "Nunavut, Canada",
      "Ontario, Canada",
      "Prince Edward Island, Canada",
      "Prince Edward Island, Canada",

      "Quebec, Canada",
      "Saskatchewan, Canada",
      "Yukon Territory, Canada",
      "Calgary, Canada",
      "Edmonton, Canada",
      "Abbotsford, Canada",
      "Kelowna, Canada",
      "Victoria",
      "Victoria, Australia",
      "Victoria, Illinois",
      "Victoria, Romania",
      "Victoria, Minnesota",
      "Victoria, Mississippi",
      "Victoria, Canada",
      "Winnipeg, Canada",
      "Moncton, Canada",
      "St. John's, Canada",
      "St. Albert, Canada",
      "Halifax, Canada",
      "Barrie, Canada",
      "London, Canada",
      "London, United Kingdom",
      "London, Kentucky",
      "London, Ohio",
      "Londonderry, Vermont",
      "London, Texas",
      "London Mills, Illinois",
      "London, Oregon",
      "London, West Virginia",
      "London, California",
      "London, Arkansas",
      "Ottawa, Canada",
      "Vancouver, Canada",
      "Yellowknife, Canada",
      "Brantford, Canada",
      "Guelph, Canada",
      "Hamilton, Canada",
      "Kingston, Canada",
      "Kitchener, Canada",
      "Peterborough, Canada",
      "Saint Catharines, Canada",
      "Sudbury, Canada",
      "Thunder Bay, Canada",
      "Toronto, Canada",
      "Windsor, Canada",
      "Charlottetown, Canada",
      "Saguenay, Canada",
      "Montreal, Canada",
      "Quebec, Canada",
      "Sherbrooke, Canada",
      "Trois-Rivières, Canada",
      "Regina, Canada",
      "Saskatoon, Canada",
      "Africa",
      "Algeria",
      "Angola",
      "Benin",
      "Botswana",
      "Burkina Faso",
      "Burundi",
      "Cameroon",
      "Cape Verde",
      "Central African Republic",
      "Chad",
      "Comoros",
      "Moroni, Comoros",
      "Ngazidja, Comoros",
      "Nzwani, Comoros",
      "Democratic Republic of the Congo",
      "Republic of the Congo",
      "Djibouti",
      "Djibouti, Djibouti",
      "Egypt",
      "Equatorial Guinea",
      "Eritrea",
      "Asmara, Eritrea",
      "Ethiopia",
      "Gabon",
      "The Gambia",
      "Serrekunda, The Gambia",
      "Banjul, The Gambia",
      "West Coast Region, The Gambia",
      "West Coast Region, The Gambia",

      "Serrekunda, The Gambia",
      "Ghana",
      "Guinea",
      "Conakry, Guinea",
      "Kamsar, Guinea",
      "Guinea-Bissau",
      "Bissau, Guinea-Bissau",
      "Kenya",
      "Lesotho",
      "Liberia",
      "Libya",
      "Madagascar",
      "Malawi",
      "Mali",
      "Mauritania",
      "Mauritius",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Niger",
      "Nigeria",
      "Rwanda",
      "São Tomé, São Tomé and Príncipe",
      "São Tomé, São Tomé and Príncipe",

      "São Tomé and Príncipe",
      "Senegal",
      "Seychelles",
      "Sierra Leone",
      "Somalia",
      "South Africa",
      "Cape Town, South Africa",
      "Sudan",
      "Swaziland",
      "Tanzania",
      "Togo",
      "Tunisia",
      "Uganda",
      "Zambia",
      "Asia",
      "Afghanistan",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Bangladesh",
      "Bhutan",
      "Brunei",
      "Cambodia",
      "China",
      "Georgia",
      "India",
      "Indonesia",
      "Iran",
      "Israel",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Lebanon",
      "Malaysia",
      "Maldives",
      "Mongolia",
      "Myanmar",
      "United Arab Emirates",
      "Sindo, North Korea",
      "Pyongyang, North Korea",
      "Kangwon, North Korea",
      "North Korea",
      "Oman",
      "Philippines",
      "Qatar",
      "Saudi Arabia",
      "Singapore",
      "South Korea",
      "Sri Lanka",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Europe",
      "Albania",
      "Andorra",
      "Austria",
      "Belarus",
      "Belgium",
      "Belgium, Wisconsin",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Denmark, South Carolina",
      "Denmark, Iowa",
      "Estonia",
      "Finland",
      "Finland, Minnesota",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Iceland",
      "Ireland",
      "Italy",
      "Italy, Texas",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Macedonia, Illinois",
      "Macedonia, Ohio",
      "Macedonia (FYROM)",
      "Malta",
      "Moldova",
      "Monaco",
      "Netherlands",
      "Norway",
      "Norway, Iowa",
      "Norway, Maine",
      "Poland",
      "Poland, Indiana",
      "Poland, Maine",
      "Poland, New York",
      "Portugal",
      "Romania",
      "Brașov, Romania",
      "Sibiu, Romania",
      "Bucharest, Romania",
      "Russia",
      "Russia, Ohio",
      "San Marino",
      "San Marino, California",
      "San Marino, Italy",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
      "Switzerland",
      "Ukraine",
      "United Kingdom",
      "Swindon, United Kingdom",
      "North America",
      "Antigua",
      "The Bahamas",
      "Barbados",
      "Belize",
      "Costa Rica",
      "Cuba",
      "Cuba, Missouri",
      "Cuba, Illinois",
      "Cuba, New York",
      "Cuba, Alabama",
      "Cuba, New Mexico",
      "Dominica",
      "Dominican Republic",
      "El Salvador",
      "Grenada",
      "Grenada, Mississippi",
      "Guatemala",
      "Haiti",
      "Honduras",
      "Jamaica",
      "Jamaica, Mexico",
      "Jamaica, Iowa",
      "Mexico",
      "Mexico, Maine",
      "Mexico, Missouri",
      "Mexico, New York",
      "Mexico, Indiana",
      "Nicaragua",
      "Panama",
      "Saint Kitts and Nevis",
      "Saint Vincent and the Grenadines",
      "Saint Vincent and the Grenadines",

      "Trinidad and Tobago",
      // "Oceania" ,
      "Australia",
      "Cook Islands",
      "Fiji",
      "Kiribati",
      "New Zealand",
      "Papua New Guinea",
      "Samoa",
      "Solomon Islands",
      "Timor-Leste",
      "Tonga",
      "Vanuatu",
      "South America",
      "Argentina",
      "Argentine, France",
      "Bolivia",
      "Brazil",
      "Brazil, Indiana",
      "Chile",
      "Colombia",
      "Ecuador",
      "Guyana",
      "Paraguay",
      "Peru",
      "Peru, Illinois",
      "Peru, Indiana",
      "Suriname",
      "Uruguay",
      "Venezuela",
      "Lancaster",
      "Lancaster, Texas",
      "Lancaster, Minnesota",
      // "Lancaster, VIC, AU",
      "Lancaster, California",
      // "South Lancaster, ON, CA",
      "Afghanistan",
      "Kabul", "Kandahar", "Herat", "Mazar-i-Sharif",
      "Albania",
      "Tirana", "Durrës", "Vlorë", "Shkodër",
      "Algeria",
      "Algiers", "Oran", "Constantine", "Annaba",
      "Andorra",
      "Angola",
      "Luanda",
      "Huambo",
      "Lobito",
      "Benguela",
      "Kuito",
      "Malanje",
      "Namibe",
      "Soyo",
      "Cabinda",
      "Uíge",
      "Antigua and Barbuda",
      "St. John's",
      "All Saints",
      "Liberta",
      "Bolans",
      "Piggotts",
      "Parham",
      "Carlisle",
      "Falmouth",
      "Willikies",
      "Codrington",
      "Argentina",
      "Buenos Aires",
      "Córdoba",
      "Rosario",
      "Mendoza",
      "San Miguel de Tucumán",
      "La Plata",
      "Mar del Plata",
      "Salta",
      "Santa Fe",
      "San Juan",
      "Armenia",
      "Yerevan",
      "Gyumri",
      "Vanadzor",
      "Vagharshapat",
      "Hrazdan",
      "Abovyan",
      "Kapan",
      "Ararat",
      "Armavir",
      "Gavar",
      "Australia",
      "Sydney",
      "Melbourne",
      "Brisbane",
      "Perth",
      "Adelaide",
      "Gold Coast",
      "Newcastle",
      "Canberra",
      "Hobart",
      "Geelong",
      "Austria",
      "Vienna",
      "Graz",
      "Linz",
      "Salzburg",
      "Innsbruck",
      "Klagenfurt",
      "Villach",
      "Wels",
      "Sankt Pölten",
      "Dornbirn",
      "Wiener Neustadt",
      "Steyr",
      "Feldkirch",
      "Bregenz",
      "Leonding",
      "Klosterneuburg",
      "Baden bei Wien",
      "Wolfsberg",
      "Leoben",
      "Krems an der Donau",
      "Traun",
      "Amstetten",
      "Lustenau",
      "Kapfenberg",
      "Mödling",
      "Hallein",
      "Kufstein",
      "Schwechat",
      "Traiskirchen",
      "Azerbaijan",
      "Baku",
      "Ganja",
      "Sumqayit",
      "Mingachevir",
      "Lankaran",
      "Shirvan",
      "Nakhchivan",
      "Shaki",
      "Yevlakh",
      "Khirdalan",
      "Barda",
      "Bilasuvar",
      "Gabala",
      "Guba",
      "Saatli",
      "Zaqatala",
      "Jalilabad",
      "Qazakh",
      "Shamkir",
      "Tovuz",
      "Masalli",
      "Gadabay",
      "Ismayilli",
      "Ujar",
      "Bahamas",
      "Nassau",
      "Freeport",
      "West End",
      "Coopers Town",
      "Marsh Harbour",
      "High Rock",
      "Andros Town",
      "Clarence Town",
      "Dunmore Town",
      "Rock Sound",
      "Bahrain",
      "Manama",
      "Riffa",
      "Muharraq",
      "Hamad Town",
      "A'ali",
      "Isa Town",
      "Sitra",
      "Budaiya",
      "Jidhafs",
      "Zallaq",
      "Bangladesh",
      "Dhaka",
      "Chittagong",
      "Khulna",
      "Rajshahi",
      "Comilla",
      "Rangpur",
      "Mymensingh",
      "Barisal",
      "Narayanganj",
      "Sylhet",
      "Gazipur",
      "Narsingdi",
      "Tongi",
      "Jessore",
      "Cox's Bazar",
      "Bogra",
      "Saidpur",
      "Nawabganj",
      "Feni",
      "Kushtia",
      "Noakhali",
      "Pabna",
      "Dinajpur",
      "Brahmanbaria",
      "Bagerhat",
      "Barbados",
      "Bridgetown",
      "Speightstown",
      "Oistins",
      "Holetown",
      "Bathsheba",
      "Crane",
      "Six Cross Roads",
      "Greenland",
      "Checker Hall",
      "Haggatt Hall",
      "Belarus",
      "Minsk",
      "Gomel",
      "Mogilev",
      "Vitebsk",
      "Grodno",
      "Brest",
      "Bobruisk",
      "Baranovichi",
      "Borisov",
      "Pinsk",
      "Orsha",
      "Mozyr",
      "Novopolotsk",
      "Soligorsk",
      "Lida",
      "Slutsk",
      "Polotsk",
      "Zhlobin",
      "Svetlogorsk",
      "Rechitsa",
      "Belgium",
      "Brussels",
      "Antwerp",
      "Ghent",
      "Charleroi",
      "Liège",
      "Bruges",
      "Namur",
      "Leuven",
      "Mons",
      "Aalst",
      "Mechelen",
      "La Louvière",
      "Kortrijk",
      "Hasselt",
      "Ostend",
      "Sint-Niklaas",
      "Tournai",
      "Genk",
      "Seraing",
      "Roeselare",
      "Belize",
      "Belize City",
      "San Ignacio",
      "Orange Walk Town",
      "Belmopan",
      "Dangriga",
      "Corozal Town",
      "Punta Gorda",
      "Benque Viejo del Carmen",
      "Hopkins",
      "Placencia",
      "San Pedro",
      "Spanish Lookout",
      "Caye Caulker",
      "Valley of Peace",
      "Bullet Tree Falls",
      "Maskall",
      "Benin",
      "Cotonou",
      "Porto-Novo",
      "Parakou",
      "Djougou",
      "Bohicon",
      "Abomey-Calavi",
      "Kandi",
      "Lokossa",
      "Ouidah",
      "Natitingou",
      "Savalou",
      "Malanville",
      "Tchaourou",
      "Pobe",
      "Sakete",
      "Come",
      "Banikoara",
      "Bembereke",
      "Dogbo",
      "Ketou",
      "Bhutan",
      "Thimphu",
      "Phuntsholing",
      "Paro",
      "Punakha",
      "Wangdue Phodrang",
      "Jakar",
      "Trashigang",
      "Mongar",
      "Gelephu",
      "Samdrup Jongkhar",
      "Trongsa",
      "Bumthang",
      "Haa",
      "Gasa",
      "Samtse",
      "Lhuntse",
      "Zhemgang",
      "Bolivia",
      "Santa Cruz de la Sierra",
      "La Paz",
      "Cochabamba",
      "Sucre",
      "Oruro",
      "Tarija",
      "Potosí",
      "Sacaba",
      "Quillacollo",
      "Montero",
      "Trinidad",
      "Riberalta",
      "Yacuiba",
      "Quiborax",
      "Llallagua",
      "Villazón",
      "San Ignacio de Velasco",
      "San Borja",
      "Warnes",
      "Bermejo",
      "Bosnia and Herzegovina",
      "Sarajevo",
      "Banja Luka",
      "Tuzla",
      "Zenica",
      "Mostar",
      "Bijeljina",
      "Prijedor",
      "Brčko",
      "Trebinje",
      "Doboj",
      "Cazin",
      "Široki Brijeg",
      "Visoko",
      "Travnik",
      "Goražde",
      "Konjic",
      "Livno",
      "Zvornik",
      "Sanski Most",
      "Velika Kladuša",
      "Botswana",
      "Gaborone",
      "Francistown",
      "Molepolole",
      "Serowe",
      "Selebi-Phikwe",
      "Maun",
      "Mahalapye",
      "Palapye",
      "Lobatse",
      "Mochudi",
      "Kanye",
      "Ramotswa",
      "Thamaga",
      "Ghanzi",
      "Kasane",
      "Jwaneng",
      "Orapa",
      "Letlhakane",
      "Tlokweng",
      "Brazil",
      "São Paulo",
      "Rio de Janeiro",
      "Brasília",
      "Salvador",
      "Fortaleza",
      "Belo Horizonte",
      "Manaus",
      "Curitiba",
      "Recife",
      "Porto Alegre",
      "Belém",
      "Goiânia",
      "Guarulhos",
      "Campinas",
      "São Luís",
      "São Gonçalo",
      "Maceió",
      "Duque de Caxias",
      "Natal",
      "Teresina",
      "Brunei",
      "Bandar Seri Begawan",
      "Kuala Belait",
      "Seria",
      "Tutong",
      "Bangar",
      "Sungai Liang",
      "Jerudong",
      "Muara",
      "Lumut",
      "Pekan Tutong",
      "Kuala Lurah",
      "Sukang",
      "Bulgaria",
      "Sofia",
      "Plovdiv",
      "Varna",
      "Burgas",
      "Ruse",
      "Stara Zagora",
      "Pleven",
      "Sliven",
      "Dobrich",
      "Shumen",
      "Pernik",
      "Haskovo",
      "Yambol",
      "Blagoevgrad",
      "Veliko Tarnovo",
      "Gabrovo",
      "Vratsa",
      "Kardzhali",
      "Montana",
      "Lovech",
      "Burkina Faso",
      "Ouagadougou",
      "Bobo-Dioulasso",
      "Koudougou",
      "Ouahigouya",
      "Banfora",
      "Dédougou",
      "Fada N'gourma",
      "Tenkodogo",
      "Kaya",
      "Houndé",
      "Manga",
      "Ziniaré",
      "Réo",
      "Koupéla",
      "Nouna",
      "Diapaga",
      "Gaoua",
      "Gourcy",
      "Yako",
      "Dori",
      "Burundi",
      "Bujumbura",
      "Muyinga",
      "Ngozi",
      "Gitega",
      "Ruyigi",
      "Bururi",
      "Kirundo",
      "Makamba",
      "Cibitoke",
      "Muramvya",
      "Kayanza",
      "Rutana",
      "Bubanza",
      "Karuzi",
      "Rumonge",
      "Mwaro",
      "Buhiga",
      "Bugarama",
      "Rutumo",
      "Bunyoni",
      "Cambodia",
      "Phnom Penh",
      "Siem Reap",
      "Sihanoukville",
      "Battambang",
      "Kampong Cham",
      "Krong Preah Sihanouk",
      "Ta Khmau",
      "Krong Pursat",
      "Krong Svay Rieng",
      "Krong Kampot",
      "Krong Ta Khmau",
      "Krong Siem Reap",
      "Krong Kep",
      "Kampong Speu",
      "Kampong Thom",
      "Krong Koh Kong",
      "Kampong Chhnang",
      "Krong Pailin",
      "Krong Prey Veng",
      "Kratié",
      "Cameroon",
      "Douala",
      "Yaoundé",
      "Garoua",
      "Bamenda",
      "Maroua",
      "Bafoussam",
      "Ngaoundéré",
      "Bertoua",
      "Ebolowa",
      "Kumba",
      "Limbe",
      "Bafia",
      "Edea",
      "Loum",
      "Kribi",
      "Foumban",
      "Mbouda",
      "Nkongsamba",
      "Dschang",
      "Bafang",
      "Canada",
      "Toronto",
      "Montreal",
      "Vancouver",
      "Calgary",
      "Edmonton",
      "Ottawa",
      "Winnipeg",
      "Quebec City",
      "Hamilton",
      "Kitchener",
      "London",
      "Victoria",
      "Halifax",
      "Oshawa",
      "Windsor",
      "Sherbrooke",
      "Saskatoon",
      "Barrie",
      "Regina",
      "St. John's",
      "Cape Verde",
      "Praia",
      "Mindelo",
      "Santa Maria",
      "Assomada",
      "São Filipe",
      "Tarrafal",
      "Espargos",
      "Pedra Badejo",
      "Vila do Maio",
      "Pombas",
      "Calheta de São Miguel",
      "Ribeira Brava",
      "Porto Novo",
      "Vila da Ribeira Brava",
      "Vila do Porto Inglês",
      "Cidade Velha",
      "Sal Rei",
      "Ribeira Grande de Santiago",
      "Central African Republic",
      "Bangui",
      "Bimbo",
      "Berbérati",
      "Carnot",
      "Bambari",
      "Bria",
      "Bossangoa",
      "Bozoum",
      "Nola",
      "Bouar",
      "Kaga-Bandoro",
      "Sibut",
      "Damara",
      "Mobaye",
      "Gamboula",
      "Mbaiki",
      "Batangafo",
      "Boda",
      "Baboua",
      "Grimari",
      "Chad",
      "N'Djamena",
      "Moundou",
      "Sarh",
      "Abéché",
      "Kélo",
      "Koumra",
      "Pala",
      "Am Timan",
      "Bongor",
      "Mongo",
      "Doba",
      "Ati",
      "Fada",
      "Massakory",
      "Biltine",
      "Goz Beida",
      "Benoy",
      "Bol",
      "Laï",
      "Dourbali",
      "Chile",
      "Santiago",
      "Puente Alto",
      "Antofagasta",
      "Viña del Mar",
      "Valparaíso",
      "Talcahuano",
      "San Bernardo",
      "Temuco",
      "Iquique",
      "Concepción",
      "Rancagua",
      "La Pintana",
      "Talca",
      "Arica",
      "Coquimbo",
      "Puerto Montt",
      "La Serena",
      "Chillán",
      "Calama",
      "Osorno",
      "China",
      "Shanghai",
      "Beijing",
      "Guangzhou",
      "Shenzhen",
      "Tianjin",
      "Chongqing",
      "Chengdu",
      "Nanjing",
      "Wuhan",
      "Xi'an",
      "Hangzhou",
      "Shenyang",
      "Harbin",
      "Suzhou",
      "Dalian",
      "Zhengzhou",
      "Jinan",
      "Qingdao",
      "Changsha",
      "Kunming",
      "Colombi",
      "Bogotá",
      "Medellín",
      "Cali",
      "Barranquilla",
      "Cartagena",
      "Cúcuta",
      "Bucaramanga",
      "Pereira",
      "Santa Marta",
      "Ibagué",
      "Pasto",
      "Manizales",
      "Neiva",
      "Soledad",
      "Villavicencio",
      "Armenia",
      "Valledupar",
      "Popayán",
      "Bello",
      "Montería",
      "Comoros",
      "Moroni",
      "Mutsamudu",
      "Fomboni",
      "Domoni",
      "Tsimbeo",
      "Ouani",
      "Mirontsi",
      "Mitsamiouli",
      "Koni-Djodjo",
      "Bandar Salim",
      "Nioumachoua",
      "Mnoungou",
      "Nkourani",
      "Itsandra",
      "Mavingouni",
      "Vouvouni",
      "Koki",
      "Congo (Brazzaville)",
      "Brazzaville",
      "Pointe-Noire",
      "Dolisie",
      "Nkayi",
      "Impfondo",
      "Owando",
      "Loandjili",
      "Mossendjo",
      "Sibiti",
      "Gamboma",
      "Madingou",
      "Ouésso",
      "Makoua",
      "Boko",
      "Kinkala",
      "Ewo",
      "Kindamba",
      "Loutété",
      "Djambala",
      "Mindouli",
      "Congo",
      "Kinshasa",
      "Lubumbashi",
      "Mbuji-Mayi",
      "Kananga",
      "Kisangani",
      "Bukavu",
      "Tshikapa",
      "Likasi",
      "Goma",
      "Kolwezi",
      "Ilebo",
      "Uvira",
      "Bunia",
      "Isiro",
      "Kikwit",
      "Kindu",
      "Matadi",
      "Bandundu",
      "Mbandaka",
      "Boma",
      "Costa Rica",
      "San José",
      "Limon",
      "San Francisco",
      "Alajuela",
      "Liberia",
      "Paraiso",
      "Puntarenas",
      "San Isidro",
      "Curridabat",
      "San Vicente",
      "Cartago",
      "Heredia",
      "Tres Rios",
      "Calle Blancos",
      "San Miguel",
      "San Pablo",
      "San Juan",
      "Cote d'Ivoire",
      "Abidjan",
      "Yamoussoukro",
      "Bouaké",
      "Daloa",
      "San-Pédro",
      "Divo",
      "Korhogo",
      "Anyama",
      "Abobo",
      "Man",
      "Gagnoa",
      "Bondoukou",
      "Agboville",
      "Grand-Bassam",
      "Séguéla",
      "Odienné",
      "Tiassalé",
      "Dabou",
      "Duekoué",
      "Guiglo",
      "Croatia",
      "Zagreb",
      "Split",
      "Rijeka",
      "Osijek",
      "Zadar",
      "Slavonski Brod",
      "Pula",
      "Sesvete",
      "Karlovac",
      "Varaždin",
      "Cuba",
      "Havana",
      "Santiago de Cuba",
      "Camagüey",
      "Holguín",
      "Santa Clara",
      "Guantánamo",
      "Bayamo",
      "Las Tunas",
      "Cienfuegos",
      "Pinar del Río",
      "Cyprus",
      "Nicosia",
      "Limassol",
      "Larnaca",
      "Famagusta",
      "Paphos",
      "Kyrenia",
      "Protaras",
      "Morphou",
      "Aradippou",
      "Paralimni",
      "Czech Republic",
      "Prague",
      "Brno",
      "Ostrava",
      "Plzeň",
      "Liberec",
      "Olomouc",
      "České Budějovice",
      "Hradec Králové",
      "Ústí nad Labem",
      "Pardubice",
      "Denmark",
      "Copenhagen",
      "Aarhus",
      "Odense",
      "Aalborg",
      "Esbjerg",
      "Randers",
      "Kolding",
      "Horsens",
      "Vejle",
      "Roskilde",
      "Djibouti",
      "Djibouti City",
      "Ali Sabieh",
      "Tadjoura",
      "Obock",
      "Dikhil",
      "Arta",
      "Holhol",
      "Dominica",
      "Roseau",
      "Portsmouth",
      "Marigot",
      "Berekua",
      "Mahaut",
      "Saint Joseph",
      "Castle Bruce",
      "La Plaine",
      "Grand Bay",
      "Wesley",
      "Dominican Republic",
      "Santo Domingo",
      "Santiago de los Caballeros",
      "Santo Domingo Oeste",
      "Santo Domingo Este",
      "San Pedro de Macorís",
      "La Romana",
      "San Cristóbal",
      "Puerto Plata",
      "San Francisco de Macorís",
      "Salvaleón de Higüey",
      "East Timor (Timor Timur)",
      "Dili",
      "Baucau",
      "Lospalos",
      "Suai",
      "Same",
      "Maliana",
      "Liquiçá",
      "Aileu",
      "Viqueque",
      "Manatuto",
      "Ecuador",
      "Quito",
      "Guayaquil",
      "Cuenca",
      "Santo Domingo de los Colorados",
      "Machala",
      "Manta",
      "Portoviejo",
      "Ambato",
      "Durán",
      "Loja",
      "Egypt",
      "Cairo",
      "Alexandria",
      "Giza",
      "Shubra El-Kheima",
      "Port Said",
      "Suez",
      "Luxor",
      "Asyut",
      "Mansoura",
      "Tanta",
      "El Salvador",
      "San Salvador",
      "Santa Ana",
      "San Miguel",
      "Mejicanos",
      "Soyapango",
      "Apopa",
      "Delgado",
      "Sonsonate",
      "San Marcos",
      "Usulután",
      "Equatorial Guinea",
      "Malabo",
      "Bata",
      "Ebebiyín",
      "Aconibe",
      "Anisoc",
      "Luba",
      "Mongomo",
      "Mikomeseng",
      "Rebola",
      "Palea",
      "Eritrea",
      "Asmara",
      "Keren",
      "Massawa",
      "Assab",
      "Mendefera",
      "Barentu",
      "Adi Keyh",
      "Akordat",
      "Teseney",
      "Segeneiti",
      "Estonia",
      "Tallinn",
      "Tartu",
      "Narva",
      "Pärnu",
      "Kohtla-Järve",
      "Viljandi",
      "Rakvere",
      "Sillamäe",
      "Maardu",
      "Kuressaare",
      "Ethiopia",
      "Addis Ababa",
      "Dire Dawa",
      "Adama",
      "Mekelle",
      "Gondar",
      "Hawassa",
      "Bahir Dar",
      "Jimma",
      "Debre Markos",
      "Dessie",
      "Fiji",
      "Suva",
      "Lautoka",
      "Nadi",
      "Labasa",
      "Ba",
      "Levuka",
      "Savusavu",
      "Rakiraki",
      "Tavua",
      "Nausori",
      "Finland",
      "Helsinki",
      "Espoo",
      "Tampere",
      "Vantaa",
      "Oulu",
      "Turku",
      "Jyväskylä",
      "Lahti",
      "Kuopio",
      "Kouvola",
      "France",
      "Paris",
      "Marseille",
      "Lyon",
      "Toulouse",
      "Nice",
      "Nantes",
      "Strasbourg",
      "Montpellier",
      "Bordeaux",
      "Lille",
      "Rennes",
      "Reims",
      "Le Havre",
      "Cannes",
      "Saint-Étienne",
      "Toulon",
      "Grenoble",
      "Dijon",
      "Angers",
      "Nîmes",
      "Gabon",
      "Libreville",
      "Port-Gentil",
      "Franceville",
      "Oyem",
      "Moanda",
      "Mouila",
      "Lambaréné",
      "Tchibanga",
      "Koulamoutou",
      "Makokou",
      "Bitam",
      "Mékambo",
      "Mbigou",
      "Ndjolé",
      "Okondja",
      "Lékoni",
      "Lastoursville",
      "Gamba",
      "Mayumba",
      "Booué",
      "Gambia, The",
      "Banjul",
      "Serekunda",
      "Brikama",
      "Bakau",
      "Banjulinding",
      "Farafenni",
      "Lamin",
      "Soma",
      "Gunjur",
      "Sukuta",
      "Basse Santa Su",
      "Brufut",
      "Bakoteh",
      "Kololi",
      "Kerewan",
      "Barra",
      "Kuntaur",
      "Essau",
      "Jufureh",
      "Kanifing",
      "Georgia",
      "Tbilisi",
      "Kutaisi",
      "Batumi",
      "Rustavi",
      "Zugdidi",
      "Gori",
      "Poti",
      "Samtredia",
      "Khashuri",
      "Senaki",
      "Marneuli",
      "Tsqaltubo",
      "Telavi",
      "Akhaltsikhe",
      "Ozurgeti",
      "Kobuleti",
      "Kaspi",
      "Khobi",
      "Borjomi",
      "Signagi",
      "Germany",
      "Berlin",
      "Hamburg",
      "Munich",
      "Cologne",
      "Frankfurt",
      "Stuttgart",
      "Düsseldorf",
      "Dortmund",
      "Essen",
      "Leipzig",
      "Bremen",
      "Dresden",
      "Hanover",
      "Nuremberg",
      "Duisburg",
      "Bochum",
      "Wuppertal",
      "Bielefeld",
      "Bonn",
      "Münster",
      "Ghana",
      "Accra",
      "Kumasi",
      "Tamale",
      "Takoradi",
      "Sekondi",
      "Cape Coast",
      "Sunyani",
      "Ho",
      "Wa",
      "Bolgatanga",
      "Koforidua",
      "Techiman",
      "Nkawkaw",
      "Dunkwa-on-Offin",
      "Berekum",
      "Winneba",
      "Hohoe",
      "Yendi",
      "Navrongo",
      "Keta",
      "Greece",
      "Athens",
      "Thessaloniki",
      "Patras",
      "Heraklion",
      "Larissa",
      "Volos",
      "Rhodes",
      "Ioannina",
      "Chania",
      "Chalcis",
      "Komotini",
      "Kavala",
      "Serres",
      "Alexandroupoli",
      "Lamia",
      "Kozani",
      "Katerini",
      "Mytilene",
      "Tripoli",
      "Corfu",
      "Grenada",
      "St. George's",
      "Gouyave",
      "Grenville",
      "Victoria",
      "Guatemala",
      "Guatemala City",
      "Mixco",
      "Villa Nueva",
      "Quetzaltenango",
      "San Miguel Petapa",
      "Escuintla",
      "Chinautla",
      "Chimaltenango",
      "Chichicastenango",
      "Huehuetenango",
      "Guinea",
      "Conakry",
      "Nzérékoré",
      "Kindia",
      "Kankan",
      "Guéckédou",
      "Kissidougou",
      "Labé",
      "Fria",
      "Siguiri",
      "Mamou",
      "Guinea-Bissau",
      "Bissau",
      "Bafatá",
      "Gabú",
      "Bissorã",
      "Bolama",
      "Cacheu",
      "Catió",
      "Fulacunda",
      "Mansôa",
      "Quebo",
      "Guyana",
      "Georgetown",
      "Linden",
      "New Amsterdam",
      "Bartica",
      "Skeldon",
      "Rosignol",
      "Mabaruma",
      "Anna Regina",
      "Kwakwani",
      "Mahdia",
      "Haiti",
      "Port-au-Prince",
      "Cap-Haïtien",
      "Carrefour",
      "Delmas",
      "Pétion-Ville",
      "Port-de-Paix",
      "Jacmel",
      "Léogâne",
      "Les Cayes",
      "Gonaïves",
      "Honduras",
      "Tegucigalpa",
      "San Pedro Sula",
      "Choloma",
      "La Ceiba",
      "El Progreso",
      "Ciudad Choluteca",
      "Comayagua",
      "Puerto Cortés",
      "Danlí",
      "Juticalpa",
      "Hungary",
      "Budapest",
      "Debrecen",
      "Szeged",
      "Miskolc",
      "Pécs",
      "Győr",
      "Nyíregyháza",
      "Kecskemét",
      "Székesfehérvár",
      "Szombathely",
      "Iceland",
      "Reykjavík",
      "Kópavogur",
      "Hafnarfjörður",
      "Akureyri",
      "Reykjanesbær",
      "Garðabær",
      "Mosfellsbær",
      "Árborg",
      "Akranes",
      "Selfoss",
      "India",
      "Mumbai", "Delhi", "Bangalore", "Hyderabad", "Ahmedabad", "Chennai", "Kolkata", "Surat", "Pune", "Jaipur",
      "Indonesia",
      "Jakarta",
      "Surabaya",
      "Bandung",
      "Medan",
      "Semarang",
      "Makassar",
      "Palembang",
      "Tangerang",
      "Depok",
      "Batam",
      "Bekasi",
      "Padang",
      "Denpasar",
      "Pekanbaru",
      "Bandar Lampung",
      "Malang",
      "Banjarmasin",
      "Serang",
      "Samarinda",
      "Pontianak",
      "Iran",
      "Tehran",
      "Mashhad",
      "Isfahan",
      "Karaj",
      "Tabriz",
      "Shiraz",
      "Ahvaz",
      "Qom",
      "Kermanshah",
      "Urmia",
      "Rasht",
      "Kerman",
      "Yazd",
      "Ardabil",
      "Hamedan",
      "Zahedan",
      "Sari",
      "Arak",
      "Khorramabad",
      "Bandar Abbas",
      "Iraq",
      "Baghdad",
      "Basra",
      "Mosul",
      "Erbil",
      "Sulaymaniyah",
      "Kirkuk",
      "Najaf",
      "Karbala",
      "Nasiriyah",
      "Hilla",
      "Duhok",
      "Ramadi",
      "Fallujah",
      "Tikrit",
      "Diwaniyah",
      "Kut",
      "Amara",
      "Samawah",
      "Ba'qubah",
      "Ireland",
      "Dublin",
      "Cork",
      "Limerick",
      "Galway",
      "Waterford",
      "Drogheda",
      "Dundalk",
      "Swords",
      "Bray",
      "Navan",
      "Ennis",
      "Tralee",
      "Carlow",
      "Kilkenny",
      "Naas",
      "Sligo",
      "Newbridge",
      "Mullingar",
      "Wexford",
      "Balbriggan",
      "Israel",
      "Jerusalem",
      "Tel Aviv",
      "Haifa",
      "Rishon LeZion",
      "Petah Tikva",
      "Ashdod",
      "Netanya",
      "Beer Sheva",
      "Bnei Brak",
      "Holon",
      "Bat Yam",
      "Ramat Gan",
      "Ashkelon",
      "Herzliya",
      "Kfar Saba",
      "Ra'anana",
      "Lod",
      "Nazareth",
      "Modi'in",
      "Italy",
      "Rome",
      "Milan",
      "Naples",
      "Turin",
      "Palermo",
      "Genoa",
      "Bologna",
      "Florence",
      "Bari",
      "Catania",
      "Venice",
      "Verona",
      "Messina",
      "Padua",
      "Trieste",
      "Taranto",
      "Brescia",
      "Parma",
      "Prato",
      "Modena",
      "Jamaica",
      "Kingston",
      "Montego Bay",
      "Spanish Town",
      "Portmore",
      "May Pen",
      "Mandeville",
      "Old Harbour",
      "Savanna-la-Mar",
      "Linstead",
      "Half Way Tree",
      "Ocho Rios",
      "Port Antonio",
      "Morant Bay",
      "Port Maria",
      "Falmouth",
      "Browns Town",
      "St. Ann's Bay",
      "Annotto Bay",
      "Black River",
      "Point Hill",
      "Japan",
      "Tokyo",
      "Yokohama",
      "Osaka",
      "Nagoya",
      "Sapporo",
      "Fukuoka",
      "Kobe",
      "Kyoto",
      "Kawasaki",
      "Saitama",
      "Hiroshima",
      "Sendai",
      "Kitakyushu",
      "Chiba",
      "Sakai",
      "Shizuoka",
      "Okayama",
      "Nagasaki",
      "Kumamoto",
      "Hamamatsu",
      "Jordan",
      "Amman",
      "Zarqa",
      "Irbid",
      "Aqaba",
      "Salt",
      "Mafraq",
      "Madaba",
      "Jerash",
      "Karak",
      "Ma'an",
      "Tafilah",
      "Ruseifa",
      "Deir Alla",
      "Safi",
      "Ajloun",
      "Qasabah",
      "Ar Ramtha",
      "Al Husn",
      "Al Jafr",
      "Al Azraq",
      "Kazakhstan",
      "Almaty",
      "Nur-Sultan (Astana)",
      "Shymkent",
      "Karaganda",
      "Aktobe",
      "Taraz",
      "Pavlodar",
      "Ust-Kamenogorsk",
      "Semey",
      "Kostanay",
      "Petropavl",
      "Oral",
      "Temirtau",
      "Atyrau",
      "Aktau",
      "Kyzylorda",
      "Taldykorgan",
      "Kokshetau",
      "Ekibastuz",
      "Rudny",
      "Kenya",
      "Nairobi",
      "Mombasa",
      "Kisumu",
      "Nakuru",
      "Eldoret",
      "Malindi",
      "Kitale",
      "Garissa",
      "Kakamega",
      "Kisii",
      "Thika",
      "Machakos",
      "Meru",
      "Nyeri",
      "Naivasha",
      "Lamu",
      "Busia",
      "Mandera",
      "Homa Bay",
      "Moyale",
      "Kiribati",
      "South Tarawa",
      "Betio",
      "Bairiki",
      "Bikenibeu",
      "Teinainano Urban Council",
      "Eita",
      "Antai",
      "Bonriki",
      "Korea, North",
      "Pyongyang",
      "Hamhung",
      "Chongjin",
      "Nampo",
      "Wonsan",
      "Sinuiju",
      "Tanchon",
      "Kaechon",
      "Sariwon",
      "Haeju",
      "Kanggye",
      "Hyesan",
      "Rason",
      "Songnim",
      "Manpo",
      "Chongju",
      "Korea, South",
      "Seoul",
      "Busan",
      "Incheon",
      "Daegu",
      "Daejeon",
      "Gwangju",
      "Suwon",
      "Ulsan",
      "Changwon",
      "Seongnam",
      "Goyang",
      "Yongin",
      "Bucheon",
      "Ansan",
      "Jeonju",
      "Cheongju",
      "Anyang",
      "Uijeongbu",
      "Gimhae",
      "Pohang",
      "Kuwait",
      "Kuwait City",
      "Ahmadi",
      "Hawalli",
      "As Salimiyah",
      "Sabah as Salim",
      "Al Farwaniyah",
      "Al Fahahil",
      "Ar Rumaythiyah",
      "Al Jahra",
      "Al Manqaf",
      "Ar Rabiyah",
      "Bayan",
      "Al Mahboula",
      "Mangaf",
      "Salmiya",
      "Jahra",
      "Fintas",
      "Wafra",
      "Adailiya",
      "Shamiya",
      "Kyrgyzstan",
      "Bishkek",
      "Osh",
      "Jalal-Abad",
      "Karakol",
      "Tokmok",
      "Balykchy",
      "Naryn",
      "Talas",
      "Kara-Balta",
      "Isfana",
      "Kyzyl-Kiya",
      "Kant",
      "Cholpon-Ata",
      "Tash-Kumyr",
      "Kara-Suu",
      "At-Bashi",
      "Batken",
      "Tash-Komur",
      "Kadamjay",
      "Sulukta",
      "Laos",
      "Vientiane",
      "Pakxe",
      "Savannakhet",
      "Luang Prabang",
      "Thakhek",
      "Xam Neua",
      "Phonsavan",
      "Muang Xay",
      "Muang Pakxan",
      "Vang Vieng",
      "Champasak",
      "Paksan",
      "Xaignabouli",
      "Vang Vieng",
      "Phongsaly",
      "Xam Nua",
      "Salavan",
      "Nong Khai",
      "Xayaboury",
      "Latvia",
      "Riga",
      "Daugavpils",
      "Liepaja",
      "Jelgava",
      "Jurmala",
      "Ventspils",
      "Rezekne",
      "Valmiera",
      "Ogre",
      "Cesis",
      "Tukums",
      "Salaspils",
      "Kuldiga",
      "Ozolnieki",
      "Talsi",
      "Sigulda",
      "Dobele",
      "Bauska",
      "Saldus",
      "Kraslava",
      "Lebanon",
      "Beirut",
      "Tripoli",
      "Sidon",
      "Tyre",
      "Byblos",
      "Jounieh",
      "Zahle",
      "Batroun",
      "Baabda",
      "Baalbek",
      "Aley",
      "Bint Jbeil",
      "Nabatieh",
      "Chekka",
      "Jiyeh",
      "Bhamdoun",
      "Mtein",
      "Mashghara",
      "Rashaya",
      "Hasbaya",
      "Lesotho",
      "Maseru",
      "Teyateyaneng",
      "Mafeteng",
      "Hlotse",
      "Mohale's Hoek",
      "Maputsoe",
      "Qacha's Nek",
      "Quthing",
      "Butha-Buthe",
      "Mokhotlong",
      "Thaba-Tseka",
      "Mafeteng",
      "Mokhotlong",
      "Quthing",
      "Qacha's Nek",
      "Liberia",
      "Monrovia",
      "Ganta",
      "Buchanan",
      "Gbarnga",
      "Kakata",
      "Harper",
      "Voinjama",
      "Zwedru",
      "Robertsport",
      "Fish Town",
      "Sanniquellie",
      "Barclayville",
      "Greenville",
      "Cestos City",
      "River Cess",
      "Pleebo",
      "Bopolu",
      "Tubmanburg",
      "Foya",
      "Libya",
      "Tripoli",
      "Benghazi",
      "Misratah",
      "Tarhuna",
      "Zawiya",
      "Al Khums",
      "Sirte",
      "Zliten",
      "Sabha",
      "Ajdabiya",
      "Al Marj",
      "Zuwara",
      "Ghat",
      "Murzuq",
      "Surt",
      "Sabha",
      "Derna",
      "Tobruk",
      "Yafran",
      "Nalut",
      "Liechtenstein",
      "Vaduz",
      "Schaan",
      "Balzers",
      "Triesen",
      "Eschen",
      "Mauren",
      "Ruggell",
      "Gamprin",
      "Schellenberg",
      "Planken",
      "Lithuania",
      "Vilnius",
      "Kaunas",
      "Klaipėda",
      "Šiauliai",
      "Panevėžys",
      "Alytus",
      "Marijampolė",
      "Mažeikiai",
      "Jonava",
      "Utena",
      "Kėdainiai",
      "Tauragė",
      "Telšiai",
      "Visaginas",
      "Šilutė",
      "Radviliškis",
      "Plungė",
      "Kretinga",
      "Palanga",
      "Rokiškis",
      "Luxembourg",
      "Luxembourg City",
      "Esch-sur-Alzette",
      "Differdange",
      "Dudelange",
      "Ettelbruck",
      "Diekirch",
      "Wiltz",
      "Echternach",
      "Rumelange",
      "Grevenmacher",
      "Remich",
      "Vianden",
      "Echternach",
      "Beaufort",
      "Mersch",
      "Redange",
      "Schengen",
      "Walferdange",
      "Strassen",
      "Sanem",
      "Macedonia",
      "Skopje",
      "Bitola",
      "Kumanovo",
      "Prilep",
      "Tetovo",
      "Veles",
      "Ohrid",
      "Gostivar",
      "Strumica",
      "Kavadarci",
      "Struga",
      "Kochani",
      "Kratovo",
      "Radoviš",
      "Štip",
      "Vinica",
      "Debar",
      "Negotino",
      "Sveti Nikole",
      "Kriva Palanka",
      "Madagascar",
      "Antananarivo",
      "Toamasina",
      "Antsirabe",
      "Fianarantsoa",
      "Mahajanga",
      "Toliara",
      "Antsiranana",
      "Antanifotsy",
      "Ambovombe",
      "Ambilobe",
      "Mananjary",
      "Morondava",
      "Manakara",
      "Ambanja",
      "Ambositra",
      "Maroantsetra",
      "Nosy Be",
      "Farafangana",
      "Fort Dauphin",
      "Tôlanaro",
      "Malawi",
      "Lilongwe",
      "Blantyre",
      "Mzuzu",
      "Zomba",
      "Kasungu",
      "Mangochi",
      "Karonga",
      "Salima",
      "Nkhotakota",
      "Liwonde",
      "Balaka",
      "Nsanje",
      "Rumphi",
      "Mchinji",
      "Mulanje",
      "Nkhata Bay",
      "Dedza",
      "Monkey Bay",
      "Machinga",
      "Chiradzulu",
      "Malaysia",
      "Kuala Lumpur",
      "George Town",
      "Johor Bahru",
      "Ipoh",
      "Shah Alam",
      "Petaling Jaya",
      "Kuching",
      "Kota Kinabalu",
      "Seremban",
      "Kuantan",
      "Malacca City",
      "Alor Setar",
      "Miri",
      "Taiping",
      "Sibu",
      "Kangar",
      "Kuala Terengganu",
      "Bintulu",
      "Padawan",
      "Sandakan",
      "Maldives",
      "Male",
      "Addu City",
      "Fuvahmulah",
      "Hithadhoo",
      "Kulhudhuffushi",
      "Eydhafushi",
      "Naifaru",
      "Thinadhoo",
      "Dhidhdhoo",
      "Dhuvaafaru",
      "Hulhumale",
      "Mahibadhoo",
      "Maradhoo",
      "Ungoofaaru",
      "Gan",
      "Velidhoo",
      "Meedhoo",
      "Thulusdhoo",
      "Veymandoo",
      "Maamigili",
      "Mali",
      "Bamako",
      "Sikasso",
      "Mopti",
      "Koutiala",
      "Kayes",
      "Segou",
      "Gao",
      "Kolokani",
      "Kati",
      "Bougouni",
      "Markala",
      "Douentza",
      "Niono",
      "Banamba",
      "Kidal",
      "Yelimane",
      "San",
      "Bafoulabe",
      "Bandiagara",
      "Kangaba",
      "Malta",
      "Valletta",
      "Birkirkara",
      "Mosta",
      "Qormi",
      "Sliema",
      "Zejtun",
      "Paola",
      "Rabat",
      "Fgura",
      "Gżira",
      "Żabbar",
      "Saint Paul's Bay",
      "Swieqi",
      "San Ġwann",
      "Mellieħa",
      "Żebbuġ",
      "Naxxar",
      "Birżebbuġa",
      "Victoria",
      "Marsaskala",
      "Marshall Islands",
      "Majuro",
      "Kwajalein",
      "Arno",
      "Jaluit",
      "Wotje",
      "Mili",
      "Ebon",
      "Namdrik",
      "Ailinglaplap",
      "Mejit",
      "Likiep",
      "Ailuk",
      "Wotho",
      "Ujae",
      "Jabor",
      "Taroa",
      "Mauritania",
      "Nouakchott",
      "Nouadhibou",
      "Kaédi",
      "Rosso",
      "Zouérat",
      "Atar",
      "Tidjikja",
      "Kiffa",
      "Aleg",
      "Akjoujt",
      "Tichit",
      "Boghé",
      "Selibaby",
      "Boutilimit",
      "Néma",
      "Maghama",
      "Bababé",
      "Kankossa",
      "Guérou",
      "Mauritius",
      "Port Louis",
      "Beau Bassin-Rose Hill",
      "Vacoas-Phoenix",
      "Curepipe",
      "Quatre Bornes",
      "Triolet",
      "Goodlands",
      "Centre de Flacq",
      "Bel Air Rivière Sèche",
      "Mahebourg",
      "Saint Pierre",
      "Le Hochet",
      "Montagne Blanche",
      "Long Mountain",
      "Pailles",
      "Albion",
      "L'Escalier",
      "Rivière des Anguilles",
      "Bambous",
      "Brisee Verdiere",
      "Mexico",
      "Mexico City",
      "Guadalajara",
      "Monterrey",
      "Puebla",
      "Tijuana",
      "Toluca",
      "León",
      "Ciudad Juárez",
      "Torreón",
      "San Luis Potosí",
      "Mérida",
      "Aguascalientes",
      "Hermosillo",
      "Saltillo",
      "Mexicali",
      "Culiacán",
      "Veracruz",
      "Cancún",
      "Chihuahua",
      "Querétaro",
      "Micronesia",
      "Palikir",
      "Weno",
      "Kolonia",
      "Kitti",
      "Nan Madol",
      "Tonoas",
      "Nukuoro",
      "Kapingamarangi",
      "Mokil",
      "Pingelap",
      "Lukunor",
      "Moldova",
      "Chisinau",
      "Tiraspol",
      "Bălți",
      "Bender",
      "Rîbnița",
      "Cahul",
      "Ungheni",
      "Soroca",
      "Orhei",
      "Comrat",
      "Edineț",
      "Ceadîr-Lunga",
      "Hîncești",
      "Strășeni",
      "Florești",
      "Dubăsari",
      "Vulcănești",
      "Nisporeni",
      "Rezina",
      "Monaco",
      "Monaco",
      "Monte Carlo",
      "La Condamine",
      "Fontvieille",
      "Mongolia",
      "Ulaanbaatar",
      "Erdenet",
      "Darkhan",
      "Choibalsan",
      "Khovd",
      "Ölgii",
      "Ulaangom",
      "Baruun-Urt",
      "Bayankhongor",
      "Dalandzadgad",
      "Mörön",
      "Altai",
      "Zuunmod",
      "Bulgan",
      "Tsagaannuur",
      "Tsetserleg",
      "Uvs",
      "Choir",
      "Mandalgovi",
      "Dzuunharaa",
      "Morocco",
      "Casablanca",
      "Rabat",
      "Fes",
      "Marrakesh",
      "Agadir",
      "Tangier",
      "Meknes",
      "Oujda",
      "Kenitra",
      "Tetouan",
      "Safi",
      "El Jadida",
      "Nador",
      "Settat",
      "Khouribga",
      "Beni Mellal",
      "Ouarzazate",
      "Berrechid",
      "Taourirt",
      "Larache",
      "Mozambique",
      "Maputo",
      "Matola",
      "Beira",
      "Nampula",
      "Chimoio",
      "Nacala",
      "Quelimane",
      "Tete",
      "Xai-Xai",
      "Maxixe",
      "Lichinga",
      "Pemba",
      "Dondo",
      "Angoche",
      "Inhambane",
      "Cuamba",
      "Montepuez",
      "Chokwé",
      "Macia",
      "Manjacaze",
      "Myanmar",
      "Yangon",
      "Mandalay",
      "Naypyidaw",
      "Mawlamyine",
      "Bago",
      "Pathein",
      "Monywa",
      "Sittwe",
      "Meiktila",
      "Taunggyi",
      "Myeik",
      "Pakokku",
      "Pyay",
      "Mergui",
      "Sagaing",
      "Dawei",
      "Thayetmyo",
      "Aunglan",
      "Magway",
      "Yenangyaung",
      "Namibia",
      "Windhoek",
      "Rundu",
      "Walvis Bay",
      "Oshakati",
      "Swakopmund",
      "Katima Mulilo",
      "Grootfontein",
      "Rehoboth",
      "Otjiwarongo",
      "Okahandja",
      "Gobabis",
      "Keetmanshoop",
      "Tsumeb",
      "Lüderitz",
      "Mariental",
      "Eenhana",
      "Ongwediva",
      "Ondangwa",
      "Usakos",
      "Karasburg",
      "Nauru",
      "Yaren District",
      "Denigomodu",
      "Meneng",
      "Buada District",
      "Anabar",
      "Aiwo",
      "Boe",
      "Baiti",
      "Ewa",
      "Nibok",
      "Uaboe",
      "Yaren",
      "Nepal",
      "Kathmandu",
      "Pokhara",
      "Patan",
      "Biratnagar",
      "Dharan",
      "Bharatpur",
      "Janakpur",
      "Butwal",
      "Hetauda",
      "Bhaktapur",
      "Birgunj",
      "Dhangadhi",
      "Itahari",
      "Nepalgunj",
      "Dhankuta",
      "Dipayal",
      "Ilam",
      "Jaleswar",
      "Kirtipur",
      "Lalitpur",
      "Netherlands",
      "Amsterdam",
      "Rotterdam",
      "The Hague",
      "Utrecht",
      "Eindhoven",
      "Tilburg",
      "Groningen",
      "Almere",
      "Breda",
      "Nijmegen",
      "Enschede",
      "Haarlem",
      "Arnhem",
      "Zaanstad",
      "Amersfoort",
      "Apeldoorn",
      "'s-Hertogenbosch",
      "Hoofddorp",
      "Maastricht",
      "Leiden",
      "New Zealand",
      "Auckland",
      "Wellington",
      "Christchurch",
      "Hamilton",
      "Tauranga",
      "Napier-Hastings",
      "Dunedin",
      "Palmerston North",
      "Nelson",
      "Rotorua",
      "Whangarei",
      "New Plymouth",
      "Invercargill",
      "Whanganui",
      "Gisborne",
      "Upper Hutt",
      "Lower Hutt",
      "Porirua",
      "Timaru",
      "Taupo",
      "Nicaragua",
      "Managua",
      "León",
      "Masaya",
      "Chinandega",
      "Matagalpa",
      "Granada",
      "Estelí",
      "Jinotega",
      "Juigalpa",
      "Rivas",
      "Bluefields",
      "Boaco",
      "Jinotepe",
      "Puerto Cabezas",
      "Corinto",
      "Somoto",
      "Nueva Guinea",
      "El Viejo",
      "San Marcos",
      "Ocotal",
      "Niger",
      "Niamey",
      "Zinder",
      "Maradi",
      "Agadez",
      "Tahoua",
      "Dosso",
      "Tillabéri",
      "Diffa",
      "Arlit",
      "Birni-N'Konni",
      "Gaya",
      "Dogondoutchi",
      "Mayahi",
      "Mirriah",
      "Ayorou",
      "Tessaoua",
      "Madaoua",
      "Illela",
      "Nguigmi",
      "Filingué",
      "Nigeria",
      "Lagos",
      "Kano",
      "Ibadan",
      "Benin City",
      "Port Harcourt",
      "Kaduna",
      "Jos",
      "Enugu",
      "Abuja",
      "Sokoto",
      "Onitsha",
      "Warri",
      "Zaria",
      "Aba",
      "Ilorin",
      "Ado Ekiti",
      "Maiduguri",
      "Makurdi",
      "Uyo",
      "Minna",
      "Norway",
      "Oslo",
      "Bergen",
      "Stavanger",
      "Trondheim",
      "Drammen",
      "Fredrikstad",
      "Kristiansand",
      "Sandnes",
      "Tromsø",
      "Sarpsborg",
      "Skien",
      "Ålesund",
      "Sandefjord",
      "Haugesund",
      "Tønsberg",
      "Moss",
      "Porsgrunn",
      "Bodø",
      "Arendal",
      "Hamar",
      "Oman",
      "Muscat",
      "Seeb",
      "Salalah",
      "Bawshar",
      "Sohar",
      "Sur",
      "Rustaq",
      "Nizwa",
      "Ibri",
      "Bahla",
      "Izki",
      "Bidbid",
      "Yanqul",
      "Adam",
      "Khasab",
      "Buraimi",
      "Manah",
      "Sama'il",
      "Al Hamra",
      "Barka",
      "Pakistan",
      "Karachi",
      "Lahore",
      "Faisalabad",
      "Rawalpindi",
      "Gujranwala",
      "Peshawar",
      "Multan",
      "Saidu Sharif",
      "Hyderabad",
      "Islamabad",
      "Quetta",
      "Sargodha",
      "Sialkot",
      "Bahawalpur",
      "Sukkur",
      "Larkana",
      "Sheikhupura",
      "Jhang",
      "Dera Ghazi Khan",
      "Gujrat",
      "Sahiwal",
      "Wah Cantonment",
      "Mardan",
      "Kasur",
      "Okara",
      "Mingora",
      "Nawabshah",
      "Mirpur Khas",
      "Chiniot",
      "Kamoke",
      "Burewala",
      "Jhelum",
      "Sadiqabad",
      "Kohat",
      "Jacobabad",
      "Shikarpur",
      "Khanpur",
      "Khuzdar",
      "Dera Ismail Khan",
      "Chaman",
      "Charsadda",
      "Mandi Bahauddin",
      "Ahmadpur East",
      "Vihari",
      "Gojra",
      "Tando Adam",
      "Khairpur",
      "Daska",
      "Dadu",
      "Mastung",
      "Abbottabad",
      "Bahawalnagar",
      "Malir Cantonment",
      "Lodhran",
      "Nowshera",
      "Swabi",
      "Turbat",
      "Chishtian",
      "Haripur",
      "Muzaffargarh",
      "Muridke",
      "Jaranwala",
      "Chakwal",
      "Kot Addu",
      "Hassan Abdal",
      "Gujar Khan",
      "Tando Allahyar",
      "Hafizabad",
      "Mianwali",
      "Kandhkot",
      "Tando Muhammad Khan",
      "Kamalia",
      "Umerkot",
      "Sanghar",
      "Pakpattan",
      "Toba Tek Singh",
      "Samundri",
      "Shahdadkot",
      "Shikarpur",
      "Mansehra",
      "Kotri",
      "Nankana Sahib",
      "Bhakkar",
      "Jatoi",
      "Pattoki",
      "Dipalpur",
      "Karor Lal Esan",
      "Ghotki",
      "Nushki",
      "Shujaabad",
      "Hujra Shah Muqim",
      "Khushab",
      "Mian Channu",
      "Jalalpur Jattan",
      "Sangla Hill",
      "Alipur",
      "Sarai Alamgir",
      "Hasilpur",
      "Narang Mandi",
      "Kot Malik",
      "Kalabagh",
      "Fort Abbas",
      "Fateh Jang",
      "Jand",
      "Darya Khan",
      "Wazirabad",
      "Hub",
      "Sibi",
      "Jalalpur Pirwala",
      "Gambat",
      "Hangu",
      "Jamshoro",
      "Khanewal",
      "Layyah",
      "Malakwal",
      "Narowal",
      "Talagang",
      "Tank",
      "Zhob",
      "Karak",
      "Kot Radha Kishan",
      "Kundian",
      "Lala Musa",
      "Mitha Tiwana",
      "Nankana Sahib",
      "Sambrial",
      "Sehwan",
      "Sujawal",
      "Takht Bhai",
      "Tandlianwala",
      "Thul",
      "Vihari",
      "Wana",
      "Zahir Pir",
      "Fazalpur",
      "Palau",
      "Ngerulmud",
      "Koror",
      "Melekeok",
      "Urdmang",
      "Airai",
      "Ngardmau",
      "Angaur",
      "Kloulklubed",
      "Ngerkeai",
      "Panama",
      "Panama City",
      "San Miguelito",
      "Tocumen",
      "David",
      "Arraiján",
      "Colon",
      "Las Cumbres",
      "La Chorrera",
      "Pedregal",
      "Chitré",
      "Santiago",
      "Veraguas",
      "Bocas del Toro",
      "Penonomé",
      "Aguadulce",
      "La Concepción",
      "Pueblo Nuevo",
      "Volcán",
      "Papua New Guinea",
      "Port Moresby",
      "Lae",
      "Arawa",
      "Mount Hagen",
      "Popondetta",
      "Madang",
      "Kokopo",
      "Mendi",
      "Kimbe",
      "Goroka",
      "Wewak",
      "Alotau",
      "Vanimo",
      "Kavieng",
      "Buka",
      "Daru",
      "Rabaul",
      "Kerema",
      "Kiunga",
      "Paraguay",
      "Asunción",
      "Ciudad del Este",
      "San Lorenzo",
      "Luque",
      "Capiatá",
      "Lambaré",
      "Fernando de la Mora",
      "Limpio",
      "Ñemby",
      "Encarnación",
      "Pedro Juan Caballero",
      "Villa Hayes",
      "Caaguazú",
      "Coronel Oviedo",
      "Itauguá",
      "Villarrica",
      "Caacupé",
      "Paraguarí",
      "Concepción",
      "San Juan Bautista",
      "Peru",
      "Lima",
      "Arequipa",
      "Trujillo",
      "Chiclayo",
      "Piura",
      "Iquitos",
      "Cusco",
      "Huancayo",
      "Tacna",
      "Chimbote",
      "Ica",
      "Pucallpa",
      "Juliaca",
      "Sullana",
      "Ayacucho",
      "Huaraz",
      "Tarapoto",
      "Puno",
      "Huaral",
      "Cajamarca",
      "Philippines",
      "Manila",
      "Quezon City",
      "Davao City",
      "Caloocan",
      "Cebu City",
      "Zamboanga City",
      "Taguig",
      "Pasig",
      "Antipolo",
      "Cagayan de Oro",
      "Parañaque",
      "Makati",
      "General Santos",
      "Bacoor",
      "Muntinlupa",
      "Iloilo City",
      "Baguio",
      "Batangas City",
      "San Jose del Monte",
      "Bacolod",
      "Poland",
      "Warsaw",
      "Kraków",
      "Łódź",
      "Wrocław",
      "Poznań",
      "Gdańsk",
      "Szczecin",
      "Bydgoszcz",
      "Lublin",
      "Białystok",
      "Katowice",
      "Gdynia",
      "Częstochowa",
      "Radom",
      "Sosnowiec",
      "Toruń",
      "Kielce",
      "Gliwice",
      "Zabrze",
      "Bytom",
      "Portugal",
      "Lisbon",
      "Porto",
      "Amadora",
      "Braga",
      "Coimbra",
      "Funchal",
      "Setúbal",
      "Almada",
      "Aveiro",
      "Vila Nova de Gaia",
      "Guimarães",
      "Evora",
      "Faro",
      "Viseu",
      "Rio Tinto",
      "Matosinhos",
      "Ponta Delgada",
      "Queluz",
      "Loures",
      "Odivelas",
      "Qatar",
      "Doha",
      "Al Rayyan",
      "Umm Salal",
      "Al Wakrah",
      "Al Khor",
      "Al Zubarah",
      "Dukhan",
      "Mesaieed",
      "Lusail",
      "Ras Laffan Industrial City",
      "Al Ruwais",
      "Romania",
      "Bucharest",
      "Cluj-Napoca",
      "Timișoara",
      "Iași",
      "Constanța",
      "Craiova",
      "Brașov",
      "Galați",
      "Ploiești",
      "Oradea",
      "Brăila",
      "Arad",
      "Pitești",
      "Sibiu",
      "Bacău",
      "Târgu Mureș",
      "Baia Mare",
      "Buzău",
      "Botoșani",
      "Satu Mare",
      "Russia",
      "Moscow",
      "Saint Petersburg",
      "Novosibirsk",
      "Yekaterinburg",
      "Nizhny Novgorod",
      "Kazan",
      "Chelyabinsk",
      "Omsk",
      "Samara",
      "Ufa",
      "Rostov-on-Don",
      "Krasnoyarsk",
      "Voronezh",
      "Perm",
      "Volgograd",
      "Krasnodar",
      "Saratov",
      "Tyumen",
      "Tolyatti",
      "Izhevsk",
      "Rwanda",
      "Kigali",
      "Butare",
      "Gitarama",
      "Ruhengeri",
      "Gisenyi",
      "Byumba",
      "Cyangugu",
      "Kibuye",
      "Kibungo",
      "Nyanza",
      "Rwamagana",
      "Kibeho",
      "Musanze",
      "Nyagatare",
      "Huye",
      "Karongo",
      "Rubavu",
      "Muhanga",
      "Nyamata",
      "Saint Kitts and Nevis",
      "Basseterre",
      "Charlestown",
      "Dieppe Bay Town",
      "Half Way Tree",
      "New Castle",
      "Sandy Point Town",
      "St. Paul’s",
      "Cayon",
      "Gingerland",
      "Tabernacle",
      "Saint Lucia",
      "Castries",
      "Vieux Fort",
      "Micoud",
      "Soufrière",
      "Dennery",
      "Gros Islet",
      "Laborie",
      "Anse La Raye",
      "Canaries",
      "Saint Vincent",
      "Kingstown",
      "Georgetown",
      "Byera Village",
      "Biabou",
      "Port Elizabeth",
      "Chateaubelair",
      "Barrouallie",
      "Dovers",
      "Dumfries",
      "Layou",
      "Samoa",
      "Apia",
      "Vaitele",
      "Faleula",
      "Siusega",
      "Malie",
      "Fasito'o Uta",
      "Nofoalii",
      "Leulumoega",
      "Lufilufi",
      "Faleasi'u",
      "San Marino",
      "City of San Marino",
      "Serravalle",
      "Borgo Maggiore",
      "Domagnano",
      "Fiorentino",
      "Acquaviva",
      "Chiesanuova",
      "Montegiardino",
      "Faetano",
      "Fiorentino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia and Montenegro",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "Johannesburg",
      "Cape Town",
      "Durban",
      "Pretoria",
      "Port Elizabeth",
      "Bloemfontein",
      "East London",
      "Polokwane",
      "Nelspruit",
      "Kimberley",
      "Pietermaritzburg",
      "Rustenburg",
      "Soweto",
      "George",
      "Vereeniging",
      "Vanderbijlpark",
      "Witbank",
      "Stellenbosch",
      "Richards Bay",
      "Middelburg",
      "Spain",
      "Madrid",
      "Barcelona",
      "Valencia",
      "Seville",
      "Zaragoza",
      "Málaga",
      "Murcia",
      "Palma",
      "Las Palmas de Gran Canaria",
      "Bilbao",
      "Alicante",
      "Córdoba",
      "Valladolid",
      "Vigo",
      "Gijón",
      "L'Hospitalet de Llobregat",
      "A Coruña",
      "Vitoria-Gasteiz",
      "Granada",
      "Elche",
      "Sri Lanka",
      "Colombo",
      "Dehiwala-Mount Lavinia",
      "Moratuwa",
      "Jaffna",
      "Negombo",
      "Pita Kotte",
      "Sri Jayawardenepura Kotte",
      "Kandy",
      "Trincomalee",
      "Kalmunai",
      "Galle",
      "Point Pedro",
      "Batticaloa",
      "Katunayake",
      "Valvedditturai",
      "Matara",
      "Battaramulla",
      "Dambulla",
      "Maharagama",
      "Kotikawatta",
      "Sudan",
      "Khartoum",
      "Omdurman",
      "Port Sudan",
      "Kassala",
      "Al-Ubayyid",
      "Gedaref",
      "Kosti",
      "Wad Madani",
      "El Fasher",
      "Nyala",
      "Ad-Damazin",
      "El Geneina",
      "Atbara",
      "Kadugli",
      "Singa",
      "Ed Dueim",
      "Zalingei",
      "Rabak",
      "Sennar",
      "Maiurno",
      "Suriname",
      "Paramaribo",
      "Lelydorp",
      "Nieuw Nickerie",
      "Moengo",
      "Nieuw Amsterdam",
      "Mariënburg",
      "Wageningen",
      "Albina",
      "Groningen",
      "Brownsweg",
      "Onverwacht",
      "Swaziland",
      "Mbabane",
      "Manzini",
      "Lobamba",
      "Siteki",
      "Piggs Peak",
      "Malkerns",
      "Nhlangano",
      "Hluti",
      "Mhlume",
      "Big Bend",
      "Sweden",
      "Stockholm",
      "Gothenburg",
      "Malmö",
      "Uppsala",
      "Västerås",
      "Örebro",
      "Linköping",
      "Helsingborg",
      "Jönköping",
      "Norrköping",
      "Lund",
      "Umeå",
      "Gävle",
      "Borås",
      "Södertälje",
      "Eskilstuna",
      "Halmstad",
      "Växjö",
      "Karlstad",
      "Täby",
      "Switzerland",
      "Zurich",
      "Geneva",
      "Basel",
      "Lausanne",
      "Bern",
      "Winterthur",
      "Lucerne",
      "St. Gallen",
      "Lugano",
      "Biel/Bienne",
      "Thun",
      "Köniz",
      "La Chaux-de-Fonds",
      "Fribourg",
      "Schaffhausen",
      "Chur",
      "Vernier",
      "Neuchâtel",
      "Uster",
      "Sion",
      "Syria",
      "Damascus",
      "Aleppo",
      "Homs",
      "Hama",
      "Latakia",
      "Deir ez-Zor",
      "Raqqa",
      "Hasakah",
      "Qamishli",
      "Daraa",
      "Idlib",
      "Tartus",
      "As-Suwayda",
      "Masyaf",
      "Salamiyah",
      "Darayya",
      "Manbij",
      "Yabroud",
      "Al-Qusayr",
      "Adra",
      "Taiwan",
      "Taipei",
      "New Taipei",
      "Kaohsiung",
      "Taichung",
      "Tainan",
      "Taoyuan",
      "Hsinchu",
      "Keelung",
      "Chiayi",
      "Changhua",
      "Pingtung",
      "Yilan",
      "Nantou",
      "Hualien City",
      "Taitung City",
      "Magong",
      "Douliu",
      "Hengchun",
      "Tajikistan",
      "Dushanbe",
      "Khujand",
      "Kulob",
      "Qurghonteppa",
      "Istaravshan",
      "Kofarnihon",
      "Tursunzoda",
      "Isfara",
      "Panjakent",
      "Konibodom",
      "Khorugh",
      "Vahdat",
      "Norak",
      "Vose",
      "Danghara",
      "Chkalovsk",
      "Farkhor",
      "Tanzania",
      "Dar es Salaam",
      "Dodoma",
      "Mwanza",
      "Zanzibar City",
      "Arusha",
      "Mbeya",
      "Morogoro",
      "Tanga",
      "Kahama",
      "Tabora",
      "Kigoma",
      "Moshi",
      "Songea",
      "Shinyanga",
      "Musoma",
      "Iringa",
      "Sumbawanga",
      "Njombe",
      "Bukoba",
      "Mtwara",
      "Thailand",
      "Bangkok",
      "Samut Prakan",
      "Nonthaburi",
      "Udon Thani",
      "Chon Buri",
      "Nakhon Ratchasima",
      "Chiang Mai",
      "Hat Yai",
      "Pak Kret",
      "Si Racha",
      "Phra Pradaeng",
      "Lampang",
      "Khon Kaen",
      "Surat Thani",
      "Nakhon Si Thammarat",
      "Ubon Ratchathani",
      "Nakhon Sawan",
      "Chiang Rai",
      "Rayong",
      "Phitsanulok",
      "Togo",
      "Lomé",
      "Sokodé",
      "Kara",
      "Palimé",
      "Atakpamé",
      "Bassar",
      "Tsevie",
      "Aneho",
      "Sansanné-Mango",
      "Dapaong",
      "Tonga",
      "Nuku'alofa",
      "Neiafu",
      "Haveluloto",
      "Vaini",
      "Pangai",
      "‘Ohonua",
      "Niutoua",
      "‘Eua",
      "Fonuamotu",
      "Kolonga",
      "Trinidad and Tobago",
      "Port of Spain",
      "San Fernando",
      "Chaguanas",
      "Arima",
      "Point Fortin",
      "Sangre Grande",
      "Tunapuna",
      "Scarborough",
      "Princes Town",
      "Diego Martin",
      "Tunisia",
      "Tunis",
      "Sfax",
      "Sousse",
      "Ettadhamen",
      "Kairouan",
      "Gabès",
      "Bizerte",
      "Aryanah",
      "Gafsa",
      "El Kef",
      "Mahdia",
      "La Goulette",
      "Ben Arous",
      "Monastir",
      "Hammamet",
      "Nabeul",
      "Jendouba",
      "Douz",
      "Zarzis",
      "Tozeur",
      "Turkey",
      "Istanbul",
      "Ankara",
      "Izmir",
      "Bursa",
      "Adana",
      "Gaziantep",
      "Konya",
      "Antalya",
      "Kayseri",
      "Mersin",
      "Eskisehir",
      "Diyarbakir",
      "Samsun",
      "Denizli",
      "Sanliurfa",
      "Malatya",
      "Kahramanmaras",
      "Erzurum",
      "Van",
      "Batman",
      "Turkmenistan",
      "Ashgabat",
      "Turkmenabat",
      "Dashoguz",
      "Mary",
      "Balkanabat",
      "Bayramaly",
      "Turkmenbashi",
      "Tejen",
      "Atamyrat",
      "Yoloten",
      "Tuvalu",
      "Funafuti",
      "Fongafale",
      "Nanumea",
      "Nanumanga",
      "Nui",
      "Nukufetau",
      "Nukulaelae",
      "Niutao",
      "Vaitupu",
      "Uganda",
      "Kampala",
      "Nansana",
      "Kira",
      "Mbarara",
      "Mukono",
      "Gulu",
      "Lira",
      "Jinja",
      "Bwizibwera",
      "Mbale",
      "Kasese",
      "Masaka",
      "Entebbe",
      "Njeru",
      "Hoima",
      "Fort Portal",
      "Soroti",
      "Kitgum",
      "Arua",
      "Iganga",
      "Ukraine",
      "Kyiv",
      "Kharkiv",
      "Odesa",
      "Dnipro",
      "Donetsk",
      "Zaporizhzhia",
      "Lviv",
      "Kryvyi Rih",
      "Mykolaiv",
      "Mariupol",
      "Luhansk",
      "Sevastopol",
      "Vinnytsia",
      "Simferopol",
      "Kherson",
      "Poltava",
      "Chernihiv",
      "Cherkasy",
      "Zhytomyr",
      "Sumy",
      "United Arab Emirates",
      "Dubai",
      "Abu Dhabi",
      "Sharjah",
      "Al Ain",
      "Ajman",
      "Ras Al Khaimah",
      "Fujairah",
      "Umm Al Quwain",
      "Khor Fakkan",
      "Dibba Al-Fujairah",
      "United Kingdom",
      "London",
      "Birmingham",
      "Manchester",
      "Glasgow",
      "Liverpool",
      "Bristol",
      "Sheffield",
      "Leeds",
      "Edinburgh",
      "Leicester",
      "Coventry",
      "Bradford",
      "Cardiff",
      "Belfast",
      "Stoke-on-Trent",
      "Wolverhampton",
      "Derby",
      "Swansea",
      "Southampton",
      "Salford",
      "United States",
      "New York",
      "Los Angeles",
      "Chicago",
      "Houston",
      "Phoenix",
      "Philadelphia",
      "San Antonio",
      "San Diego",
      "Dallas",
      "San Jose",
      "Austin",
      "Jacksonville",
      "Fort Worth",
      "Columbus",
      "Charlotte",
      "San Francisco",
      "Indianapolis",
      "Seattle",
      "Denver",
      "Washington D.C.",
      "Uruguay",
      "Montevideo",
      "Salto",
      "Ciudad de la Costa",
      "Paysandú",
      "Las Piedras",
      "Rivera",
      "Maldonado",
      "Tacuarembó",
      "Melo",
      "Mercedes",
      "Artigas",
      "Minas",
      "San José de Mayo",
      "Durazno",
      "Florida",
      "Treinta y Tres",
      "Rocha",
      "Canelones",
      "Pando",
      "Fray Bentos",
      "Uzbekistan",
      "Tashkent",
      "Samarkand",
      "Namangan",
      "Andijan",
      "Bukhara",
      "Nukus",
      "Qarshi",
      "Fergana",
      "Kokand",
      "Khiva",
      "Margilan",
      "Navoi",
      "Urganch",
      "Termez",
      "Jizzakh",
      "Shahrisabz",
      "Guliston",
      "Angren",
      "Bekabad",
      "Navoiy",
      "Vanuatu",
      "Port Vila",
      "Luganville",
      "Norsup",
      "Isangel",
      "Vatican City",
      "Venezuela",
      "Caracas",
      "Maracaibo",
      "Valencia",
      "Barquisimeto",
      "Ciudad Guayana",
      "Barcelona",
      "Maturín",
      "San Cristóbal",
      "Ciudad Bolívar",
      "Cumaná",
      "Maracay",
      "Barinas",
      "Los Teques",
      "Guatire",
      "Porlamar",
      "Acarigua",
      "Cabimas",
      "Coro",
      "El Tigre",
      "Puerto Cabello",
      "Guarenas",
      "Tinaquillo",
      "Punto Fijo",
      "Valera",
      "San Fernando de Apure",
      "San Felipe",
      "Vietnam",
      "Hanoi",
      "Ho Chi Minh City",
      "Hai Phong",
      "Da Nang",
      "Can Tho",
      "Bien Hoa",
      "Nha Trang",
      "Hue",
      "Vung Tau",
      "Quy Nhon",
      "Buon Ma Thuot",
      "Nam Dinh",
      "Phan Thiet",
      "Long Xuyen",
      "Thai Nguyen",
      "Thanh Hoa",
      "Vinh",
      "Rach Gia",
      "Thu Dau Mot",
      "Cam Ranh",
      "Ca Mau",
      "Tra Vinh",
      "Dong Hoi",
      "Ha Tinh",
      "Ben Tre",
      "Yemen",
      "Sana'a",
      "Aden",
      "Taiz",
      "Al Hudaydah",
      "Ibb",
      "Dhamar",
      "Al Mukalla",
      "Zabid",
      "Say'un",
      "Amran",
      "Sa'dah",
      "Al Bayda",
      "Marib",
      "Al Hazm",
      "Hajjah",
      "Zambia",
      "Lusaka",
      "Kitwe",
      "Ndola",
      "Kabwe",
      "Chingola",
      "Mufulira",
      "Luanshya",
      "Livingstone",
      "Kasama",
      "Chipata",
      "Kalulushi",
      "Mazabuka",
      "Mongu",
      "Kafue",
      "Chililabombwe",
      "Mansa",
      "Solwezi",
      "Kapiri Mposhi",
      "Monze",
      "Mpika",
      "Zimbabwe",
      "Harare",
      "Bulawayo",
      "Chitungwiza",
      "Mutare",
      "Gweru",
      "Kwekwe",
      "Kadoma",
      "Masvingo",
      "Chinhoyi",
      "Marondera",
      "Norton",
      "Chegutu",
      "Zvishavane",
      "Bindura",
      "Beitbridge",
      "Victoria Falls",
      "Hwange",
      "Redcliff",
      "Rusape",
      "Kariba",
    ],
  },
];
// export const locationData = [
//   {
//     id: 0,
//     label: "All Locations",
//     children: [
//       "United States",
//   "Alabama",
//   "Alabama, US",
//   "Alaska",
//   "Alaska, US",
//   "Arizona",
//   "Arizona, US",
//   "Arizona City, Arizona",
//   "Arkansas, US",
//   "California",
//   "California, Maryland",
//   "California, Missouri",
//   "California, Pennsylvania",
//   "California, US",
//   "Colorado, US",
//   "Connecticut, US",
//   "Delaware, US",
//   "Florida",
//   "Florida, New York",
//   "Florida, Argentina",
//   "Florida, Bolivia",
//   "Florida, US",
//   "Georgia, Europe",
//   "Georgia, US",
//   "Georgia, Alabama",
//   "Hawaii",
//   "Hawaii, US",
//   "Idaho, US",
//   "Illinois, US",
//   "Indiana",
//   "Indiana, Pennsylvania",
//   "Indiana, US",
//   "Iowa, US",
//   "Kansas, US",
//   "Kentucky, US",
//   "Louisiana, US",
//   "Maine, US",
//   "Maryland, US",
//   "Massachusetts, US",
//   "Michigan, US",
//   "Minnesota, US",
//   "Mississippi",
//   "Mississippi, US",
//   "Missouri, US",
//   "Montana, US",
//   "Nebraska, US",
//   "Nevada, US",
//   "New Hampshire, US",
//   "New Jersey",
//   "New Jersey, US",
//   "New Mexico, US",
//   "New York, US",
//   "North Carolina, US",
//   "North Dakota, US",
//   "Ohio, US",
//   "Oklahoma, US",
//   "Oregon, US",
//   "Pennsylvania, US",
//   "Rhode Island, US",
//   "South Carolina, US",
//   "South Dakota, US",
//   "Tennessee, US",
//   "Texas",
//   "Texas, US",
//   "Utah, US",
//   "Vermont, US",
//   "Virginia",
//   "Virginia, Minnesota",
//   "Virginia, Illinois",
//   "Virginia, Australia",
//   "Virginia, US",
//   "Washington, US",
//   //   "Washington D.C.",
//   "Washington, District of Columbia",
//   "Washington, District of Columbia",

//   "West Virginia, US",
//   "Wisconsin, US",
//   "Wyoming, US",
//   "District of Columbia, US",
//   "American Samoa",
//   "Clermont, Florida",
//   "Guam, US",
//   "Puerto Rico, US",
//   "Virgin Islands, US",

//   // grp -> US Metro Areas
//   "Monsey, New York",
//   "Anchorage, Alaska",
//   "Fairbanks, Alaska",
//   "Fairbanks, Indiana",
//   "Birmingham",
//   "Birmingham, United Kingdom",
//   "Birmingham, Iowa",
//   "Birmingham, Alabama",
//   "Anniston, Alabama",
//   "Dothan, Alabama",
//   "Huntsville, Alabama",
//   "Mobile, Alabama",
//   "Montgomery, Alabama",
//   "Tuscaloosa, Alabama",
//   "Fort Smith, Arkansas",
//   "Jonesboro, Arkansas",
//   "Phoenix, Arizona",
//   "Eureka",
//   "Eureka, Missouri",
//   "Eureka, Illinois",
//   "Eureka, Montana",
//   "Eureka, South Dakota",
//   "Eureka, Kansas",
//   "Eureka, California",
//   "Fresno, California",
//   "Irvine, California",
//   "Little Rock, Arkansas",
//   "Tucson, Arizona",
//   "Yuma, Arizona",
//   "Bakersfield, California",
//   "Chico, California",
//   "Eureka, California",
//   "Fresno, California",
//   "Los Angeles, California",
//   "Monterey, California",
//   // "Irvine, California",
//   "Palm Springs, California",
//   "Sacramento, California",
//   "San Diego, California",
//   "San Francisco, California",
//   "San Jose, California",
//   "Santa Barbara, California",
//   "Denver, Colorado",
//   "Fort Myers, Florida",
//   "Colorado Springs, Colorado",
//   "Grand Junction, Colorado",
//   "Hartford, Connecticut",
//   "Washington",
//   "Washington, Pennsylvania",
//   "Washington, Michigan",
//   "Washington, Missouri",
//   "Washington, New Jersey",
//   "Washington, Indiana",
//   "Washington, Virginia",
//   "Washington, United Kingdom",
//   "Washington, North Carolina",
//   "Washington, Illinois",
//   "Washington, Maine",
//   "Washington, Iowa",
//   "Washington, Utah",
//   "Washington, Kansas",
//   "Washington, California",
//   "Washington D.C. Metro Area",
//   "Gainesville, Florida",
//   "Jacksonville, Florida",
//   "Miami, Florida",
//   "Orlando, Florida",
//   "Panama City",
//   "Panama City, Panama",
//   "Panama City, Florida",
//   "Tallahassee, Florida",
//   "Tampa, Florida",
//   "West Palm Beach, Florida",
//   "Albany, Georgia",
//   "Atlanta",
//   "Atlanta, Illinois",
//   "Atlanta, Texas",
//   "Atlanta, Nebraska",
//   "Atlanta, Idaho",
//   "Atlanta, Missouri",
//   "Atlanta, Indiana",
//   "Atlanta, Georgia",
//   "Augusta, Georgia",
//   "Columbus, Georgia",
//   "Macon, Georgia",
//   "Savannah, Georgia",
//   "Honolulu, Hawaii",
//   "Cedar Rapids, Iowa",
//   "Davenport, Iowa",
//   "Des Moines, Iowa",
//   "Ottumwa, Iowa",
//   "Sioux City, Iowa",
//   "Boise, Idaho",
//   "Idaho Falls, Idaho",
//   "Twin Falls, Idaho",
//   "Chicago",
//   "Chicago, Illinois",
//   "Peoria, Illinois",
//   "Quincy, Illinois",
//   "Lafayette, Indiana",
//   "Topeka, Kansas",
//   "Rockford, Illinois",
//   "Springfield, Illinois",
//   "Evansville, Indiana",
//   "Fort Wayne, Indiana",
//   "Indianapolis, Indiana",
//   "South Bend, Indiana",
//   "Terre Haute, Indiana",
//   "Wichita, Kansas",
//   "Bowling Green, Kentucky",
//   "Lexington, Kentucky",
//   "Louisville, Kentucky",
//   "Paducah, Kentucky",
//   "Alexandria, Louisiana",
//   "Baton Rouge, Louisiana",
//   "Lafayette, Louisiana",
//   "Lake Charles, Louisiana",
//   "Monroe, Louisiana",
//   "New Orleans, Louisiana",
//   "Shreveport, Louisiana",
//   "Boston",
//   "Boston, United Kingdom",
//   "Boston, New York",
//   "Boston, Indiana",
//   "Boston, Massachusetts",
//   "Baltimore, Maryland",
//   "Presque lsle, Maine",
//   "Springfield, Massachusetts",
//   "Salisbury, Maryland",
//   "Havre de Grace, Maryland",
//   "Baltimore, Maryland",
//   "Eldersburg, Maryland",
//   "Bangor, Maine",
//   "Portland",
//   "Portland, Oregon",
//   "Portland, Tennessee",
//   "Portland, Indiana",
//   "Portland, Texas",
//   "Portland, Connecticut",
//   "Portland, United Kingdom",
//   "Portland, Michigan",
//   "Portland, Missouri",
//   "Portland, Pennsylvania",
//   "Portland, Ohio",
//   "Portland, Arkansas",
//   "Portland, Maine",
//   "Alpena, Michigan",
//   "Detroit, Michigan",
//   "Flint Township, Michigan",
//   "Grand Rapids, Michigan",
//   "Lansing, Michigan",
//   "Marquette, Michigan",
//   "Traverse City, Michigan",
//   "Duluth, Minnesota",
//   "Mankato, Minnesota",
//   "Columbus, Mississippi",
//   "Minneapolis, Minnesota",
//   "Rochester, Minnesota",
//   "Jefferson City, Missouri",
//   "Joplin, Missouri",
//   "Kansas City, Missouri",
//   "Saint Joseph, Missouri",
//   "Saint Louis, Missouri",
//   "Springfield, Missouri",
//   "Biloxi, Mississippi",
//   "Greenville, Mississippi",
//   "Hattiesburg, Mississippi",
//   "Jackson, Mississippi",
//   "Meridian, Mississippi",
//   "Billings, Montana",
//   "Butte, Montana",
//   "Glendive, Montana",
//   "Great Falls, Montana",
//   "Helena, Montana",
//   "Missoula, Montana",
//   "Charlotte, North Carolina",
//   "Greenville, North Carolina",
//   "Raleigh, North Carolina",
//   "Wilmington, North Carolina",
//   "Winston-Salem, North Carolina",
//   "Winston-Salem, North Carolina",

//   "Bismarck, North Dakota",
//   "Fargo, North Dakota",
//   "Lincoln, Nebraska",
//   "North Platte, Nebraska",
//   "Omaha, Nebraska",
//   "Albuquerque, New Mexico",
//   "Las Vegas, Nevada",
//   "Reno, Nevada",
//   "Albany, New York",
//   "Binghamton, New York",
//   "Buffalo, New York",
//   "Elmira, New York",
//   "New York, New York",
//   "Rochester, New York",
//   "Syracuse, New York",
//   "Utica, New York",
//   "Watertown, New York",
//   "Cincinnati, Ohio",
//   "Cleveland, Ohio",
//   "Columbus, Ohio",
//   "Dayton, Ohio",
//   "Lima, Peru",
//   "Toledo, Ohio",
//   "Wheeling, West Virginia",
//   "Youngstown, Ohio",
//   "Zanesville, Ohio",
//   "Ardmore, Oklahoma",
//   "Oklahoma City, Oklahoma",
//   "Tulsa, Oklahoma",
//   "Eugene, Oregon",
//   "Erie, Pennsylvania",
//   "Johnstown, Pennsylvania",
//   "Pittsburgh, Pennsylvania",
//   "Wilkes-Barre, Pennsylvania",
//   "Mayagüez, Puerto Rico",
//   "Ponce, Puerto Rico",
//   "Providence, Rhode Island",
//   "Columbia, South Carolina",
//   "Florence, South Carolina",
//   "Bend, Oregon",
//   "Medford, Oregon",
//   "Portland, Oregon",
//   "Harrisburg, Pennsylvania",
//   "Philadelphia, Pennsylvania",
//   "Aguadilla, Puerto Rico",
//   "San Juan, Puerto Rico",
//   "Charleston, South Carolina",
//   "Greenville, South Carolina",
//   "Rapid City, South Dakota",
//   "Sioux Falls, South Dakota",
//   "Chattanooga, Tennessee",
//   "Jackson, Tennessee",
//   "Knoxville, Tennessee",
//   "Memphis, Tennessee",
//   "Nashville, Tennessee",
//   //   "Tri Cities, Washington",
//   "Abilene, Texas",
//   "Amarillo, Texas",
//   "Austin, Texas",
//   "Beaumont, Texas",
//   "Bryan, Texas",
//   "Corpus Christi, Texas",
//   "Dallas, Texas",
//   "El Paso, Texas",
//   "Harlingen, Texas",
//   "Houston, Texas",
//   "Laredo, Texas",
//   "Lubbock, Texas",
//   "Odessa, Texas",
//   "San Antonio, Texas",
//   "Tyler, Texas",
//   "Wichita Falls, Texas",
//   "San Angelo, Texas",
//   "Victoria, Texas",
//   "Hampton, Virginia",
//   "Roanoke, Virginia",
//   "Salt Lake City, Utah",
//   "Charlottesville, Virginia",
//   "Harrisonburg, Virginia",
//   "Richmond, Virginia",
//   "Burlington, Vermont",
//   "Seattle, Washington",
//   "Spokane, Washington",
//   //   "Tri Cities Area",
//   "Green Bay, Wisconsin",
//   "La Crosse, Wisconsin",
//   "Madison, Wisconsin",
//   "Milwaukee, Wisconsin",
//   "Wausau, Wisconsin",
//   "Bluefield, West Virginia",
//   "Charleston, West Virginia",
//   "Clarksburg, West Virginia",
//   //   "Parkersburg, West Virginia",
//   "Casper, Wyoming",
//   "Cheyenne, Wyoming",
//   "Canada",
//   "Alberta",
//   "Alberta, Minnesota",
//   "Alberta, Canada",
//   "British Columbia, Canada",
//   "Manitoba, Canada",
//   "New Brunswick, Canada",
//   "Newfoundland and Labrador, Canada",
//   "Newfoundland and Labrador, Canada",

//   "Northwest Territories, Canada",
//   "Northwest Territories, Canada",

//   "Nova Scotia, Canada",
//   "Nunavut, Canada",
//   "Ontario, Canada",
//   "Prince Edward Island, Canada",
//   "Prince Edward Island, Canada",

//   "Quebec, Canada",
//   "Saskatchewan, Canada",
//   "Yukon Territory, Canada",
//   "Calgary, Canada",
//   "Edmonton, Canada",
//   "Abbotsford, Canada",
//   "Kelowna, Canada",
//   "Victoria",
//   "Victoria, Australia",
//   "Victoria, Illinois",
//   "Victoria, Romania",
//   "Victoria, Minnesota",
//   "Victoria, Mississippi",
//   "Victoria, Canada",
//   "Winnipeg, Canada",
//   "Moncton, Canada",
//   "St. John's, Canada",
//   "St. Albert, Canada",
//   "Halifax, Canada",
//   "Barrie, Canada",
//   "London, Canada",
//   "London, United Kingdom",
//   "London, Kentucky",
//   "London, Ohio",
//   "Londonderry, Vermont",
//   "London, Texas",
//   "London Mills, Illinois",
//   "London, Oregon",
//   "London, West Virginia",
//   "London, California",
//   "London, Arkansas",
//   "Ottawa, Canada",
//   "Vancouver, Canada",
//   "Yellowknife, Canada",
//   "Brantford, Canada",
//   "Guelph, Canada",
//   "Hamilton, Canada",
//   "Kingston, Canada",
//   "Kitchener, Canada",
//   "Peterborough, Canada",
//   "Saint Catharines, Canada",
//   "Sudbury, Canada",
//   "Thunder Bay, Canada",
//   "Toronto, Canada",
//   "Windsor, Canada",
//   "Charlottetown, Canada",
//   "Saguenay, Canada",
//   "Montreal, Canada",
//   "Quebec, Canada",
//   "Sherbrooke, Canada",
//   "Trois-Rivières, Canada",
//   "Regina, Canada",
//   "Saskatoon, Canada",
//   "Africa",
//   "Algeria",
//   "Angola",
//   "Benin",
//   "Botswana",
//   "Burkina Faso",
//   "Burundi",
//   "Cameroon",
//   "Cape Verde",
//   "Central African Republic",
//   "Chad",
//   "Comoros",
//   "Moroni, Comoros",
//   "Ngazidja, Comoros",
//   "Nzwani, Comoros",
//   "Democratic Republic of the Congo",
//   "Republic of the Congo",
//   "Djibouti",
//   "Djibouti, Djibouti",
//   "Egypt",
//   "Equatorial Guinea",
//   "Eritrea",
//   "Asmara, Eritrea",
//   "Ethiopia",
//   "Gabon",
//   "The Gambia",
//   "Serrekunda, The Gambia",
//   "Banjul, The Gambia",
//   "West Coast Region, The Gambia",
//   "West Coast Region, The Gambia",

//   "Serrekunda, The Gambia",
//   "Ghana",
//   "Guinea",
//   "Conakry, Guinea",
//   "Kamsar, Guinea",
//   "Guinea-Bissau",
//   "Bissau, Guinea-Bissau",
//   "Kenya",
//   "Lesotho",
//   "Liberia",
//   "Libya",
//   "Madagascar",
//   "Malawi",
//   "Mali",
//   "Mauritania",
//   "Mauritius",
//   "Morocco",
//   "Mozambique",
//   "Namibia",
//   "Niger",
//   "Nigeria",
//   "Rwanda",
//   "São Tomé, São Tomé and Príncipe",
//   "São Tomé, São Tomé and Príncipe",

//   "São Tomé and Príncipe",
//   "Senegal",
//   "Seychelles",
//   "Sierra Leone",
//   "Somalia",
//   "South Africa",
//   "Cape Town, South Africa",
//   "Sudan",
//   "Swaziland",
//   "Tanzania",
//   "Togo",
//   "Tunisia",
//   "Uganda",
//   "Zambia",
//   "Asia",
//   "Afghanistan",
//   "Armenia",
//   "Azerbaijan",
//   "Bahrain",
//   "Bangladesh",
//   "Bhutan",
//   "Brunei",
//   "Cambodia",
//   "China",
//   "Georgia",
//   "India",
//   "Indonesia",
//   "Iran",
//   "Israel",
//   "Japan",
//   "Jordan",
//   "Kazakhstan",
//   "Kuwait",
//   "Kyrgyzstan",
//   "Laos",
//   "Lebanon",
//   "Malaysia",
//   "Maldives",
//   "Mongolia",
//   "Myanmar",
//   "United Arab Emirates",
//   "Sindo, North Korea",
//   "Pyongyang, North Korea",
//   "Kangwon, North Korea",
//   "North Korea",
//   "Oman",
//   "Pakistan",
//   "Philippines",
//   "Qatar",
//   "Saudi Arabia",
//   "Singapore",
//   "South Korea",
//   "Sri Lanka",
//   "Syria",
//   "Taiwan",
//   "Tajikistan",
//   "Europe",
//   "Albania",
//   "Andorra",
//   "Austria",
//   "Belarus",
//   "Belgium",
//   "Belgium, Wisconsin",
//   "Bosnia and Herzegovina",
//   "Bulgaria",
//   "Croatia",
//   "Cyprus",
//   "Czech Republic",
//   "Denmark",
//   "Denmark, South Carolina",
//   "Denmark, Iowa",
//   "Estonia",
//   "Finland",
//   "Finland, Minnesota",
//   "France",
//   "Germany",
//   "Greece",
//   "Hungary",
//   "Iceland",
//   "Ireland",
//   "Italy",
//   "Italy, Texas",
//   "Latvia",
//   "Liechtenstein",
//   "Lithuania",
//   "Luxembourg",
//   "Macedonia",
//   "Macedonia, Illinois",
//   "Macedonia, Ohio",
//   "Macedonia (FYROM)",
//   "Malta",
//   "Moldova",
//   "Monaco",
//   "Netherlands",
//   "Norway",
//   "Norway, Iowa",
//   "Norway, Maine",
//   "Poland",
//   "Poland, Indiana",
//   "Poland, Maine",
//   "Poland, New York",
//   "Portugal",
//   "Romania",
//   "Brașov, Romania",
//   "Sibiu, Romania",
//   "Bucharest, Romania",
//   "Russia",
//   "Russia, Ohio",
//   "San Marino",
//   "San Marino, California",
//   "San Marino, Italy",
//   "Slovakia",
//   "Slovenia",
//   "Spain",
//   "Sweden",
//   "Switzerland",
//   "Ukraine",
//   "United Kingdom",
//   "Swindon, United Kingdom",
//   "North America",
//   "Antigua",
//   "The Bahamas",
//   "Barbados",
//   "Belize",
//   "Costa Rica",
//   "Cuba",
//   "Cuba, Missouri",
//   "Cuba, Illinois",
//   "Cuba, New York",
//   "Cuba, Alabama",
//   "Cuba, New Mexico",
//   "Dominica",
//   "Dominican Republic",
//   "El Salvador",
//   "Grenada",
//   "Grenada, Mississippi",
//   "Guatemala",
//   "Haiti",
//   "Honduras",
//   "Jamaica",
//   "Jamaica, Mexico",
//   "Jamaica, Iowa",
//   "Mexico",
//   "Mexico, Maine",
//   "Mexico, Missouri",
//   "Mexico, New York",
//   "Mexico, Indiana",
//   "Nicaragua",
//   "Panama",
//   "Saint Kitts and Nevis",
//   "Saint Vincent and the Grenadines",
//   "Saint Vincent and the Grenadines",

//   "Trinidad and Tobago",
//   // "Oceania" ,
//   "Australia",
//   "Cook Islands",
//   "Fiji",
//   "Kiribati",
//   "New Zealand",
//   "Papua New Guinea",
//   "Samoa",
//   "Solomon Islands",
//   "Timor-Leste",
//   "Tonga",
//   "Vanuatu",
//   "South America",
//   "Argentina",
//   "Argentine, France",
//   "Bolivia",
//   "Brazil",
//   "Brazil, Indiana",
//   "Chile",
//   "Colombia",
//   "Ecuador",
//   "Guyana",
//   "Paraguay",
//   "Peru",
//   "Peru, Illinois",
//   "Peru, Indiana",
//   "Suriname",
//   "Uruguay",
//   "Venezuela",
//   "Lancaster", 
//   "Lancaster, Texas",
//   "Lancaster, Minnesota",
//   // "Lancaster, VIC, AU",
//   "Lancaster, California",
//   // "South Lancaster, ON, CA",
//     ],
//   }
// ];
export const locations = [
  "US > States",
  "Alabama, US",
  "Alaska, US",
  "Arizona, US",
  "Arkansas, US",
  "California, US",
  "Colorado, US",
  "Connecticut,",
  "Delaware, US",
  "Florida, US",
  "Georgia, US",
  "Hawaii, US",
  "Idaho, US",
  "Illinois, US",
  "Indiana, US",
  "lowa, US",
  "Kansas, US",
  "Kentucky, US",
  "Louisiana, US",
  "Maine, US",
  "Maryland, US",
  "Massachusetts, US",
  "Michigan, US",
  "Minnesota, US",
  "Mississippi, US",
  "Missouri, US",
  "Montana, US",
  "Nebraska, US",
  "Nevada, US",
  "New Hampshire, US",
  "New Jersey, US",
  "New Mexico, US",
  "New York, US",
  "North Carolina, US",
  "North Dakota, US",
  "Ohio, US",
  "Oklahoma, US",
  "Oregon, US",
  "Pennsylvania, US",
  "Rhode Island, US",
  "South Carolina, US",
  "South Dakota, US",
  "Tennessee, US",
  "Texas, US",
  "Utah, US",
  "Vermont, US",
  "Virginia, US",
  "Washington, US",
  "West Virginia, US",
  "Wisconsin, US",
  "Wyoming, US",
  "District of Columbia",
  "American Samoa",
  "Canal Zone",
  "Guam",
  "Puerto Rico",
  "Virgin Islands",
  "US Metro Areas (217)",
  "AK > Anchorage Area",
  "AK > Fairbanks Area",
  "AL > Birmingham Area",
  "AL> Anniston Area",
  "AL > Dothan Area",
  "AL > Huntsville Area",
  "AL > Mobile Area",
  "AL > Montgomery Area",
  "AL > Tuscaloosa Area",
  "AR > Fort Smith Area",
  "AR > Jonesboro Area",
  "AZ > Phoenix Area",
  "CA > Eureka Area",
  "CA > Fresno Area",
  "CA > Irvine Area",
  "AR > Little Rock Area",
  "AZ > Tucson Area",
  "AZ > Yuma Area",
  "CA > Bakersfield Area",
  "CA > Chico Area",
  "CA > Eureka Area",
  "CA > Fresno Area",
  "CA> Los Angeles Area",
  "CA > Monterey Bay Area",
  "CA > Irvine Area",
  "CA> Palm Springs Area",
  "CA > Sacramento Area",
  "CA> San Diego Area",
  "CA > San Francisco Area",
  "CA > San Jose Area",
  "CA > Santa Barbara Area",
  "CO > Denver Area",
  "FL> Fort Myers Area",
  "CO Colorado Springs Area",
  "CO > Grand Junction Area",
  "CT > Hartford Area",
  "DC > Washington Area",
  "FL > Gainesville Area",
  "FL > Jacksonville Area",
  "FL Miami Area",
  "FL> Orlando Area",
  "FL> Panama City Area",
  "FL> Tallahassee Area",
  "FL> Tampa Bay Area",
  "FL> West Palm Beach Area",
  "GA> Albany Area",
  "GA > Atlanta Area",
  "GA> Augusta Area",
  "GA > Columbus Area",
  "GA> Macon Area",
  "GA > Savannah Area",
  "HI > Honolulu Area",
  "IA > Cedar Rapids Area",
  "IA > Davenport Area",
  "IA > Des Moines Area",
  "IA > Ottumwa Area",
  "IA > Sioux City Area",
  "ID > Boise City Area",
  "ID > Idaho Falls Area",
  "ID> Twin Falls Area",
  "IL > Chicago Area",
  "IL > Peoria Area",
  "IL> Quincy Area",
  "IN > Lafayette Area",
  "KS > Topeka Area",
  "IL > Rockford Area",
  "IL > Springfield Area",
  "IN > Evansville Area",
  "IN > Fort Wayne Area",
  "IN > Indianapolis Area",
  "IN > South Bend Area",
  "IN > Terre Haute Area",
  "KS > Wichita Area",
  "KY > Bowling Green Area",
  "KY > Lexington Area",
  "KY > Louisville Area",
  "KY > Paducah Area",
  "LA > Alexandria Area",
  "LA > Baton Rouge Area",
  "LA > Lafayette Area",
  "LA > Lake Charles Area",
  "LA > Monroe Area",
  "LA > New Orleans Area",
  "LA > Shreveport Area",
  "MA > Boston Area",
  "MD > Baltimore Area",
  "ME > Presquelsle Area",
  "MA > Springfield Area",
  "MD > Salisbury Area",
  "ME > Bangor Area",
  "ME > Portland Area",
  "MI > Alpena Area",
  "MI > Detroit Area",
  "MI > Flint Area",
  "MI > Grand Rapids Area",
  "MI > Lansing Area",
  "MI> Marquette Area",
  "MI > Traverse City Area",
  "MN > Duluth Area",
  "MN > Mankato Area",
  "MS > Columbus Area",
  "MN > Minneapolis Area",
  "MN > Rochester Area",
  "MO > Jefferson City Area",
  "MO > Joplin Area",
  "MO> Kansas City Area",
  "MO > Saint Joseph Area",
  "MO > Saint Louis Area",
  "MO > Springfield Area",
  "MS > Biloxi Area",
  "MS > Greenville Area",
  "MS > Hattiesburg Area",
  "MS > Jackson Area",
  "MS > Meridian Area",
  "MT > Billings Area",
  "MT > Butte Area",
  "MT > Glendive Area",
  "MT > Great Falls Area",
  "MT > Helena Area",
  "MT > Missoula Area",
  "NC > Charlotte Area",
  "NC > Greenville Area",
  "NC > Raleigh Area",
  "NC > Wilmington Area",
  "NC > Winston Salem Area",
  "ND > Bismarck Area",
  "ND > Fargo Area",
  "NE > Lincoln Area",
  "NE> North Platte Area",
  "NE > Omaha Area",
  "NM > Albuquerque Area",
  "NV > Las Vegas Area",
  "NV > Reno Area",
  "NY > Albany Area",
  "NY > Binghamton Area",
  "NY > Buffalo Area",
  "NY > Elmira Area",
  "NY > New York Area",
  "NY > Rochester Area",
  "NY > Syracuse Area",
  "NY > Utica Area",
  "NY > Watertown Area",
  "OH > Cincinnati Area",
  "OH > Cleveland Area",
  "OH > Columbus Area",
  "OH > Dayton Area",
  "OH > Lima Area",
  "OH > Toledo Area",
  "OH > Wheeling Area",
  "OH > Youngstown Area",
  "OH > Zanesville Area",
  "OK > Ardmore Area",
  "OK Oklahoma City Area",
  "OK > Tulsa Area",
  "OR > Eugene Area",
  "PA > Erie Area",
  "PA > Johnstown Area",
  "PA > Pittsburgh Area",
  "PA > Wilkes Barre Area",
  "PR Mayaguez Area",
  "PR > Ponce Area",
  "RI > Providence Area",
  "SC > Columbia Area",
  "SC> Florence Area",
  "OR > Bend Area",
  "OR > Medford Area",
  "OR > Portland Area",
  "PA> Harrisburg Area",
  "PA > Philadelphia Area",
  "PR > Aguadilla Area",
  "PR > San Juan Area",
  "SC > Charleston Area",
  "SC> Greenville Area",
  "SD > Rapid City Area",
  "SD > Sioux Falls Area",
  "TN> Chattanooga Area",
  "TN > Jackson Area",
  "TN > Knoxville Area",
  "TN > Memphis Area",
  "TN > Nashville Area",
  "TN> Tri Cities Area",
  "TX > Abilene Area",
  "TX > Amarillo Area",
  "TX > Austin Area",
  "TX > Beaumont Area",
  "TX > Bryan Area",
  "TX > Corpus Christi Area",
  "TX > Dallas Area",
  "TX > El Paso Area",
  "TX> Harlingen Area",
  "TX > Houston Area",
  "TX > Laredo Area",
  "TX > Lubbock Area",
  "TX > Odessa Area",
  "TX> San Antonio Area",
  "TX > Tyler Area",
  "TX > Wichita Falls Area",
  "TX San Angelo Area",
  "TX > Victoria Area",
  "VA > Hampton Roads Area",
  "VA > Roanoke Area",
  "UT > Salt Lake City Area",
  "VA > Charlottesville Area",
  "VA > Harrisonburg Area",
  "VA > Richmond Area",
  "VT > Burlington Area",
  "WA > Seattle Area",
  "WA > Spokane Area",
  "WA > Tri Cities Area",
  "WI> Green Bay Area",
  "WI > La Crosse Area",
  "WI > Madison Area",
  "WI > Milwaukee Area",
  "WI > Wausau Area",
  "WV > Bluefield Area",
  "WV > Charleston Area",
  "WV > Clarksburg Area",
  "WV > Parkersburg Area",
  "WY > Casper Area",
  "WY > Cheyenne Area",
  "CAN> Provinces (13)",
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
  "CAN > Metro Areas (34)",
  "AB > Calgary Area",
  "AB Edmonton Area",
  "BC Abbotsford Area",
  "BC > Kelowna Area",
  "BC > Victoria Area",
  "MB Winnipeg Area",
  "NB> Moncton Area",
  "NB> St. John Area",
  "NL> St. John Area",
  "NS > Halifax Area",
  "ON > Barrie Area",
  "ON > London Area",
  "ON > Ottawa Area",
  "BC > Vancouver Area",
  "NT>Yellowknife Area",
  "ON > Brantford Area",
  "ON > Guelph Area",
  "ON> Hamilton Area",
  "ON > Kingston Area",
  "ON > Kitchener Area",
  "ON > London Area",
  "ON > Ottawa Area",
  "ON> Peterborough Area",
  "ON> St. Catherines Area",
  "ON > Sudbury Area",
  "ON > Thunder Bay Area",
  "ON > Toronto Area",
  "ON > Windsor Area",
  "PEI > Charlottetown Area",
  "QC > Chicoutimi Area",
  "QC > Montreal Area",
  "QC > Quebec Area",
  "QC > Sherbrooke Area",
  "QC > Trois Rivieres Area",
  "SK > Regina Area",
  "SK> Saskatoon Area",
  "Africa (52)",
  "Algeria",
  "Angola",
  "Benin",
  "Botswana",
  "Burkina Faso",
  "Burundi",
  "Cameroon",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "Comoros",
  "Congo (DRC)",
  "Congo (Republic)",
  "Djibouti",
  "Egypt",
  "Equatorial Guinea",
  "Eritrea",
  "Ethiopia",
  "Gabon",
  "Gambia",
  "Ghana",
  "Guinea",
  "Guinea-Bissau",
  "Kenya",
  "Lesotho",
  "Liberia",
  "Libya",
  "Madagascar",
  "Malawi",
  "Mali",
  "Mauritania",
  "Mauritius",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Niger",
  "Nigeria",
  "Rwanda",
  "Sao Tome and Principe",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Somalia",
  "South Africa",
  "Sudan",
  "Swaziland",
  "Tanzania",
  "Togo",
  "Tunisia",
  "Uganda",
  "Zambia",
  "Zimbabwe",
  "Asia (45)",
  "Afghanistan",
  "Armenia",
  "Azerbaijan",
  "Bahrain",
  "Bangladesh",
  "Bhutan",
  "Brunei Darussalam",
  "Cambodia",
  "China",
  "Georgia (C)",
  "India",
  "Indonesia",
  "Iran",
  "Israel",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Lebanon",
  "Malaysia",
  "Maldives",
  "Mongolia",
  "Myanmar",
  "Nepal",
  "North Korea",
  "Oman",
  "Pakistan",
  "Philippines",
  "Qatar",
  "Saudi Arabia",
  "Singapore",
  "South Korea",
  "Sri Lanka",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Laos",
  "Lebanon",
  "Malaysia",
  "Maldives",
  "Mongolia",
  "Myanmar",
  "Nepal",
  "North Korea",
  "Oman",
  "Pakistan",
  "Philippines",
  "Qatar",
  "Saudi Arabia",
  "Singapore",
  "South Korea",
  "Sri Lanka",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Europe (42)",
  "Albania",
  "Andorra",
  "Austria",
  "Belarus",
  "Belgium",
  "Bosnia Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia (FYROM)",
  "Malta",
  "Moldova",
  "Monaco",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Ukraine",
  "United Kingdom",
  "North America (22)",
  "Antigua and Barbuda",
  "Bahamas",
  "Barbados",
  "Belize",
  "Canada",
  "Costa Rica",
  "Cuba",
  "Dominica",
  "Dominican Republic",
  "El Salvador",
  "Grenada",
  "Guatemala",
  "Haiti",
  "Honduras",
  "Jamaica",
  "Mexico",
  "Nicaragua",
  "Panama",
  "Saint Kitts and Nevis",
  "Saint Vincent and the Grenadines",
  "Trinidad and Tobago",
  "United States",
  "Oceania (11)",
  "Australia",
  "Cook Islands",
  "Fiji",
  "Kiribati",
  "New Zealand",
  "Papua New Guinea",
  "Samoa",
  "Solomon Islands",
  "Timor-Leste",
  "Tonga",
  "Vanuatu",
  "South America (12)",
  "Australia",
  "Cook Islands",
  "Fiji",
  "Kiribati",
  "New Zealand",
  "Papua New Guinea",
  "Samoa",
  "Solomon Islands",
  "Timor-Leste",
  "Tonga",
  "Vanuatu",
];


export const Department = [
  {
    id: 0,
    label: "Agriculture and Natural Resources",
    children: [
      "Agriculture",
      "Dairy",
      "Fishery",
      "Farming",
      "Food Production",
      "Mining & Metals",
      "Oil & Energy",
      "Paper & Forest Products",
      "Ranching",
    ]
  },
  {
    id: 2,
    label: "Arts, Entertainment, and Recreation",
    children: [
      "Animation",
      "Arts & Crafts",
      "Broadcast Media",
      "Entertainment",
      "Fine Art",
      "Gambling & Casinos",
      "Graphic Design",
      "Motion Pictures & Film",
      "Museums & Institutions",
      "Music",
      "Performing Arts",
      "Photography",
      "Publishing",
      "Recreational Facilities & Services",
      "Sports",
      "Sporting Goods",
    ]
  },
  {
    id: 3,
    label: "Business and Finance",
    children: [
      "Accounting",
      "Banking",
      "Business Supplies & Equipment",
      "Capital Markets",
      "Financial Services",
      "Fund-Raising",
      "Insurance",
      "Investment Banking",
      "Investment Management",
      "Management Consulting",
      "Professional Training & Coaching",
      "Venture Capital & Private Equity",
    ]
  },
  {
    id: 4,
    label: "Communications and Media",
    children: [
      "Broadcast Media",
      "Newspapers",
      "Online Media",
      "Publishing",
    ]
  },
  {
    id: 5,
    label: "Construction and Real Estate",
    children: [
      "Architecture & Planning",
      "Building Materials",
      "Civil Engineering",
      "Commercial Real Estate",
      "Construction",
      "Real Estate",
    ]
  },
  {
    id: 6,
    label: "Consumer Goods and Services",
    children: [
      "Apparel & Fashion",
      "Automotive",
      "Consumer Electronics",
      "Consumer Goods",
      "Consumer Services",
      "Cosmetics",
      "Furniture",
      "Luxury Goods & Jewelry",
      "Retail",
      "Supermarkets",
      "Wine & Spirits",
    ]
  },
  {
    id: 7,
    label: "Education and Training",
    children: [
      "E-Learning",
      "Education Management",
      "Higher Education",
      "Libraries",
      "Primary/Secondary Education",
      "Professional Training & Coaching",
    ]
  },
  {
    id: 8,
    label: "Engineering and Manufacturing",
    children: [
      "Aerospace",
      "Aviation & Aerospace",
      "Chemicals",
      "Electrical/Electronic Manufacturing",
      "Glass, Ceramics & Concrete",
      "Industrial Automation",
      "Machinery",
      "Mechanical or Industrial Engineering",
      "Nanotechnology",
      "Plastics",
      "Printing",
      "Railroad Manufacture",
      "Semiconductors",
      "Shipbuilding",
    ]
  },
  {
    id: 9,
    label: "Government and Public Administration",
    children: [
      "Civic & Social Organization",
      "Government Administration",
      "Government Relations",
      "International Affairs",
      "Judiciary",
      "Law Enforcement",
      "Legislative Office",
      "Political Organization",
      "Public Policy",
      "Public Safety",
    ]
  },
  {
    id: 10,
    label: "Health and Human Services",
    children: [
      "Alternative Medicine",
      "Health, Wellness & Fitness",
      "Hospital & Health Care",
      "Individual & Family Services",
      "Medical Devices",
      "Medical Practice",
      "Mental Health Care",
      "Pharmaceuticals",
      "Veterinary",
    ]
  },
  {
    id: 11,
    label: "Hospitality and Tourism",
    children: [
      "Airlines/Aviation",
      "Hospitality",
      "Leisure, Travel & Tourism",
      "Restaurants",
    ]
  },
  {
    id: 12,
    label: "Human Resources and Staffing",
    children: [
      "Human Resources",
      "Staffing & Recruiting",
    ]
  },
  {
    id: 13,
    label: "Information Technology and Services",
    children: [
      "Computer & Network Security",
      "Computer Games",
      "Computer Hardware",
      "Computer Networking",
      "Computer Software",
      "Information Services",
      "Information Technology & Services",
      "Internet",
      "Online Media",
    ]
  },
  {
    id: 14,
    label: "Law and Legal Services",
    children: [
      "Alternative Dispute Resolution",
      "Law Practice",
      "Legal Services",
    ]
  },
  {
    id: 15,
    label: "Manufacturing and Distribution",
    children: [
      "Building Materials",
      "Consumer Goods",
      "Food & Beverages",
      "Furniture",
      "Glass, Ceramics & Concrete",
      "Machinery",
      "Packaging & Containers",
      "Paper & Forest Products",
      "Plastics",
    ]
  },
  {
    id: 16,
    label: "Marketing and Advertising",
    children: [
      "Market Research",
      "Marketing & Advertising",
      "Public Relations & Communications",
    ]
  },
  {
    id: 17,
    label: "Nonprofit and Philanthropy",
    children: [
      "Nonprofit Organization Management",
      "Philanthropy",
      "Think Tanks",
    ]
  },
  {
    id: 18,
    label: "Professional Services",
    children: [
      "Executive Office",
      "Management Consulting",
      "Program Development",
    ]
  },
  {
    id: 19,
    label: "Science and Research",
    children: [
      "Biotechnology",
      "Environmental Services",
      "Nanotechnology",
      "Research",
    ]
  },
  {
    id: 20,
    label: "Security and Defense",
    children: [
      "Defense & Space",
      "Security & Investigations",
    ]
  },
  {
    id: 21,
    label: "Trade and Logistics",
    children: [
      "Commercial Real Estate",
      "Import & Export",
      "International Trade & Development",
      "Logistics & Supply Chain",
      "Outsourcing/Offshoring",
      "Package/Freight Delivery",
      "Transportation/Trucking/Railroad",
      "Warehousing",
      "Wholesale",
    ]
  },
  {
    id: 22,
    label: "Utilities and Energy",
    children: [
      "Oil & Energy",
      "Renewables & Environment",
      "Utilities",
    ]
  },
  {
    id: 23,
    label: "Miscellaneous",
    children: [
      "Design",
      "Events Services",
      "Facilities Services",
      "Fine Art",
      "Performing Arts",
      "Photography",
      "Printing",
      "Restaurants",
      "Translation & Localization",
      "Writing & Editing",
    ]
  },
];

export const ManagementLevels = [
  "Owner",
  "Founder",
  "C suite",
  "Partner",
  "Vp",
  "Head",
  "Director",
  "Manager",
  "Senior",
  "Entry",
  "Intern",
];


export const industryData = [
  "Accounting",
  "Agriculture",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "E-Learning",
  "Education Management",
  "Electrical/Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fund-Raising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure, Travel & Tourism",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Market Research",
  "Marketing & Advertising",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Devices",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Media",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary Education",
  "Printing",
  "Professional Training & Coaching",
  "Program Development",
  "Public Policy",
  "Public Relations & Communications",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital & Private Equity",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing",

];

export const name = [];

export const degreeData = [
  "Bachelors",
  "Masters",
  "Associates",
  "High School",
  "Doctorate"
];

export const majorData = [
  "Computer Science",
  "Business Administration",
  "Management",
  "Marketing",
  "Accounting",
];

export const jobTitles = [
  "manager",
"project manager",
"teacher",
"owner",
"student",
"director",
"software engineer",
"consultant",
"account manager",
"engineer",
"professor",
"sales manager",
"sales",
"partner",
"associate",
"president",
"administrative assistant",
"supervisor",
"general manager",
"realtor",
"chief executive officer (ceo)",
"chief operating officer (coo)",
"chief financial officer (cfo)",
"chief marketing officer (cmo)",
"chief technology officer (cto)",
"chief information officer (cio)",
"chief sales officer (cso)",
"chief revenue officer (cro)",
"senior management",
"vice president of sales",
"vice president of marketing",
"vice president of operations",
"vice president of finance",
"vice president of technology",
"vice president of business development",
"mid-level management",
"sales director",
"marketing director",
"operations director",
"finance director",
"product manager",
"business development manager",
"it manager",
"customer success manager",
"specialized roles",
"lead generation specialist",
"digital marketing manager",
"content marketing manager",
"data analyst",
"sales enablement manager",
"product marketing manager",
"brand manager",
"administrative and support",
"executive assistant",
"office manager",
"sales and business development",
"account executive",
"sales operations manager",
"sales development representative (sdr)",
"business development representative (bdr)",
"regional sales manager",
"enterprise sales manager",
"channel sales manager",
"sales strategy manager",
"marketing",
"growth marketing manager",
"digital advertising specialist",
"seo specialist",
"ppc specialist",
"email marketing manager",
"marketing operations manager",
"marketing analytics manager",
"event marketing manager",
"social media manager",
"public relations manager",
"product and development",
"product development manager",
"product owner",
"product director",
"ux/ui designer",
"technical product manager",
"engineering manager",
"head of product",
"innovation manager",
"finance and administration",
"controller",
"treasurer",
"finance manager",
"accounting manager",
"financial analyst",
"audit manager",
"compliance officer",
"operations",
"operations manager",
"supply chain manager",
"logistics manager",
"procurement manager",
"facilities manager",
"quality assurance manager",
"customer and support",
"customer support manager",
"customer experience manager",
"client success manager",
"technical support specialist",
"customer service representative",
"human resources",
"hr director",
"hr manager",
"talent acquisition manager",
"recruitment specialist",
"learning and development manager",
"compensation and benefits manager",
"technology and it",
"it director",
"network administrator",
"systems administrator",
"database administrator",
"cloud solutions architect",
"it support specialist",
"cybersecurity specialist",
"legal and compliance",
"general counsel",
"corporate counsel",
"compliance manager",
"legal advisor",
"contract manager",
];

export const skills = [];
export const yearsOfExperience = ["0 - 3", "3 - 10", "10 +"];
export const companyName = [];
export const employeeCount = [
  "1 , 10",
  "11 , 50",
  "51 , 100",
  "101 , 500",
  "501 , 1000",
  "1001 , 5000",
  "5001 , 10000",
  "10001 , 25000",
  "25000 +",
];
export const revenue = [
  {
    label: "< $10M",
    value: "<10000000",
  },
  {
    label: "$10M - $50M",
    value: "10000000-50000000",
  },
  {
    label: "$50M - $100M",
    value: "50000000-100000000",
  },
  {
    label: "$100M - $500M",
    value: "100000000-500000000",
  },
  {
    label: "$500M - $1B",
    value: "500000000-1000000000",
  },
  {
    label: "$1B - $5B",
    value: "1000000000-5000000000",
  },
  {
    label: "$5B +",
    value: "5000000000+",
  },
];

export const employeeGrowth = [
  {
    label:  '-100% -> 50%',
    value: "~100-~50",
  },
  {
    label:  '-50% -> 0%',
    value: "~50-0",
  },
  {
    label:  '0% -> 25%',
    value: "0-25",
  },
  {
    label:  '25% -> 50%',
    value: "25-50",
  },
  {
    label:  '50% -> 100%',
    value: "50-100",
  },
  {
    label:  '100%+',
    value: "100<",
  }
];
export const emailTypes = ["Professional Email", "Personal Email"];
export const companyLists = [];
export const education = [];
export const contactInfo = [];
export const socialLink = [];
export const keywords = [];
export const exclude = [];

export const filterIndustryList = [
  {
    name: "name",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFCompanyName sx={{}} />
      </Box>
    ),
    label: "Company Name",
    placeHolder: "Company Name",
  },
  // {
  //   name: "domain",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFCompanyName sx={{}} />
  //     </Box>
  //   ),
  //   label: "Domain",
  //   placeHolder: "Domain",
  // },
  {
    name: "geo",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: "Location",
    placeHolder: "Location",
    menuItems: locationData,
    select: true,
  },
  {
    name: "nGeo",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: "Company Not Located At",
    placeHolder: "Company Not Located At",
    menuItems: locationData,
    select: true,
  },
  {
    name: "keyword",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <Abc sx={{ color: "#28287B" }} />
      </Box>
    ),
    label: "organization Id",
    placeHolder: "organization Id",
  },
  {
    name: "industry",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFIndustry sx={{}} />
      </Box>
    ),
    label: "Industry",
    placeHolder: "Industry",
    select: true,
    suggestion: true,
    suggestionName: 'company_industry',
    menuItems: industryData,
  },
  // {
  //   name: "sic_code",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFKeywords sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: [],
  //   label: "SIC Code",
  //   suggestion: false,
  //   placeHolder: "Enter SIC code",
  // },
  // {
  //   name: "naics_code",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFKeywords sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: [],
  //   label: "NAICS Code",
  //   suggestion: false,
  //   placeHolder: "Enter NAICS code",
  // },
  {
    name: "employees",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFEmployees sx={{}} />
      </Box>
    ),
    label: "Employee Count",
    placeHolder: "Employee Count",
    select: true,
    menuItems: employeeCount,
  },
  // {
  //   name: "growth",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFEmployees sx={{}} />
  //     </Box>
  //   ),
  //   label: "Employee Growth",
  //   placeHolder: "Employee Growth",
  //   select: true,
  //   menuItems: employeeGrowth,
  // },
  // {
  //   name: "revenue",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFRevenue sx={{}} />
  //     </Box>
  //   ),
  //   label: "Revenue",
  //   placeHolder:"Revenue", 
  //   select: true,
  //   menuItems: revenue,
  // },
  // {
  //   name: "techstack",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFTechnologies sx={{ color: "#28287B" }} />
  //     </Box>
  //   ),
  //   label: "Technology",
  //   placeHolder: "Technology",
  //   select: true,
  //   suggestion: true,
  //   menuItems: []
  // },
];

export const filterList = [
  //Person Info.
  {
    title: "",
    name: "geo",
    placeHolder: "location",
    // icon: (
    //   <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
    //     <LFLocation sx={{}} />
    //   </Box>
    // ),
    label: "Location",
    menuItems: locationData,
    select: true,
  },
  {
    title: "",
    name: "name",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFName sx={{ color: "#28287B" }} />
      </Box>
    ),
    label: "Name",
    placeHolder: "Enter name of the person...",
  },
  {
    title: "",
    name: "current_title",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFJobTitles sx={{}} />
      </Box>
    ),
    label: "Job Titles",
    placeHolder: "Job Titles",
    menuItems: jobTitles,
    suggestion: true,
    select: true,
  },
  {
    title: "",
    name: "management_levels",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFManagementLevels sx={{}} />
      </Box>
    ),
    label: "Management Levels",
    placeHolder: "Management Levels",
    menuItems: ManagementLevels,
    select: true,
  },
  {
    title: "",
    name: "major",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <MailOutlineIcon sx={{ color: '#000000', opacity: 0.7 }} />
      </Box>
    ),
    select: true,
    menuItems: ["likely to engage", "verified", "unverified", "update required", "unavailable "],
    label: "Email Status",
    suggestion: true,
    placeHolder: "Email Status",
  },
  {
    title: "",
    name: "oGeo",
    placeHolder: "Company Locations",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: "Company Locations",
    menuItems: locationData,
    select: true,
  },
  {
    title: "",
    name: "link",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFTechnologies sx={{}} />
      </Box>
    ),
    label: "Domain",
    placeHolder: "Domain",
  },
  // {
  //   name: "km",
  //   label: "KM",
  //   menuItems: marks,
  //   select: false,
  // },
  {
    title: "",
    name: "department",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFDepartment sx={{}} />
      </Box>
    ),
    label: "Industries",
    placeHolder: "Industries",
    menuItems: Department,
    select: true,
  },
  // {
  //   name: "job_change_range_days",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFLocation sx={{}} />
  //     </Box>
  //   ),
  //   label: "Changed Jobs Within",
  //   placeHolder: "Changed Jobs Within",
  //   menuItems: TimeData,
  //   select: true,
  // },
  // {
  //   name: "skills",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFKeywords sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: [],
  //   label: "Skills",
  //   suggestion: true,
  //   placeHolder: "Skills",
  // },
  // {
  //   name: "years_experience",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFLookalikeDomain sx={{}} />
  //     </Box>
  //   ),
  //   label: "Years of Experience",
  //   placeHolder: "Years of Experience",
  //   select: true,
  //   menuItems: yearsOfExperience,
  // },
  // {
  //   name: "employer",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFCompanyName sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: [],
  //   label: "Company Name or Domain",
  //   suggestion: true,
  //   placeHolder: "Company Name or Domain",
  // },
  // {
  //   name: "include_past",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFCompanyName sx={{}} />
  //     </Box>
  //   ),
  //   label: "Include Past Employers",
  //   placeHolder: "Include Past Employers",
  // },
  {
    title: "",
    name: "company_size",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFEmployees sx={{}} />
      </Box>
    ),
    label: "Employee Count",
    placeHolder: "Employee Count",
    select: true,
    menuItems: employeeCount,
  },
  // {
  //   name: "revenue",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFRevenue sx={{}} />
  //     </Box>
  //   ),
  //   label: "Revenue",
  //   placeHolder: "Revenue",
  //   select: true,
  //   menuItems: revenue,
  // },
  // {
  //   name: "company_industry",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFIndustry sx={{}} />
  //     </Box>
  //   ),
  //   label: "Industry",
  //   placeHolder: "Industry",
  //   select: true,
  //   suggestion: true,
  //   menuItems: industryData,
  // },
  // {
  //   name: "company_name",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFIndustry sx={{}} />
  //     </Box>
  //   ),
  //   label: "Company List",
  //   select: true,
  //   menuItems: ['CSV Upload'],
  // },
  /* {
    name: "email_type",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFJobTitles sx={{}} />
      </Box>
    ),
    label: "Email Type",
    menuItems: emailTypes,
    select: true,
  },*/
  // {
  //   name: "degree",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFDepartment sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: degreeData,
  //   label: "Education Degree",
  //   suggestion: true,
  //   placeHolder: "Education Degree",
  // },
  // {
  //   name: "major",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFDepartment sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: majorData,
  //   label: "Education Major",
  //   suggestion: true,
  //   placeHolder: "Education Major",
  // },
  // {
  //   name: "school",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFDepartment sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   label: "Education School",
  //   placeHolder: "Education School",
  //   menuItems: [],
  //   suggestion: true,
  // },
  // {
  //   name: "email",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <BadgeOutlined sx={{ color: "#28287B" }} />
  //     </Box>
  //   ),
  //   label: "Contact Info",
  //   placeHolder: "Enter email of the person...",
  // },
  // {
  //   name: "link",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFTechnologies sx={{}} />
  //     </Box>
  //   ),
  //   label: "Social",
  //   placeHolder: "Enter Linkedin or Twitter url...",
  // },
  {
    title: "",
    name: "keyword",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <Abc sx={{ color: "#28287B" }} />
      </Box>
    ),
    label: "Organization Id",
    placeHolder: "Organization Id",
  },
  {
    title: "",
    name: "skills",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFName sx={{ color: "#28287B" }} />
      </Box>
    ),
    label: "Keywords",
    placeHolder: "Enter Keywords...",
  }
  // {
  //   name: "exclude_keyword",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <BackspaceOutlined sx={{ color: "#28287B" }} />
  //     </Box>
  //   ),
  //   label: "Exclude",
  //   placeHolder: "Exclude",
  // },
];

export const timingIntervals = [
  "0:00 AM",
  "0:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const timezones = [
  {
    offset: "GMT-12:00",
    name: "Etc/GMT-12",
  },
  {
    offset: "GMT-11:00",
    name: "Etc/GMT-11",
  },
  {
    offset: "GMT-11:00",
    name: "Pacific/Midway",
  },
  {
    offset: "GMT-10:00",
    name: "America/Adak",
  },
  {
    offset: "GMT-09:00",
    name: "America/Anchorage",
  },
  {
    offset: "GMT-09:00",
    name: "Pacific/Gambier",
  },
  {
    offset: "GMT-08:00",
    name: "America/Dawson_Creek",
  },
  {
    offset: "GMT-08:00",
    name: "America/Ensenada",
  },
  {
    offset: "GMT-08:00",
    name: "America/Los_Angeles",
  },
  {
    offset: "GMT-07:00",
    name: "America/Chihuahua",
  },
  {
    offset: "GMT-07:00",
    name: "America/Denver",
  },
  {
    offset: "GMT-06:00",
    name: "America/Belize",
  },
  {
    offset: "GMT-06:00",
    name: "America/Cancun",
  },
  {
    offset: "GMT-06:00",
    name: "America/Chicago",
  },
  {
    offset: "GMT-06:00",
    name: "Chile/EasterIsland",
  },
  {
    offset: "GMT-05:00",
    name: "America/Bogota",
  },
  {
    offset: "GMT-05:00",
    name: "America/Havana",
  },
  {
    offset: "GMT-05:00",
    name: "America/New_York",
  },
  {
    offset: "GMT-04:30",
    name: "America/Caracas",
  },
  {
    offset: "GMT-04:00",
    name: "America/Campo_Grande",
  },
  {
    offset: "GMT-04:00",
    name: "America/Glace_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Goose_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Santiago",
  },
  {
    offset: "GMT-04:00",
    name: "America/La_Paz",
  },
  {
    offset: "GMT-03:00",
    name: "America/Argentina/Buenos_Aires",
  },
  {
    offset: "GMT-03:00",
    name: "America/Montevideo",
  },
  {
    offset: "GMT-03:00",
    name: "America/Araguaina",
  },
  {
    offset: "GMT-03:00",
    name: "America/Godthab",
  },
  {
    offset: "GMT-03:00",
    name: "America/Miquelon",
  },
  {
    offset: "GMT-03:00",
    name: "America/Sao_Paulo",
  },
  {
    offset: "GMT-03:30",
    name: "America/St_Johns",
  },
  {
    offset: "GMT-02:00",
    name: "America/Noronha",
  },
  {
    offset: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
  },
  {
    offset: "GMT",
    name: "Europe/Belfast",
  },
  {
    offset: "GMT",
    name: "Africa/Abidjan",
  },
  {
    offset: "GMT",
    name: "Europe/Dublin",
  },
  {
    offset: "GMT",
    name: "Europe/Lisbon",
  },
  {
    offset: "GMT",
    name: "Europe/London",
  },
  {
    offset: "UTC",
    name: "UTC",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Algiers",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Windhoek",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Azores",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Stanley",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Amsterdam",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Belgrade",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Brussels",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Cairo",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Blantyre",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Beirut",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Damascus",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Gaza",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Jerusalem",
  },
  {
    offset: "GMT+03:00",
    name: "Africa/Addis_Ababa",
  },
  {
    offset: "GMT+03:00",
    name: "Asia/Riyadh89",
  },
  {
    offset: "GMT+03:00",
    name: "Europe/Minsk",
  },
  {
    offset: "GMT+03:30",
    name: "Asia/Tehran",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Dubai",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Yerevan",
  },
  {
    offset: "GMT+04:00",
    name: "Europe/Moscow",
  },
  {
    offset: "GMT+04:30",
    name: "Asia/Kabul",
  },
  {
    offset: "GMT+05:00",
    name: "Asia/Tashkent",
  },
  {
    offset: "GMT+05:30",
    name: "Asia/Kolkata",
  },
  {
    offset: "GMT+05:45",
    name: "Asia/Katmandu",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Dhaka",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Yekaterinburg",
  },
  {
    offset: "GMT+06:30",
    name: "Asia/Rangoon",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Bangkok",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Novosibirsk",
  },
  {
    offset: "GMT+08:00",
    name: "Etc/GMT+8",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Hong_Kong",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Krasnoyarsk",
  },
  {
    offset: "GMT+08:00",
    name: "Australia/Perth",
  },
  {
    offset: "GMT+08:45",
    name: "Australia/Eucla",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Irkutsk",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Seoul",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Tokyo",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Adelaide",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Darwin",
  },
  {
    offset: "GMT+09:30",
    name: "Pacific/Marquesas",
  },
  {
    offset: "GMT+10:00",
    name: "Etc/GMT+10",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Brisbane",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Hobart",
  },
  {
    offset: "GMT+10:00",
    name: "Asia/Yakutsk",
  },
  {
    offset: "GMT+10:30",
    name: "Australia/Lord_Howe",
  },
  {
    offset: "GMT+11:00",
    name: "Asia/Vladivostok",
  },
  {
    offset: "GMT+11:30",
    name: "Pacific/Norfolk",
  },
  {
    offset: "GMT+12:00",
    name: "Etc/GMT+12",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Anadyr",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Magadan",
  },
  {
    offset: "GMT+12:00",
    name: "Pacific/Auckland",
  },
  {
    offset: "GMT+12:45",
    name: "Pacific/Chatham",
  },
  {
    offset: "GMT+13:00",
    name: "Pacific/Tongatapu",
  },
  {
    offset: "GMT+14:00",
    name: "Pacific/Kiritimati",
  },
];

export const timezonesConcatenated = timezones.map((item) => `${item.name} (${item.offset})`);

const timezoneObj = {
  "Pacific/Niue": "(GMT-11:00) Niue",
  "Pacific/Pago_Pago": "(GMT-11:00) Pago Pago",
  "Pacific/Honolulu": "(GMT-10:00) Hawaii Time",
  "Pacific/Rarotonga": "(GMT-10:00) Rarotonga",
  "Pacific/Tahiti": "(GMT-10:00) Tahiti",
  "Pacific/Marquesas": "(GMT-09:30) Marquesas",
  "America/Anchorage": "(GMT-09:00) Alaska Time",
  "Pacific/Gambier": "(GMT-09:00) Gambier",
  "America/Los_Angeles": "(GMT-08:00) Pacific Time",
  "America/Tijuana": "(GMT-08:00) Pacific Time - Tijuana",
  "America/Vancouver": "(GMT-08:00) Pacific Time - Vancouver",
  "America/Whitehorse": "(GMT-08:00) Pacific Time - Whitehorse",
  "Pacific/Pitcairn": "(GMT-08:00) Pitcairn",
  "America/Dawson_Creek": "(GMT-07:00) Mountain Time - Dawson Creek",
  "America/Denver": "(GMT-07:00) Mountain Time",
  "America/Edmonton": "(GMT-07:00) Mountain Time - Edmonton",
  "America/Hermosillo": "(GMT-07:00) Mountain Time - Hermosillo",
  "America/Mazatlan": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan",
  "America/Phoenix": "(GMT-07:00) Mountain Time - Arizona",
  "America/Yellowknife": "(GMT-07:00) Mountain Time - Yellowknife",
  "America/Belize": "(GMT-06:00) Belize",
  "America/Chicago": "(GMT-06:00) Central Time",
  "America/Costa_Rica": "(GMT-06:00) Costa Rica",
  "America/El_Salvador": "(GMT-06:00) El Salvador",
  "America/Guatemala": "(GMT-06:00) Guatemala",
  "America/Managua": "(GMT-06:00) Managua",
  "America/Mexico_City": "(GMT-06:00) Central Time - Mexico City",
  "America/Regina": "(GMT-06:00) Central Time - Regina",
  "America/Tegucigalpa": "(GMT-06:00) Central Time - Tegucigalpa",
  "America/Winnipeg": "(GMT-06:00) Central Time - Winnipeg",
  "Pacific/Galapagos": "(GMT-06:00) Galapagos",
  "America/Bogota": "(GMT-05:00) Bogota",
  "America/Cancun": "(GMT-05:00) America Cancun",
  "America/Cayman": "(GMT-05:00) Cayman",
  "America/Guayaquil": "(GMT-05:00) Guayaquil",
  "America/Havana": "(GMT-05:00) Havana",
  "America/Iqaluit": "(GMT-05:00) Eastern Time - Iqaluit",
  "America/Jamaica": "(GMT-05:00) Jamaica",
  "America/Lima": "(GMT-05:00) Lima",
  "America/Nassau": "(GMT-05:00) Nassau",
  "America/New_York": "(GMT-05:00) Eastern Time",
  "America/Panama": "(GMT-05:00) Panama",
  "America/Port-au-Prince": "(GMT-05:00) Port-au-Prince",
  "America/Rio_Branco": "(GMT-05:00) Rio Branco",
  "America/Toronto": "(GMT-05:00) Eastern Time - Toronto",
  "Pacific/Easter": "(GMT-05:00) Easter Island",
  "America/Caracas": "(GMT-04:00) Caracas",
  "America/Asuncion": "(GMT-03:00) Asuncion",
  "America/Barbados": "(GMT-04:00) Barbados",
  "America/Boa_Vista": "(GMT-04:00) Boa Vista",
  "America/Campo_Grande": "(GMT-03:00) Campo Grande",
  "America/Cuiaba": "(GMT-03:00) Cuiaba",
  "America/Curacao": "(GMT-04:00) Curacao",
  "America/Grand_Turk": "(GMT-04:00) Grand Turk",
  "America/Guyana": "(GMT-04:00) Guyana",
  "America/Halifax": "(GMT-04:00) Atlantic Time - Halifax",
  "America/La_Paz": "(GMT-04:00) La Paz",
  "America/Manaus": "(GMT-04:00) Manaus",
  "America/Martinique": "(GMT-04:00) Martinique",
  "America/Port_of_Spain": "(GMT-04:00) Port of Spain",
  "America/Porto_Velho": "(GMT-04:00) Porto Velho",
  "America/Puerto_Rico": "(GMT-04:00) Puerto Rico",
  "America/Santo_Domingo": "(GMT-04:00) Santo Domingo",
  "America/Thule": "(GMT-04:00) Thule",
  "Atlantic/Bermuda": "(GMT-04:00) Bermuda",
  "America/St_Johns": "(GMT-03:30) Newfoundland Time - St. Johns",
  "America/Araguaina": "(GMT-03:00) Araguaina",
  "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires",
  "America/Bahia": "(GMT-03:00) Salvador",
  "America/Belem": "(GMT-03:00) Belem",
  "America/Cayenne": "(GMT-03:00) Cayenne",
  "America/Fortaleza": "(GMT-03:00) Fortaleza",
  "America/Godthab": "(GMT-03:00) Godthab",
  "America/Maceio": "(GMT-03:00) Maceio",
  "America/Miquelon": "(GMT-03:00) Miquelon",
  "America/Montevideo": "(GMT-03:00) Montevideo",
  "America/Paramaribo": "(GMT-03:00) Paramaribo",
  "America/Recife": "(GMT-03:00) Recife",
  "America/Santiago": "(GMT-03:00) Santiago",
  "America/Sao_Paulo": "(GMT-03:00) Sao Paulo",
  "Antarctica/Palmer": "(GMT-03:00) Palmer",
  "Antarctica/Rothera": "(GMT-03:00) Rothera",
  "Atlantic/Stanley": "(GMT-03:00) Stanley",
  "America/Noronha": "(GMT-02:00) Noronha",
  "Atlantic/South_Georgia": "(GMT-02:00) South Georgia",
  "America/Scoresbysund": "(GMT-01:00) Scoresbysund",
  "Atlantic/Azores": "(GMT-01:00) Azores",
  "Atlantic/Cape_Verde": "(GMT-01:00) Cape Verde",
  "Africa/Abidjan": "(GMT+00:00) Abidjan",
  "Africa/Accra": "(GMT+00:00) Accra",
  "Africa/Bissau": "(GMT+00:00) Bissau",
  "Africa/Casablanca": "(GMT+00:00) Casablanca",
  "Africa/El_Aaiun": "(GMT+00:00) El Aaiun",
  "Africa/Monrovia": "(GMT+00:00) Monrovia",
  "America/Danmarkshavn": "(GMT+00:00) Danmarkshavn",
  "Atlantic/Canary": "(GMT+00:00) Canary Islands",
  "Atlantic/Faroe": "(GMT+00:00) Faeroe",
  "Atlantic/Reykjavik": "(GMT+00:00) Reykjavik",
  "Etc/GMT": "(GMT+00:00) GMT (no daylight saving)",
  "Europe/Dublin": "(GMT+00:00) Dublin",
  "Europe/Lisbon": "(GMT+00:00) Lisbon",
  "Europe/London": "(GMT+00:00) London",
  "Africa/Algiers": "(GMT+01:00) Algiers",
  "Africa/Ceuta": "(GMT+01:00) Ceuta",
  "Africa/Lagos": "(GMT+01:00) Lagos",
  "Africa/Ndjamena": "(GMT+01:00) Ndjamena",
  "Africa/Tunis": "(GMT+01:00) Tunis",
  "Africa/Windhoek": "(GMT+02:00) Windhoek",
  "Europe/Amsterdam": "(GMT+01:00) Amsterdam",
  "Europe/Andorra": "(GMT+01:00) Andorra",
  "Europe/Belgrade": "(GMT+01:00) Central European Time - Belgrade",
  "Europe/Berlin": "(GMT+01:00) Berlin",
  "Europe/Brussels": "(GMT+01:00) Brussels",
  "Europe/Budapest": "(GMT+01:00) Budapest",
  "Europe/Copenhagen": "(GMT+01:00) Copenhagen",
  "Europe/Gibraltar": "(GMT+01:00) Gibraltar",
  "Europe/Luxembourg": "(GMT+01:00) Luxembourg",
  "Europe/Madrid": "(GMT+01:00) Madrid",
  "Europe/Malta": "(GMT+01:00) Malta",
  "Europe/Monaco": "(GMT+01:00) Monaco",
  "Europe/Oslo": "(GMT+01:00) Oslo",
  "Europe/Paris": "(GMT+01:00) Paris",
  "Europe/Prague": "(GMT+01:00) Central European Time - Prague",
  "Europe/Rome": "(GMT+01:00) Rome",
  "Europe/Stockholm": "(GMT+01:00) Stockholm",
  "Europe/Tirane": "(GMT+01:00) Tirane",
  "Europe/Vienna": "(GMT+01:00) Vienna",
  "Europe/Warsaw": "(GMT+01:00) Warsaw",
  "Europe/Zurich": "(GMT+01:00) Zurich",
  "Africa/Cairo": "(GMT+02:00) Cairo",
  "Africa/Johannesburg": "(GMT+02:00) Johannesburg",
  "Africa/Maputo": "(GMT+02:00) Maputo",
  "Africa/Tripoli": "(GMT+02:00) Tripoli",
  "Asia/Amman": "(GMT+02:00) Amman",
  "Asia/Beirut": "(GMT+02:00) Beirut",
  "Asia/Damascus": "(GMT+02:00) Damascus",
  "Asia/Gaza": "(GMT+02:00) Gaza",
  "Asia/Jerusalem": "(GMT+02:00) Jerusalem",
  "Asia/Nicosia": "(GMT+02:00) Nicosia",
  "Europe/Athens": "(GMT+02:00) Athens",
  "Europe/Bucharest": "(GMT+02:00) Bucharest",
  "Europe/Chisinau": "(GMT+02:00) Chisinau",
  "Europe/Helsinki": "(GMT+02:00) Helsinki",
  "Europe/Istanbul": "(GMT+03:00) Istanbul",
  "Europe/Kaliningrad": "(GMT+02:00) Moscow-01 - Kaliningrad",
  "Europe/Kyiv": "(GMT+02:00) Kyiv",
  "Europe/Riga": "(GMT+02:00) Riga",
  "Europe/Sofia": "(GMT+02:00) Sofia",
  "Europe/Tallinn": "(GMT+02:00) Tallinn",
  "Europe/Vilnius": "(GMT+02:00) Vilnius",
  "Africa/Khartoum": "(GMT+03:00) Khartoum",
  "Africa/Nairobi": "(GMT+03:00) Nairobi",
  "Antarctica/Syowa": "(GMT+03:00) Syowa",
  "Asia/Baghdad": "(GMT+03:00) Baghdad",
  "Asia/Qatar": "(GMT+03:00) Qatar",
  "Asia/Riyadh": "(GMT+03:00) Riyadh",
  "Europe/Minsk": "(GMT+03:00) Minsk",
  "Europe/Moscow": "(GMT+03:00) Moscow+00 - Moscow",
  "Asia/Tehran": "(GMT+03:30) Tehran",
  "Asia/Baku": "(GMT+04:00) Baku",
  "Asia/Dubai": "(GMT+04:00) Dubai",
  "Asia/Tbilisi": "(GMT+04:00) Tbilisi",
  "Asia/Yerevan": "(GMT+04:00) Yerevan",
  "Europe/Samara": "(GMT+04:00) Moscow+01 - Samara",
  "Indian/Mahe": "(GMT+04:00) Mahe",
  "Indian/Mauritius": "(GMT+04:00) Mauritius",
  "Indian/Reunion": "(GMT+04:00) Reunion",
  "Asia/Kabul": "(GMT+04:30) Kabul",
  "Antarctica/Mawson": "(GMT+05:00) Mawson",
  "Asia/Aqtau": "(GMT+05:00) Aqtau",
  "Asia/Aqtobe": "(GMT+05:00) Aqtobe",
  "Asia/Ashgabat": "(GMT+05:00) Ashgabat",
  "Asia/Dushanbe": "(GMT+05:00) Dushanbe",
  "Asia/Karachi": "(GMT+05:00) Karachi",
  "Asia/Tashkent": "(GMT+05:00) Tashkent",
  "Asia/Yekaterinburg": "(GMT+05:00) Moscow+02 - Yekaterinburg",
  "Indian/Kerguelen": "(GMT+05:00) Kerguelen",
  "Indian/Maldives": "(GMT+05:00) Maldives",
  "Asia/Calcutta": "(GMT+05:30) India Standard Time",
  "Asia/Colombo": "(GMT+05:30) Colombo",
  "Asia/Katmandu": "(GMT+05:45) Katmandu",
  "Antarctica/Vostok": "(GMT+06:00) Vostok",
  "Asia/Almaty": "(GMT+06:00) Almaty",
  "Asia/Bishkek": "(GMT+06:00) Bishkek",
  "Asia/Dhaka": "(GMT+06:00) Dhaka",
  "Asia/Omsk": "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk",
  "Asia/Thimphu": "(GMT+06:00) Thimphu",
  "Indian/Chagos": "(GMT+06:00) Chagos",
  "Asia/Rangoon": "(GMT+06:30) Rangoon",
  "Indian/Cocos": "(GMT+06:30) Cocos",
  "Antarctica/Davis": "(GMT+07:00) Davis",
  "Asia/Bangkok": "(GMT+07:00) Bangkok",
  "Asia/Hovd": "(GMT+07:00) Hovd",
  "Asia/Jakarta": "(GMT+07:00) Jakarta",
  "Asia/Krasnoyarsk": "(GMT+07:00) Moscow+04 - Krasnoyarsk",
  "Asia/Saigon": "(GMT+07:00) Hanoi",
  "Asia/Ho_Chi_Minh": "(GMT+07:00) Ho Chi Minh",
  "Indian/Christmas": "(GMT+07:00) Christmas",
  "Antarctica/Casey": "(GMT+08:00) Casey",
  "Asia/Brunei": "(GMT+08:00) Brunei",
  "Asia/Choibalsan": "(GMT+08:00) Choibalsan",
  "Asia/Hong_Kong": "(GMT+08:00) Hong Kong",
  "Asia/Irkutsk": "(GMT+08:00) Moscow+05 - Irkutsk",
  "Asia/Kuala_Lumpur": "(GMT+08:00) Kuala Lumpur",
  "Asia/Macau": "(GMT+08:00) Macau",
  "Asia/Makassar": "(GMT+08:00) Makassar",
  "Asia/Manila": "(GMT+08:00) Manila",
  "Asia/Shanghai": "(GMT+08:00) China Time - Beijing",
  "Asia/Singapore": "(GMT+08:00) Singapore",
  "Asia/Taipei": "(GMT+08:00) Taipei",
  "Asia/Ulaanbaatar": "(GMT+08:00) Ulaanbaatar",
  "Australia/Perth": "(GMT+08:00) Western Time - Perth",
  "Asia/Pyongyang": "(GMT+08:30) Pyongyang",
  "Asia/Dili": "(GMT+09:00) Dili",
  "Asia/Jayapura": "(GMT+09:00) Jayapura",
  "Asia/Seoul": "(GMT+09:00) Seoul",
  "Asia/Tokyo": "(GMT+09:00) Tokyo",
  "Asia/Yakutsk": "(GMT+09:00) Moscow+06 - Yakutsk",
  "Pacific/Palau": "(GMT+09:00) Palau",
  "Australia/Adelaide": "(GMT+10:30) Central Time - Adelaide",
  "Australia/Darwin": "(GMT+09:30) Central Time - Darwin",
  "Antarctica/DumontDUrville": "(GMT+10:00) Dumont D'Urville",
  "Asia/Magadan": "(GMT+10:00) Moscow+07 - Magadan",
  "Asia/Vladivostok": "(GMT+10:00) Moscow+07 - Vladivostok",
  "Australia/Brisbane": "(GMT+10:00) Eastern Time - Brisbane",
  "Asia/Yuzhno-Sakhalinsk": "(GMT+11:00) Moscow+08 - Yuzhno-Sakhalinsk",
  "Australia/Hobart": "(GMT+11:00) Eastern Time - Hobart",
  "Australia/Sydney": "(GMT+11:00) Eastern Time - Melbourne, Sydney",
  "Pacific/Chuuk": "(GMT+10:00) Truk",
  "Pacific/Guam": "(GMT+10:00) Guam",
  "Pacific/Port_Moresby": "(GMT+10:00) Port Moresby",
  "Pacific/Efate": "(GMT+11:00) Efate",
  "Pacific/Guadalcanal": "(GMT+11:00) Guadalcanal",
  "Pacific/Kosrae": "(GMT+11:00) Kosrae",
  "Pacific/Norfolk": "(GMT+11:00) Norfolk",
  "Pacific/Noumea": "(GMT+11:00) Noumea",
  "Pacific/Pohnpei": "(GMT+11:00) Ponape",
  "Asia/Kamchatka": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  "Pacific/Auckland": "(GMT+13:00) Auckland",
  "Pacific/Fiji": "(GMT+13:00) Fiji",
  "Pacific/Funafuti": "(GMT+12:00) Funafuti",
  "Pacific/Kwajalein": "(GMT+12:00) Kwajalein",
  "Pacific/Majuro": "(GMT+12:00) Majuro",
  "Pacific/Nauru": "(GMT+12:00) Nauru",
  "Pacific/Tarawa": "(GMT+12:00) Tarawa",
  "Pacific/Wake": "(GMT+12:00) Wake",
  "Pacific/Wallis": "(GMT+12:00) Wallis",
  "Pacific/Apia": "(GMT+14:00) Apia",
  "Pacific/Enderbury": "(GMT+13:00) Enderbury",
  "Pacific/Fakaofo": "(GMT+13:00) Fakaofo",
  "Pacific/Tongatapu": "(GMT+13:00) Tongatapu",
  "Pacific/Kiritimati": "(GMT+14:00) Kiritimati",
};
const keys = Object.keys(timezoneObj);
export const timezoneArray = keys.map((key) => {
  return { value: key + " " + timezoneObj[key].split(" ")[0], label: timezoneObj[key] };
});
