import React from "react";

export const EDSStopwatchIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5538 6.14507C17.1632 6.53559 17.1632 7.16876 17.5538 7.55928C17.9443 7.94981 18.5775 7.94981 18.968 7.55928L17.5538 6.14507ZM19.8028 2.80594C19.4122 2.41541 18.7791 2.41541 18.3885 2.80594C17.998 3.19646 17.998 3.82963 18.3885 4.22015L19.8028 2.80594ZM20.8929 6.7245C21.2834 7.11502 21.9166 7.11502 22.3071 6.7245C22.6976 6.33397 22.6976 5.70081 22.3071 5.31028L20.8929 6.7245ZM13 9.84091C13 9.28863 12.5523 8.84091 12 8.84091C11.4477 8.84091 11 9.28863 11 9.84091H13ZM11 13.2175C11 13.7698 11.4477 14.2175 12 14.2175C12.5523 14.2175 13 13.7698 13 13.2175H11ZM9.5 1C8.94772 1 8.5 1.44772 8.5 2C8.5 2.55228 8.94772 3 9.5 3V1ZM14.5 3C15.0523 3 15.5 2.55228 15.5 2C15.5 1.44772 15.0523 1 14.5 1V3ZM18.968 7.55928L21.0549 5.47232L19.6407 4.05811L17.5538 6.14507L18.968 7.55928ZM18.3885 4.22015L19.6407 5.47232L21.0549 4.05811L19.8028 2.80594L18.3885 4.22015ZM19.6407 5.47232L20.8929 6.7245L22.3071 5.31028L21.0549 4.05811L19.6407 5.47232ZM11 9.84091V13.2175H13V9.84091H11ZM9.5 3H14.5V1H9.5V3ZM19.5 13.4286C19.5 17.6181 16.1343 21 12 21V23C17.2546 23 21.5 18.7068 21.5 13.4286H19.5ZM12 21C7.86574 21 4.5 17.6181 4.5 13.4286H2.5C2.5 18.7068 6.74542 23 12 23V21ZM4.5 13.4286C4.5 9.23908 7.86574 5.85714 12 5.85714V3.85714C6.74542 3.85714 2.5 8.15032 2.5 13.4286H4.5ZM12 5.85714C16.1343 5.85714 19.5 9.23908 19.5 13.4286H21.5C21.5 8.15032 17.2546 3.85714 12 3.85714V5.85714Z"
          fill="#28287B"
        />
      </svg>
    </>
  );
};
