export const createTypography = () => {
  return {
    fontFamily: "Plus Jakarta Sans, sans-serif",
    // fontFamily: "Poppins, sans-serif",
    // fontFamily: "Rubik, sans-serif",
    // fontFamily: "Kanit, sans-serif",
    // fontFamily: "Noto Serif Vithkuqi, serif",

    h1: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    body1: {
      lineHeight: 1.5,
    },
    body2: {
      lineHeight: 1.6,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.75,
    },
    caption: {
      fontWeight: 400,
      lineHeight: 1.6,
    },
    overline: {
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 1,
      lineHeight: 2.46,
    },
    button: {
      fontWeight: 500,
      textTransform: "none",
    },
  };
};
