import React, { useState } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { setWorkspace, useCreateWorkSpaceMutation, useUpdateWorkSpaceStatusMutation } from 'src/services/workSpace-service';
import { useDispatch } from 'react-redux';

const CreateWorkspace = ({ onContinue }) => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [createWorkSpace] = useCreateWorkSpaceMutation();
  const dispatch = useDispatch();
  const [updateWorkSpaceStatus] = useUpdateWorkSpaceStatusMutation();

  const handleChange = (event) => {
    setWorkspaceName(event.target.value);
    if (error) {
      setError('');
    }
  };

  const handleCancelClick = () => {
    navigate(location.state?.from || "/accounts")
  };

  const handleContinueClick = async () => {
    if (!workspaceName.trim()) {
      setError('Workspace name is required');
      return;
    }

    if (workspaceName.trim().length < 3) {
      setError('Workspace name must be at least 3 characters long');
      return;
    }
    setLoading(true);

    try {
      const { message, workSpace } = await createWorkSpace({ name: workspaceName }).unwrap();
      if (message == 'Workspace with same name already exist') {
        setError(message);
        return;
      }
      dispatch(setWorkspace(workSpace));
      await updateWorkSpaceStatus({ workspaceId: workSpace?._id });
      navigate("/workSpace/billing", { state: { fromCreateWorkspace: true }, replace: true });
    } catch (error) {
      console.error('Failed to create workspace:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', minHeight: '100vh', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '800px', mb: 3 }}>
          <Typography sx={{ mb: 1, fontWeight: 'bold', fontSize: '32px' }}>
            Let's create a new workspace
          </Typography>
          <Typography sx={{ color: '#6B7280', fontSize: '20px', mb: 3 }}>
            What would you like to name it?
          </Typography>
        </Box>

        <TextField
          label="Workspace Name"
          variant="outlined"
          value={workspaceName}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 3, maxWidth: '800px' }}
          error={!!error}
          helperText={error}
        />
        <Box sx={{ display: 'flex', width: '100%', maxWidth: '800px' }}>
          <Button onClick={handleCancelClick} sx={{ mr: 2, color: '#6B7280', padding: '12px 24px', fontSize: '16px', }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleContinueClick}
            sx={{ 
              position: 'relative', 
              padding: '12px 24px', 
              fontSize: '16px',
              minWidth: '150px',
              '&:disabled': {
                bgcolor: '#007bff',
              }
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress 
                size={24} 
                sx={{ 
                  color: "white",
                  position: 'absolute', 
                  top: '50%', 
                  left: '50%', 
                  marginTop: '-12px', 
                  marginLeft: '-12px' 
                }} 
              />
            ) : (
              'Continue'
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateWorkspace;
