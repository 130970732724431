import React from 'react';
import {Typography, Box } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';

const Maintenance = ({lead}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <EngineeringIcon sx={{ marginBottom: 2, fontSize: '200px' }} />
      <Typography variant="h1" gutterBottom>
       {lead === true ? "LeadFinder is Under Maintenance!" : "Site is Under Maintenance!"} 
      </Typography>
      <Typography variant="h6">
        Sorry for the inconvenience but we're performing some maintenance at the moment. We'll be back online shortly!
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        &mdash; The Successai Team
      </Typography>
    </Box>
  );
};

export default Maintenance;