import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import toast from "react-hot-toast";
import {useRemoveDeletedAccountMutation} from "src/services/account-service.js";
const DeleteAccountModal = ({ open, handleClose, handleContinue, formikvalue, connectedEmail}) => {
  const [checked, setChecked] = useState(false);
  const [removeDeletedAccount] = useRemoveDeletedAccountMutation();
  const handleCheckboxChange = (event) => {
	setChecked(event.target.checked);
	};

  const handleDelete = async () => {
    await toast.promise(
      removeDeletedAccount({ email }).unwrap(), 
      {
        loading: "Deleting...",
        success: "The email account associated with the previous user has been deleted; please connect the account again!",
        error: "Could not delete",
      }
    );
    handleContinue();
  };
  
  const email = formikvalue?.email || connectedEmail;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Alert"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This email account is previously connected to some other user account. It's recommended to use that user account.
          <br />
          <br />
          All campaign data particularly related to this <b>{email}</b> email account will be removed from that user account.
        </DialogContentText>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
          label="I understand that and I want to continue."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          sx={{ color: "#fff", ml: 2, width: "15ch", py: 1.2 }}
          type="submit"
          variant="contained"
          disabled={!checked}

        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccountModal;