export const SBIntegration = ({ color = "#28287B" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1984 19.1999C15.1984 20.5254 14.1239 21.5999 12.7984 21.5999C11.473 21.5999 4.79844 21.5999 4.79844 21.5999C3.47295 21.5999 2.39844 20.5254 2.39844 19.1999C2.39844 17.8744 2.39844 12.5254 2.39844 11.1999C2.39844 9.87442 3.47296 8.7999 4.79844 8.7999L12.7984 8.7999C14.1239 8.7999 15.1984 9.87442 15.1984 11.1999V19.1999Z"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.5984 12.7999C21.5984 14.1254 20.5239 15.1999 19.1984 15.1999C17.873 15.1999 11.1984 15.1999 11.1984 15.1999C9.87295 15.1999 8.79844 14.1254 8.79844 12.7999C8.79844 11.4744 8.79844 6.12539 8.79844 4.7999C8.79844 3.47442 9.87296 2.3999 11.1984 2.3999L19.1984 2.3999C20.5239 2.3999 21.5984 3.47442 21.5984 4.7999V12.7999Z"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
