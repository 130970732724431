import { CloudUploadTwoTone, TaskAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { Google } from "src/assets/Google.js";
import FileUploadRowBlocklist from "../settings/FileUploadRowBlocklist.js"
import { fetchGoogleSheet } from "src/utils/util.js";
import { useEffect, useState } from "react";
import { useAddBlocklistMutation, useDuplicateCheckMutation} from "src/services/account-service.js"
import { toast } from "react-hot-toast";
import { OffCheckboxCustomIcon } from "src/assets/general/OffCheckboxCustomIcon.js";
import { OnCheckboxCustomIcon } from "src/assets/general/OnCheckboxCustomIcon.js";
import { handleDialogUploadAllClick, handleUploadAllClick, handleSelectTypeChange, updateData, determineType } from "src/utils/util.js";
import { useSelector } from "react-redux";

const selections = [
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Domain",
    value: "domain",
  },
  {
    label: "Do not import",
    value: "DO_NOT_IMPORT",
  },
];

const selectionsLeadFinder = [
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Do not import",
    value: "DO_NOT_IMPORT",
  },
];
const GoogleSheetImport = ({ listType, setSnackbarOpen, setSnackbarMsg, onLeadsCreate, leadFinder, setLeadsData }) => {
  const [googleSheetLink, setGoogleSheetLink] = useState("");
  const [isImporting, setIsImporting] = useState(false);
  const [sheetData, setSheetData] = useState([]);
  const [leads, setLeads] = useState([]);
  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState({});
  const [domains, setDomains] = useState([]);
  const [checkDuplicates, setCheckDuplicates] = useState(true);
  const [fileColumns, setFileColumns] = useState([]);
  const [samples, setSamples] = useState([]);
  const [link, setLink] = useState("")
  const [addBlocklist, {isLoading : isUploading }] = useAddBlocklistMutation()
  const [duplicateCheck] = useDuplicateCheckMutation()
  const [workspaceId, setWorkspaceId] = useState();
  const workspace = useSelector((state) => state.workspace);
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  },[workspace])

  const handleImportClick = async () => {
    try {
      setIsImporting(true);
      const data = await fetchGoogleSheet(googleSheetLink);
      if(data?.error){
        toast.error("Link has more than one sheet");
        return;
      }
      const {columns, rows}= data;
      if (!columns || !rows[0]) {
        return toast.error("Found 0 rows - ensure the sheet is publicly accessible and has data.");
      }
      setFileColumns(columns);
      setSamples(rows[0]);
      const sheetData = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const sheetRow = [];
        for (let j = 0; j < row.length; j++) {
          const type =
            !leadFinder ? selections : selectionsLeadFinder.find((s) => s.label === columns[j])?.value ||
            !leadFinder ? selections : selectionsLeadFinder[!leadFinder ? selections.length : selectionsLeadFinder.length - 1].value;
          if (!row[j]) continue;
          sheetRow.push({
            type,
            column: columns[j],
            value: row[j],
          });
        }
        sheetData.push(sheetRow);
      }
      setSheetData(sheetData);
      setLink(googleSheetLink);
      updateData(sheetData, listType, setLeads, setDomains);
    } catch (error) {
      toast.error("Invalid link - ensure the sheet is publicly accessible and has data.");
    } finally {
      setIsImporting(false);
    }
  };
  
  const onTypeChange = (column, selection) => {
    handleSelectTypeChange(sheetData, column, selection, setSheetData, setLeads, setDomains);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onUploadClick = () => {
    handleDialogUploadAllClick({
      listType,
      leads,
      domains,
      isUploading,
      checkDuplicates,
      stats,
      link,
      addBlocklist,
      setOpen,
      setSnackbarOpen,
      setSnackbarMsg,
      onLeadsCreate, 
      workspaceId
    });
  };

  const onUploadAllClick = async () => {
    await handleUploadAllClick(checkDuplicates, listType, leads, domains, duplicateCheck, setStats, setOpen, workspaceId);
  };

  const handleClick = () => {
    const leadEmails = leads.map(lead => lead.email);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const testEmail = emailRegex.test(leadEmails[0]);
    if (testEmail){
      setLeadsData(leadEmails,checkDuplicates);
    }else{
      toast.error('Please Select email.');
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
            <Google />
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "25px",
              color: "#28287B",
            }}
          >
            Google Sheets
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            backgroundColor: "#F2F4F6",
            p: 2,
            borderRadius: "12px",
            mt: 2,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "18px",
            }}
          >
            💡 Make sure your Google Sheet is publicly accessible
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: 400,
            lineHeight: "20px",
            color: "#8181B0",
            mt: 3,
          }}
        >
          Paste your Google Sheets link here
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          sx={{
            mt: 1,
            backgroundColor: "white",
            "& div": { pl: 1 },
            "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
            "& div input": {
              p: 1.3,
              fontSize: "13px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0em",
              "&::placeholder": {
                color: "rgba(40, 40, 123, 0.5)",
              },
            },
          }}
          disabled={isUploading}
          value={googleSheetLink}
          onChange={(e) => setGoogleSheetLink(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            disabled={!googleSheetLink.trim() || isUploading}
            onClick={handleImportClick}
          >
            {isImporting ? <CircularProgress size={20} sx={{ color: "white" }} /> : "Import"}
          </Button>
        </Box>
      </Box>
      {(leads.length !== 0 || domains.length !== 0) && (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <TaskAlt sx={{ color: "rgb(33, 111, 237)", mr: 1 }} />
            <Typography sx={{ fontWeight: 600, color: "rgb(33, 111, 237)" }}>
              File Processed
            </Typography>
          </Box>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={360}>Column Name</TableCell>
                  <TableCell width={360}>Select Type</TableCell>
                  <TableCell>Samples</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileColumns.map((column, index) => (
                  <FileUploadRowBlocklist
                    key={index}
                    column={column}
                    sample={samples[index]}
                    selections={!leadFinder ? selections : selectionsLeadFinder}
                    onChange={(s) => onTypeChange(column, s)}
                    isUploading={isUploading}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {leadFinder ? <></> :
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={checkDuplicates}
                  onChange={(e, value) => setCheckDuplicates(value)}
                />
              }
              label="Check for duplicates across existing records"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "#28287B",
                },
                mt: 2,
              }}
            />
          </Box>
          }
          {leadFinder ? 
          <Box
            sx={{
              textAlign: "center",
            }}
          >
        <Typography
          sx={{ fontSize: "13px", lineHeight: "16px", fontWeight: 500, color: "#28287B" }}
        >
          Total{' '}
          <Box component="span" sx={{ color: "#004EEB", fontWeight:"bold" }}>
            {leads?.length}
          </Box>{' '}
          leads selected. We will charge your credits based on the enrichment results.
        </Typography>
          </Box> : <></>
          }
         {!leadFinder ?
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              px: 3,
              py: 1.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
              mx: "auto",
            }}
            onClick={ checkDuplicates ? onUploadAllClick : onUploadClick}
          >
            {isUploading ? (
              <CircularProgress size={20} sx={{ color: "white", mr: 1 }} />
            ) : (
              <CloudUploadTwoTone sx={{ mr: 1 }} />
            )}
            Upload All
          </Button>
          :
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              px: 3,
              py: 1.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
              mx: "auto",
            }}
            onClick={handleClick}
          >
            {isUploading ? (
              <CircularProgress size={20} sx={{ color: "white", mr: 1 }} />
            ) : (
              <CloudUploadTwoTone sx={{ mr: 1 }} />
            )}
            Enrichment
          </Button>
          }
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontSize: "25px", color: "#595959", textAlign: "center" }}>
              Are you sure?
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                This will upload{" "}
                <Typography component="span" color={"blue"}>
                  {stats.uploadedCount}{" "}
                </Typography>{" "}
                records to your blocklist .{" "}
                <Typography component="span" color={"blue"}>
                  {" "}
                  {stats?.duplicateItemsCount || 0}{" "}
                </Typography>{" "}
                 duplicate records.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  onUploadClick();
                }}                
                variant="contained"
                sx={{
                  fontSize: "16px",
                  borderRadius: "12px",
                  px: 2.5,
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                {isUploading || isUploading ? (
                  "Uploading..."
                ) : (
                  <>
                    <CloudUploadTwoTone sx={{ mr: 1 }} />
                    Upload
                  </>
                )}
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  // backgroundColor: "#595959",
                  color: "Black",
                  fontSize: "16px",
                  borderRadius: "12px",
                  px: 3.2,
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                  "&:hover": {
                    backgroundColor: "#787878",
                  },
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default GoogleSheetImport;
