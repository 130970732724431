export const BoltIcon = ({ color = "#0071F6" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.28881 13.6315L12.278 2.64854C12.5465 2.22652 13.1998 2.41675 13.1998 2.91698V10.7C13.1998 10.7552 13.2446 10.8 13.2998 10.8H18.2395C18.644 10.8 18.8811 11.2553 18.6491 11.5867L11.7094 21.5005C11.4291 21.901 10.7998 21.7026 10.7998 21.2138V14.5C10.7998 14.4447 10.755 14.4 10.6998 14.4H5.71064C5.3161 14.4 5.07699 13.9644 5.28881 13.6315Z"
          stroke={color}
          stroke-width="2"
        />
      </svg>
    </>
  );
};
